// import React from 'react';
// import Ecommerce from "../../../images/SKILL_J&J_Final Render_V07.mp4";

// const EcommerceVideoEditing = () => {
//   return (
//     <div className="bg-[#f2f2f2] container mx-auto py-14">
//       <h1 className="text-3xl md:text-5xl font-bold mb-4 text-[#770099] text-center">Ecommerce Video Editing</h1>
//       <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
//         Elevate your image editing experience with our 360-degree comparison tool. Prepare your images for versatile editing, optimizing them for text wrapping, seamless background removal, and suitability for both print and web applications.
//       </p>
//       <div className="container mx-auto py-8 px-4 sm:px-8">
//       <div className="flex justify-center">
//         <video  
//           controls
//           autoPlay
//            className="max-w-3xl rounded-lg shadow-lg border-4 border-[#581C87] hover:border-[#581C87] transition-all duration-300 transform" src={Ecommerce} alt=""/>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default EcommerceVideoEditing;


// import React from 'react';
// import Ecommerce from "../../../images/SKILL_J&J_Final Render_V07.mp4";

// const EcommerceVideoEditing = () => {
//   return (
//     <div className="bg-[#f2f2f2] container mx-auto py-14">
//       <h1 className="text-3xl md:text-5xl font-bold mb-4 text-[#770099] text-center">Elevate Your Ecommerce with Stunning Video Editing</h1>
//       <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
//         Enhance your product presentations and captivate your audience with our exceptional video editing services. Our skilled team ensures that your visuals are optimized for maximum impact, from seamless background removal to dynamic text wrapping.
//       </p>
//       <div className="container mx-auto py-8 px-4 sm:px-8">
//         <div className="flex justify-center">
//           <video
//             controls
//             autoPlay
//             loop
//             muted
//             className="max-w-3xl rounded-lg shadow-lg border-4 border-[#581C87] hover:border-[#581C87] transition-all duration-300 transform"
//           >
//             <source src={Ecommerce} type="video/mp4" />
//           </video>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EcommerceVideoEditing;


import React from 'react';
import Ecommerce from "../../../images/Animation/SKILL_J&J_Final Render_V07.mp4";

const EcommerceVideoEditing = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-[#770099] text-center">Elevate Your Ecommerce With Stunning Video Editing</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto lg:px-24 sm:px-8">
        Enhance your product presentations and captivate your audience with our exceptional video editing services. Our skilled team ensures that your visuals are optimized for maximum impact, from seamless background removal to dynamic text wrapping.
      </p>
      <div className="container mx-auto py-2 px-4 sm:px-8">
        <div className="flex justify-center">
          <video
            controls
            autoPlay
            loop
            muted
            controlsList="nodownload"
            className="max-w-full md:max-w-3xl rounded-lg shadow-lg border-4 border-[#581C87] hover:border-[#581C87] transition-all duration-300 transform"
          >
            <source src={Ecommerce} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default EcommerceVideoEditing;
