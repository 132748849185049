import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeManipulation from "../../../images/Manipulation/before/FD-20220719_0238-1.png"
import afterManipulation from "../../../images/Manipulation/after/FD-20220719_0238-1_A.png"
import BeforeManipulation1 from "../../../images/Manipulation/before/Lounge mit Flachdach (3).png"
import afterManipulation1 from "../../../images/Manipulation/after/Lounge mit Flachdach (3).png"
import BeforeManipulation2 from "../../../images/Manipulation/before/shutterstock_45126145.png"
import afterManipulation2 from "../../../images/Manipulation/after/shutterstock_45126145_A.png"
import BeforeManipulation3 from "../../../images/Manipulation/before/DSC_9208.png"
import afterManipulation3 from "../../../images/Manipulation/after/DSC_9208_A.png"
import BeforeManipulation4 from "../../../images/Manipulation/before/Before_DJI_0299.jpg"
import afterManipulation4 from "../../../images/Manipulation/after/After_DJI_0299.jpg"

const serviceData = [
  {
    beforeImage: BeforeManipulation,
    afterImage: afterManipulation,
  },
  {
    beforeImage: BeforeManipulation1,
    afterImage: afterManipulation1,
  },
  {
    beforeImage: BeforeManipulation2,
    afterImage: afterManipulation2,
  },
  {
    beforeImage: BeforeManipulation3,
    afterImage: afterManipulation3,
  },
  {
    beforeImage: BeforeManipulation4,
    afterImage: afterManipulation4,
  },
];

const Manipulation = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">   
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-black text-center">Masterful Manipulation</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
          Unleash the full potential of your visuals with our manipulation expertise. Elevate your images by preparing them for versatile editing – optimize for flawless text wrapping, seamless background removal, and ensure suitability for both print and web applications. Immerse your audience in a world where creativity knows no bounds. Transform ordinary visuals into extraordinary masterpieces with our manipulation prowess.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Manipulation;
