// import React from "react";
// import {
//   FaSmile,
//   FaFileAlt,
//   FaFolderOpen,
//   FaFolder,
//   FaFile,
//   FaRegSmile,
//   FaFileArchive,
//   FaFileDownload,
//   FaFileCode,
//   FaFileSignature,
//   FaFileContract,
//   FaFilePdf,
//   FaFileWord,
//   FaSmileWink,
//   FaSmileBeam,
// } from "react-icons/fa";
// import camera from "../../../images/gray-bg-len.png";

// import "./BusinessSummary.css";

// const BusinessSummary = () => {
//   return (
//     <section className="py-12 container mx-auto px-10 relative bg-[#f2f2f2]">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8">
//         <div className="md:w-2/4 py-16 md:mb-0 text-center md:text-left "  
// >
//           <div className="flex items-center space-x-4 mt-10"     
//           >
//             <div className="text-3xl font-bold  md:text-5xl  text-[#333] leading-tight ">
//               <h2 className="text-black">
//               Streamlined Imaging
//               </h2>
//               <p className="text-gray-800 mt-6 text-lg">
//               We delivers the high-volume image processing you need to keep your visual content sharp and on schedule
//               </p>
//               {/* <img style={{
//               position: "relative",
//               top:0,
//               bottom: 0,
//               left: 0,
         
//             }} className="w-72 hover:scale-105 transform transition-transform duration-300" src={camera} alt="" /> */}
//             </div>
//           </div>
//         </div>
//         <div className="md:w-3/4 md:mb-0">
//   <div className="space-y-4">
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
//       <div className="lg:grid-cols-2">
//       <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaFileAlt color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110"  />
//         <p className="text-xl text-primary font-semibold mb-1">375,000</p>
//         <p className="text-dark font-medium">Total Complete Files</p>
//       </div>
//       <div className="card shadow-lg my-6 lg:w-96 rounded-md p-10 text-white bg-gradient-to-r from-blue-400 to-purple-600 flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-slate-100 hover:shadow-xl duration-300">
//         <FaFolderOpen color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110"  />
//         <p className="text-xl text-success font-semibold mb-1">2500</p>
//         <p className="text-dark font-medium">Total Projects</p>
//       </div>
//       </div>
//       <div className="lg:grid-cols-1 lg:my-24">
//       <div className="card shadow-lg lg:w-96 rounded-md p-10 text-white bg-gradient-to-r from-blue-400 to-purple-600 flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-slate-100 hover:shadow-xl duration-300">
//         <FaSmile color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110"  />
//         <p className="text-xl text-info font-semibold mb-1">270</p>
//         <p className="text-dark font-medium">Satisfied Clients</p>
//       </div>
//       </div>
//     </div>
//   </div>
// </div>

//       </div>
//     </section>
//   );
// };

// export default BusinessSummary;


// import React from "react";
// import {
//   FaSmile,
//   FaFileAlt,
//   FaBusinessTime,
//   FaProductHunt,
// } from "react-icons/fa";

// import "./BusinessSummary.css";
// import CountUp from "react-countup";
// import VisibilitySensor from 'react-visibility-sensor';

// const BusinessSummary = () => {
  
//   return (
//     <section className="py-12 container mx-auto px-10 relative bg-[#f2f2f2]">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8">
//         <div className="md:w-2/4 py-16 md:mb-0 text-center md:text-left ">
//           <div className="flex items-center space-x-4 mt-10">
//             <div className="text-3xl font-bold  md:text-5xl  text-[#333] leading-tight ">
//               <h2 className="text-[#770099]">
//               Streamlined Imaging
//               </h2>
//               <p className="text-gray-600 mt-6 text-lg">
//               We delivers the high-volume image processing you need to keep your visual content sharp and on schedule
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-3/4 md:mb-0">
//   <div className="space-y-4">
//     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//       <div className="lg:grid-cols-2">
//       <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaFileAlt color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110"  />
//         <p className="text-2xl text-primary font-semibold mb-1">         
//               <CountUp  start={0}
//                     end={30}
//                     duration={3} redraw={true}>
//         {({ countUpRef, start }) => (
//             <VisibilitySensor  onChange={start} delayedCall>
//                 <span ref={countUpRef} />
//             </VisibilitySensor>
//         )}
//     </CountUp>
//     M
//           </p>
//         <p className="text-dark text-lg font-medium">Total Complete Files</p>
//       </div>
//       <div className="my-6 card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaProductHunt color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//         <p className="text-2xl text-success font-semibold mb-1">
//         <CountUp  start={0}
//                     end={10000}
//                     duration={3} redraw={true}>
//         {({ countUpRef, start }) => (
//             <VisibilitySensor onChange={start} delayedCall>
//                 <span ref={countUpRef} />
//             </VisibilitySensor>
//         )}
//     </CountUp>
//     + </p>
//         <p className="text-dark text-lg font-medium">Daily Post Production</p>
//       </div>
//       </div>
//       <div className="lg:grid-cols-2 ">
//       <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaSmile color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//         <p className="text-2xl text-info font-semibold mb-1">
        
//         <CountUp  start={0}
//                     end={1200}
//                     duration={3} redraw={true}>
//         {({ countUpRef, start }) => (
//             <VisibilitySensor onChange={start} delayedCall>
//                 <span ref={countUpRef} />
//             </VisibilitySensor>
//         )}
//     </CountUp>
//          + </p>
//         <p className="text-dark text-lg font-medium">Satisfied Clients</p>
//       </div>
//       <div className="card my-6 shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaBusinessTime color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//         <p className="text-2xl text-info font-semibold mb-1">
//         <CountUp  start={0}
//                     end={10}
//                     duration={3} redraw={true}>
//         {({ countUpRef, start }) => (
//             <VisibilitySensor onChange={start} delayedCall>
//                 <span ref={countUpRef} />
//             </VisibilitySensor>
//         )}
//     </CountUp>
//     +
//           </p>
//         <p className="text-dark text-lg font-medium">Years Of Success</p>
//       </div>
//       </div>
//     </div>
//   </div>
// </div>
//       </div>
//     </section>
//   );
// };

// export default BusinessSummary;



/******************  Important  ******************************/

// import React from "react";
// import {
//   FaSmile,
//   FaFileAlt,
//   FaBusinessTime,
//   FaProductHunt,
//   FaImages,
//   FaUserAlt,
//   FaUser,
// } from "react-icons/fa";

// import "./BusinessSummary.css";
// import CountUp from "react-countup";
// import VisibilitySensor from 'react-visibility-sensor';
// import { useState } from "react";

// const BusinessSummary = () => {

//   const [isVisible, setIsVisible] = useState(false);

//   const handleVisibilityChange = (isVisible) => {
//     if (isVisible) {
//       setIsVisible(true);
//     }
//   };
  
//   return (
//     <section className="py-16 lg:pt-24 container mx-auto px-10 relative bg-[#f2f2f2]">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8">
//         <div className="md:w-2/4 py-16 md:mb-0 text-center md:text-left ">
//           <div className="flex items-center space-x-4 mt-10">
//             <div className="text-3xl font-bold  md:text-5xl  text-[#333] leading-tight ">
//               {/* <h2 className="text-[#770099]">
//                 Streamlined Imaging
//               </h2> */}
              // <h2 className="text-[#770099] text-3xl md:text-5xl font-bold leading-tight tracking-wide">
              //   Streamlined Imaging Solutions
              // </h2>
  
//               <p className="text-gray-600 mt-6 text-lg">
//               We delivers the high-volume image processing you need to keep your visual content sharp and on schedule.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-3/4 md:mb-0">
//       <div className="space-y-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//       <div className="lg:grid-cols-2">
//       <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaImages color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110"  />
//            <p className="text-2xl text-primary font-semibold mb-1">
//             <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//               <CountUp
//                 start={0}
//                 end={30}
//                 duration={3}
//                 redraw={true}
//                 startOnMount={false}
//                 endOnMount={false}
//               >
//                 {({ countUpRef }) => (
//                   <span ref={countUpRef} />
//                 )}
//               </CountUp>
//             </VisibilitySensor>
//             M
//           </p>

//         <p className="text-dark text-lg font-medium">Total Complete Files</p>
//       </div>
//       <div className="my-6 card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaProductHunt color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//             <p className="text-2xl text-primary font-semibold mb-1">
//             <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//               <CountUp
//                start={0}
//                end={10000}
//                 duration={3}
//                 redraw={true}
//                 startOnMount={false}
//                 endOnMount={false}
//               >
//                 {({ countUpRef }) => (
//                   <span ref={countUpRef} />
//                 )}
//               </CountUp>
//             </VisibilitySensor>
//             +
//           </p>
//         <p className="text-dark text-lg font-medium">Daily Post Production</p>
//       </div>
//       </div>
//       <div className="lg:grid-cols-2 ">
//       <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaUser color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//           <p className="text-2xl text-primary font-semibold mb-1">
//             <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//               <CountUp
//                 start={0}
//                 end={1200}
//                 duration={3}
//                 redraw={true}
//                 startOnMount={false}
//                 endOnMount={false}
//               >
//                 {({ countUpRef }) => (
//                   <span ref={countUpRef} />
//                 )}
//               </CountUp>
//             </VisibilitySensor>
//             +
//           </p>
//         <p className="text-dark text-lg font-medium">Satisfied Clients</p>
//       </div>
//       <div className="card my-6 shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-col justify-center items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//         <FaBusinessTime color="#770099" className="w-12 h-12 mb-3 transition-transform transform-gpu hover:scale-110 duration-500"  />
//            <p className="text-2xl text-primary font-semibold mb-1">
//             <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//               <CountUp
//                  start={0}
//                  end={10}
//                 duration={3}
//                 redraw={true}
//                 startOnMount={false}
//                 endOnMount={false}
//               >
//                 {({ countUpRef }) => (
//                   <span ref={countUpRef} />
//                 )}
//               </CountUp>
//             </VisibilitySensor>
//             +
//           </p>
//         <p className="text-dark text-lg font-medium">Years Of Success</p>
//       </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// </section>
//   );
// };

// export default BusinessSummary;


/****************** New Design *************************/

// import React from "react";
// import {
//   FaSmile,
//   FaFileAlt,
//   FaBusinessTime,
//   FaProductHunt,
//   FaImages,
//   FaUserAlt,
//   FaUser,
// } from "react-icons/fa";

// import "./BusinessSummary.css";
// import CountUp from "react-countup";
// import VisibilitySensor from 'react-visibility-sensor';
// import { useState } from "react";

// const BusinessSummary = () => {

//   const [isVisible, setIsVisible] = useState(false);

//   const handleVisibilityChange = (isVisible) => {
//     if (isVisible) {
//       setIsVisible(true);
//     }
//   };
  
//   return (
//     <section className="py-16 lg:pt-24 container mx-auto px-10 relative bg-[#f2f2f2]">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8">
//         <div className="md:w-2/4 py-16 md:mb-0 text-center md:text-left ">
//           <div className="flex items-center space-x-4 mt-10">
//             <div className="text-2xl font-bold  md:text-3xl  text-[#333] leading-tight ">
//             <h2 className="text-[#770099] text-3xl md:text-5xl font-bold leading-tight tracking-wide">
//                 Streamlined Imaging Solutions
//               </h2>
//               <p className="text-gray-600 mt-6 text-lg">
//               We delivers the high-volume image processing you need to keep your visual content sharp and on schedule.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-3/4 md:mb-0">
//       <div className="space-y-4">
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//       <div className="lg:grid-cols-2">
//       <div className="lg:grid-cols-2">
//   <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//     <FaImages color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110" />
//     <div className="flex flex-col justify-center items-start ml-4">
//       <p className="text-2xl text-primary font-semibold mb-1">
//         <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//           <CountUp
//             start={0}
//             end={30}
//             duration={3}
//             redraw={true}
//             startOnMount={false}
//             endOnMount={false}
//           >
//             {({ countUpRef }) => (
//               <span ref={countUpRef} />
//             )}
//           </CountUp>
//         </VisibilitySensor>
//         M
//       </p>
//       <p className="text-dark text-lg font-medium">Total Complete Files</p>
//     </div>
//   </div>
//   <div className="card my-6 shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//     <FaProductHunt color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500" />
//     <div className="flex flex-col justify-center items-start ml-4">
//       <p className="text-2xl text-primary font-semibold mb-1">
//         <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//           <CountUp
//             start={0}
//             end={10000}
//             duration={3}
//             redraw={true}
//             startOnMount={false}
//             endOnMount={false}
//           >
//             {({ countUpRef }) => (
//               <span ref={countUpRef} />
//             )}
//           </CountUp>
//         </VisibilitySensor>
//         +
//       </p>
//       <p className="text-dark text-lg font-medium">Daily Post Production</p>
//     </div>
//   </div>
// </div>

//       </div>
//       <div className="lg:grid-cols-2 ">
//       <div className="lg:grid-cols-2">
//   <div className="card shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//     <FaUser color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500" />
//     <div className="flex flex-col justify-center items-start ml-4">
//       <p className="text-2xl text-primary font-semibold mb-1">
//         <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//           <CountUp
//             start={0}
//             end={1200}
//             duration={3}
//             redraw={true}
//             startOnMount={false}
//             endOnMount={false}
//           >
//             {({ countUpRef }) => (
//               <span ref={countUpRef} />
//             )}
//           </CountUp>
//         </VisibilitySensor>
//         +
//       </p>
//       <p className="text-dark text-lg font-medium">Satisfied Clients</p>
//     </div>
//   </div>
//   <div className="card my-6 shadow-lg rounded-md lg:w-96 p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//     <FaBusinessTime color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500" />
//     <div className="flex flex-col justify-center items-start ml-4">
//       <p className="text-2xl text-primary font-semibold mb-1">
//         <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//           <CountUp
//             start={0}
//             end={10}
//             duration={3}
//             redraw={true}
//             startOnMount={false}
//             endOnMount={false}
//           >
//             {({ countUpRef }) => (
//               <span ref={countUpRef} />
//             )}
//           </CountUp>
//         </VisibilitySensor>
//         +
//       </p>
//       <p className="text-dark text-lg font-medium">Years Of Success</p>
//     </div>
//   </div>
// </div>

//       </div>
//     </div>
//   </div>
// </div>
// </div>
// </section>
//   );
// };

// export default BusinessSummary;

// import React from "react";
// import { FaSmile, FaFileAlt, FaBusinessTime, FaProductHunt, FaImages, FaUserAlt, FaUser } from "react-icons/fa";
// import "./BusinessSummary.css";
// import CountUp from "react-countup";
// import VisibilitySensor from 'react-visibility-sensor';
// import { useState } from "react";

// const BusinessSummary = () => {
//   const [isVisible, setIsVisible] = useState(false);

//   const handleVisibilityChange = (isVisible) => {
//     if (isVisible) {
//       setIsVisible(true);
//     }
//   };

//   return (
//     <section className="py-16 lg:pt-24 container mx-auto px-10 relative bg-[#f2f2f2]">
//       <div className="container mx-auto flex flex-col md:flex-row items-center gap-8">
//         <div className="md:w-2/4 py-16 md:mb-0 text-center md:text-left">
//           <div className="flex items-center space-x-4 mt-10">
//             <div className="text-2xl font-bold md:text-3xl text-[#333] leading-tight">
//               <h2 className="text-[#770099] text-3xl md:text-5xl font-bold leading-tight tracking-wide">
//                 Streamlined Imaging Solutions
//               </h2>
//               <p className="text-gray-600 mt-6 text-lg">
//                 We deliver the high-volume image processing you need to keep your visual content sharp and on schedule.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="md:w-3/4 md:mb-0">
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
//             <div className="card shadow-lg rounded-md p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//               <FaImages color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110" />
//               <div className="flex flex-col justify-center items-start ml-4">
//                 <p className="text-2xl text-primary font-semibold mb-1">
//                   <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//                     <CountUp start={0} end={30} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
//                       {({ countUpRef }) => (
//                         <span ref={countUpRef} />
//                       )}
//                     </CountUp>
//                   </VisibilitySensor>
//                   M
//                 </p>
//                 <p className="text-dark text-lg font-medium">Total Complete Files</p>
//               </div>
//             </div>
//             <div className="card shadow-lg rounded-md p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//               <FaProductHunt color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500" />
//               <div className="flex flex-col justify-center items-start ml-4">
//                 <p className="text-2xl text-primary font-semibold mb-1">
//                   <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//                     <CountUp start={0} end={10000} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
//                       {({ countUpRef }) => (
//                         <span ref={countUpRef} />
//                       )}
//                     </CountUp>
//                   </VisibilitySensor>
//                   +
//                 </p>
//                 <p className="text-dark text-lg font-medium">Daily Post Production</p>
//               </div>
//             </div>
//             <div className="card shadow-lg rounded-md p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//               <FaUser color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500 lg:mb-3" />
//               <div className="flex flex-col justify-center items-start ml-4">
//                 <p className="text-2xl text-primary font-semibold mb-1">
//                   <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//                     <CountUp start={0} end={1200} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
//                       {({ countUpRef }) => (
//                         <span ref={countUpRef} />
//                       )}
//                     </CountUp>
//                   </VisibilitySensor>
//                   +
//                 </p>
//                 <p className="text-dark text-lg font-medium">Satisfied Clients</p>
//               </div>
//             </div>
//             <div className="card shadow-lg rounded-md p-10 text-black hover:text-white bg-white flex flex-row justify-start items-center transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
//               <FaBusinessTime color="#770099" className="w-12 h-12 transition-transform transform-gpu hover:scale-110 duration-500" />
//               <div className="flex flex-col justify-center items-start ml-4">
//                 <p className="text-2xl text-primary font-semibold mb-1">
//                   <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
//                     <CountUp start={0} end={10} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
//                       {({ countUpRef }) => (
//                         <span ref={countUpRef} />
//                       )}
//                     </CountUp>
//                   </VisibilitySensor>
//                   +
//                 </p>
//                 <p className="text-dark text-lg font-medium">Years Of Success</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default BusinessSummary;


import React from "react";
import { FaSmile, FaFileAlt, FaBusinessTime, FaProductHunt, FaImages, FaUserAlt, FaUser } from "react-icons/fa";
import "./BusinessSummary.css";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from "react";

const BusinessSummary = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <section className="py-16 lg:pt-24 container mx-auto px-4 sm:px-8 bg-[#f2f2f2]">
      <div className="flex flex-col md:flex-row items-center gap-8">
        <div className="md:w-1/2 py-16 text-center md:text-left">
          <h2 className="text-2xl text-[#770099] font-bold sm:text-3xl md:text-5xl leading-tight tracking-wide mb-4">
            Streamlined Imaging Solutions
          </h2>
          <p className="text-gray-600 mt-6 text-lg">
            We deliver the high-volume image processing you need to keep your visual content sharp and on schedule.
          </p>
        </div>
        <div className="md:w-1/2">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="card shadow-lg rounded-md p-6 text-black hover:text-white bg-white flex flex-col items-center md:flex-row transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
              <FaImages color="#770099" className="w-12 h-12 mb-4 md:mb-0 md:mr-4" />
              <div className="flex flex-col justify-center items-center md:items-start">
                <p className="text-2xl font-semibold mb-1">
                  <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
                    <CountUp start={0} end={30} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </VisibilitySensor>
                  M
                </p>
                <p className="text-base font-medium">Total Complete Files</p>
              </div>
            </div>
            <div className="card shadow-lg rounded-md p-6 text-black hover:text-white bg-white flex flex-col items-center md:flex-row transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
              <FaProductHunt color="#770099" className="w-12 h-12 mb-4 md:mb-0 md:mr-4" />
              <div className="flex flex-col justify-center items-center md:items-start">
                <p className="text-2xl font-semibold mb-1">
                  <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
                    <CountUp start={0} end={10000} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </VisibilitySensor>
                  +
                </p>
                <p className="text-base text-justify font-medium">Daily Post Production</p>
              </div>
            </div>
            <div className="card shadow-lg rounded-md p-6 text-black hover:text-white bg-white flex flex-col items-center md:flex-row transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
              <FaUser color="#770099" className="w-12 h-12 mb-4 md:mb-0 md:mr-4" />
              <div className="flex flex-col justify-center items-center md:items-start">
                <p className="text-2xl font-semibold mb-1">
                  <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
                    <CountUp start={0} end={1200} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </VisibilitySensor>
                  +
                </p>
                <p className="text-base font-medium">Satisfied Clients</p>
              </div>
            </div>
            <div className="card shadow-lg rounded-md p-6 text-black hover:text-white bg-white flex flex-col items-center md:flex-row transform transition-transform hover:scale-105 hover:bg-[#770099] hover:shadow-xl duration-300">
              <FaBusinessTime color="#770099" className="w-12 h-12 mb-4 md:mb-0 md:mr-4" />
              <div className="flex flex-col justify-center items-center md:items-start">
                <p className="text-2xl font-semibold mb-1">
                  <VisibilitySensor onChange={handleVisibilityChange} delayedCall>
                    <CountUp start={0} end={10} duration={3} redraw={true} startOnMount={false} endOnMount={false}>
                      {({ countUpRef }) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                  </VisibilitySensor>
                  +
                </p>
                <p className="text-base font-medium">Years Of Success</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessSummary;
