import React from 'react';
import modelling from "../../../images/Animation/threeD-3.mp4"
import { FaArrowRight } from 'react-icons/fa';

const ThreeDModelling = () => {
    return (
        <section id="section1" className="py-12 h-screen container mx-auto px-4 md:px-14 bg-white">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-8 text-start md:text-left">
          <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
           3D Modelling
          </h1>
          <p className="text-base md:text-lg text-[#646a76] mb-6">
          Skill Graphics redefines 3D modeling with unmatched expertise. Elevate your projects through our innovative and quality-driven approach. Immerse yourself in precision-crafted details that breathe life into your vision. Experience the transformative difference that sets us apart.
          </p> 
      
          <a
        href="https://auleek.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <button className="bg-[#770099] hover:bg-[#950cba] text-white font-bold py-2 px-4 rounded">
            Explore More
          </button> */}
            <button className="bg-[#770099] hover:bg-[#950cba] text-white px-6 py-3 rounded flex items-center focus:outline-none focus:ring focus:border-purple-300">
              Explore More
              <FaArrowRight className="ml-2" />
            </button>
        </a>
        </div>
        <div className="md:w-1/2 mt-6 md:mt-0 text-center md:text-right">
        <video
              controls
              autoPlay
              loop
              muted
              src={modelling}
              alt="vedio Eding"
              className="max-w-full rounded-lg hover:scale-105 transform transition-transform duration-300"
              controlsList="nodownload"
              />
        </div>
      </div>
    </section>
    );
};

export default ThreeDModelling;