import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeFlatlay from "../../../src/images/Flat lay/before/_-8.png"
import afterFlatlay from "../../../src/images/Flat lay/after/_-8_A.png"
import BeforeFlatlay1 from "../../../src/images/Flat lay/before/_-7.png"
import afterFlatlay1 from "../../../src/images/Flat lay/after/_-7_A.png"
import BeforeFlatlay2 from "../../../src/images/Flat lay/before/Before_Flatlay - 5.jpg"
import afterFlatlay2 from "../../../src/images/Flat lay/after/After_Flatlay - 5.jpg"
import BeforeFlatlay3 from "../../../src/images/Flat lay/before/gubj_Fron_Beforet.png"
import afterFlatlay3 from "../../../src/images/Flat lay/after/gubj_Front (1)_After.png"

const serviceData = [
  {
    beforeImage: BeforeFlatlay,
    afterImage: afterFlatlay,
  },
  {
    beforeImage: BeforeFlatlay1,
    afterImage: afterFlatlay1,
  },
  {
    beforeImage: BeforeFlatlay2,
    afterImage: afterFlatlay2,
  },
  {
    beforeImage: BeforeFlatlay3,
    afterImage: afterFlatlay3,
  },
];

const Flatlay = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Flat lay</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Infuse vitality into your product imagery by skillfully eliminating mannequins from your e-commerce photos. Let your products take center stage with a clean and captivating presentation that grabs attention and enhances the shopping experience.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Flatlay;
