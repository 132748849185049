import React from "react";
import ReactCompareImage from "react-compare-image";
import beforeRetouching from "../../../images/Model Retouch/Before/5R4A1655.png"
import afterRetouching from "../../../images/Model Retouch/After/5R4A1655_A.png"
import beforeRetouching1 from "../../../images/Model Retouch/Before/DSC_0249.png"
import afterRetouching1 from "../../../images/Model Retouch/After/DSC_0249.png"
import beforeRetouching2 from "../../../images/Model Retouch/Before/Before_32_11234-0120_black_0250.jpg"
import afterRetouching2 from "../../../images/Model Retouch/After/After_32_11234-0120_black_0250.jpg"
import beforeRetouching3 from "../../../images/Model Retouch/Before/DSC_0287.png"
import afterRetouching3 from "../../../images/Model Retouch/After/DSC_0287.png"
import beforeRetouching4 from "../../../images/Model Retouch/Before/LOOK ACQUA E SAPONE _23.png"
import afterRetouching4 from "../../../images/Model Retouch/After/LOOK ACQUA E SAPONE _23_A.png"
import beforeRetouching5 from "../../../images/Model Retouch/Before/Before_Cristina Robles White Portrait1091.jpg"
import afterRetouching5 from "../../../images/Model Retouch/After/After_Cristina Robles White Portrait1091.jpg"

const serviceData = [
  {
    beforeImage: beforeRetouching3,
    afterImage: afterRetouching3,
  },
  {
    beforeImage: beforeRetouching,
    afterImage: afterRetouching,
  },
  {
    beforeImage: beforeRetouching1,
    afterImage: afterRetouching1,
  },
  {
    beforeImage: beforeRetouching4,
    afterImage: afterRetouching4,
  },
  {
    beforeImage: beforeRetouching5,
    afterImage: afterRetouching5,
  },
  {
    beforeImage: beforeRetouching2,
    afterImage: afterRetouching2,
  },
];

const ModelRetouching = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Model Retouching</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Elevate your image to a professional standard by expertly removing any distracting props, smoothing out fabric creases, reducing wrinkles, refining shape and symmetry, and skillfully cleaning up skin blemishes, ensuring a polished and refined final result.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModelRetouching;
