import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeClipping from "../../../images/CP/Before/545012 Lantaarn Groep_.jpg"
import afterClipping from "../../../images/CP/After/545012 Lantaarn Groep.jpg"
import beforeMasking from "../../../images/Masking/Before/IMG_0005_Before.png"
import afterMasking from "../../../images/Masking/After/IMG_0005_After.png"
import threesixty from "../../../images/360/360_gif.gif";
import { Link } from "react-router-dom";
import "./BeforeAfterSlider.css"

const serviceData = [
  {
    service: "Masking",
    beforeImage: beforeMasking,
    afterImage: afterMasking,
    path: "/image-masking-services"
  },
  {
    service: "Clipping Paths",
    beforeImage: BeforeClipping,
    afterImage: afterClipping,
    path: "/clipping-Paths"
  },
  {
    service: "360",
    beforeImage: null,
    afterImage: null,
    path: "/threesixty"
  },
];


const BeforeAfterSlider = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto pt-12 relative">
      {/* <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-[#770099] text-center">Boost Your Images with Our Skilled Services 🚀</h1> */}
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-[#770099] text-center">Boost Your Images with Our Skilled Services</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-4 text-center mx-auto px-4 sm:px-8">
        With just a few clicks, access professional editing for thousands of photos daily, guaranteeing your visual content is always at its best.
      </p>
       <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:mx-10">
          {serviceData.map((data, index) => (
            <div key={index} 
            className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
                <Link to={data.path}>
                {data.service === "360" ? (
                  <div>
                    <img src={threesixty} alt="360Image" style={{ width: "100%", height: "auto" }} />
                  </div>
                ) : (               
                   <ReactCompareImage
                    hover
                    handleSize={40}
                    rightImage={data.afterImage}
                    leftImage={data.beforeImage}
                    leftImageCss={{
                      width: "100%",
                      height: "auto",
                    }}
                    rightImageCss={{
                      width: "100%",
                      height: "auto",
                    }}              
                  />
                )}
                <p className="text-base hover:underline hover:text-[#770099] sm:text-lg md:text-base lg:text-lg font-semibold mt-2">{data.service}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BeforeAfterSlider;