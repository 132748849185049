import React from "react";
import people2 from "../../../images/Home/man (1).png";

const TestimonialTwo = () => {
  const reviews = [
    {
      _id: 2,
      name: "Eddie Nash",
      review:
        "The accessory, shoes, and jewelry pages on our site have received a notable upgrade, presenting a more polished and visually appealing look than ever before.",
      position: "Sr. Global Marketing Manager",
      img: people2,
    },
  ];

  return (
    <section className="my-10 container mx-auto">
      <div className="flex flex-col items-center space-y-6 md:flex-row md:justify-center">
        {reviews.map((review) => (
          <div
            key={review._id}
            className="max-w-2xl bg-white rounded-lg shadow-inherit p-6 flex flex-col items-center md:items-start md:flex-row md:space-x-4"
          >
            <img
              src={review.img}
              alt="our-team"
              className="w-24 h-24 object-cover rounded-full cursor-pointer mb-4 md:mb-0"
            />
            <div className="flex-grow">
              <p className="text-lg text-[#666f7e] font-bold text-center md:text-left">
                {review.review}
              </p>
              <div className="text-center md:text-left">
                <div className="text-base font-bold">{review.name}</div>
                <div className="text-sm text-[#a7aaae]">{review.position}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialTwo;
