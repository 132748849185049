// import React from 'react';
// import { FaCheckCircle } from 'react-icons/fa';
// import "./PackageCard.css";
// import { useState } from 'react';
// import ModalPackageForm from '../../ModalPackageForm/ModalPackageForm';

// function PackageCard(props) {
//   const { title, time, description, features } = props;
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedChat, setSelectedChat] = useState(null);

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

  
//   const handleChoosePlan = () => {
//     setIsModalOpen(true);
//   };


//   return (
//     <div className="bg-white card hover:bg-[#770099] text-[#770099] py-14 hover:text-white rounded-lg shadow-2xl p-4 hover:shadow-xl transform transition-transform hover:scale-105 duration-300">
//       <div className="justify-between items-center">
//       <h2 className="text-3xl  font-extrabold">{title}</h2>
//         <h2 className="text-2xl font-semibold ">{time}</h2>
//       </div>
//       <p className="text-lg text-black text py-3">{description}</p>
//       <div className="mt-4">
//         <ul className="pl-10">
//           {
//             features.map((feature) => <li feature={feature} className="flex items-center gap-2 py-3 font-semibold text text-black ">
//             <FaCheckCircle size={25} className="text-[#770099] mr-2" /> {feature}
//           </li>)
//           }
//             {/* <li className="flex items-center gap-2 py-3 font-semibold text text-black ">
//               <FaCheckCircle size={25} className="text-[#770099] mr-2" /> {features}
//             </li> */}
//         </ul>
//       </div>
//        <div className="mt-6 text-center">
        
//         <button onClick={handleChoosePlan}  className="bg-gradient-to-r from-purple-500 to-purple-800 text-white px-8 py-3 rounded-full hover:text-stone-950 transition duration-300">
//           Choose Plan
//         </button>
//       </div>
//       <ModalPackageForm isModalOpen={isModalOpen} closeModal={closeModal}/>
//       {selectedChat}
//     </div>
//   );
// }

// export default PackageCard;

//**********************************/


// import { FaCheckCircle } from "react-icons/fa";
// import "./PackageCard.css";

// const PackageCard = ({pack, handleChoosePlan, handleCustomizePlan}) => {
//   const { title, time, description, features } = pack;

//   return (
//     <div className="bg-white card hover:bg-[#770099] border-4 hover:border-white  text-[#770099] py-14 hover:text-white rounded-lg shadow-2xl p-4 hover:shadow-xl transform transition-transform hover:scale-105 duration-500">
//       <div className="justify-between items-center">
//         <h2 className="text-3xl font-extrabold">{title}</h2>
//         <h2 className="text-2xl font-semibold ">{time}</h2>
//       </div>
//       <p className="text-lg text-black text py-3">{description}</p>
//       <div className="mt-4">
//         <ul className="pl-10">
//           {features.map((feature) => (
//             <li feature={feature} className="flex items-center gap-2 py-3 font-semibold text text-black ">
//               <FaCheckCircle size={25} className="text-[#770099] mr-2" /> {feature}
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div className="mt-6 text-center">
//         {title === 'CUSTOMIZE' ? (
//           <button
//             onClick={handleCustomizePlan}
//             className="bg-gradient-to-r from-purple-500 to-purple-800 text-white px-8 py-3 rounded-full hover:text-stone-950 transition duration-300" 
//           >
//             Customize Plan
//           </button>
//         ): <button
//         onClick={handleChoosePlan}
//         className="bg-gradient-to-r from-purple-500 to-purple-800 text-white px-8 py-3 rounded-full hover:text-stone-950 transition duration-300" 
//       >
//         Choose Plan
//       </button> }
//       </div>
//     </div>
//   );
// }

// export default PackageCard;


import { FaCheckCircle } from "react-icons/fa";
import "./PackageCard.css";

const PackageCard = ({pack, handleChoosePlan, handleCustomizePlan}) => {
  const { title, time, description, features } = pack;

  return (
    <div className="bg-white card hover:bg-[#770099] border-4 hover:border-white text-[#770099] py-14 hover:text-[#ffff] rounded-lg shadow-2xl p-4 hover:shadow-xl transform transition-transform hover:scale-105 duration-500">
      <div className="justify-between items-center">
        <p className="text-3xl font-extrabold">{title}</p>
        <p className="text-2xl font-semibold">{time}</p>
      </div>
      <p className="text-lg hover:text-white py-3">{description}</p>
      <div className="mt-4 hover:text-white">
        <ul className="text-base text-black">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center py-3 font-semibold">
              <FaCheckCircle size={25} className="text-[#770099] mr-2" /> {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6 text-center">
        {title === 'CUSTOMIZE' ? (
          <button
            onClick={handleCustomizePlan}
            className="bg-gradient-to-r from-purple-500 to-purple-800 text-white px-8 py-3 rounded-full hover:text-stone-950 transition duration-300"
          >
            Customize Plan
          </button>
        ) : (
          <button
            onClick={handleChoosePlan}
            className="bg-gradient-to-r from-purple-500 to-purple-800 text-white px-8 py-3 rounded-full hover:text-stone-950 transition duration-300"
          >
            Choose Plan
          </button>
        )}
      </div>
    </div>
  );
}

export default PackageCard;
