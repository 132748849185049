import React from 'react';
import BeforeAfterSlider from '../BeforeAfterSlider/BeforeAfterSlider';
import EcommerceRetouching from '../EcommerceRetouching/EcommerceRetouching';
import OtherServices from '../OtherServices/OtherServices';
import GarmentsProducts from '../../GarmentsProducts/GarmentsProducts';

const OurServices = () => {
    return (
        <div>
            <BeforeAfterSlider/>
            <EcommerceRetouching/>
            <GarmentsProducts/>
            <OtherServices/>
        </div>
    );
};

export default OurServices;