import React from 'react';
import {FaFacebook, FaLinkedin, FaRegEnvelope, FaSkype, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="py-16 container mx-auto bg-[#770099] text-white">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-around">
          <div className="md:mr-8 text-center md:text-left">
            <h3 className="text-lg text-white font-semibold ml-6">Services</h3>
            <ul className="list-none">
              <li><a href="#"></a></li>
              <li><a href="#">Branding</a></li>
              <li><a href="#">Design</a></li>
              <li><a href="#">Marketing</a></li>
            </ul>
          </div>
          <div className="text-center flex lg:ml-5 flex-col justify-center items-center mb-2">
          <p className="text-lg">
            Copyright © {new Date().getFullYear()} All rights reserved.
          </p>
          <p className="text-lg">Skill Service Limited</p>
          <div className="mt-4 flex justify-center">
          <a
            href="https://www.facebook.com/skillgraphics.biz/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-[#1877F2] mx-2"
            style={{ textDecoration: 'none' }}
          >
            <FaFacebook size={32} />
          </a>
          <a
            href="https://www.linkedin.com/company/skill-graphics-ltd./"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-500 mx-2"
          >
            <FaLinkedin size={32} />
          </a>
          <a
            href="https://join.skype.com/invite/JCh6h87c0V7C"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-500 mx-2"
          >
            <FaSkype size={32} />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=8801723099983"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-[#25D366] mx-2"
          >
            <FaWhatsapp size={32} />
          </a>
          <a
            href="https://www.youtube.com/@MVRKStudios"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-red-700 mx-2"
          >
            <FaYoutube size={32} />
          </a>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@skillgraphics.biz"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-red-700 mx-2"
          >
            <FaRegEnvelope  size={32} />
          </a>
        </div>
      </div>
          <div className="md:ml-8 md:mt-0 text-center md:text-left">
            <h3 className="text-lg text-white font-semibold ml-6 mb-1.5">Legal</h3>
            <ul className="list-none">
              <li className=''><Link to="/termsAndConditions">Terms of Conditions</Link></li>
              <li className=''><Link to="/privacyPolicy">Privacy policy</Link></li>
              <li className=''><Link to="/about">About Us</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
