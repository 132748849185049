import React from 'react';
import Ecommerce from "../../../images/Animation/Scripted.mp4";

const ImageEditingAiExample = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-16">
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-[#770099] text-center">Integrating cutting-edge AI into our image editing process,</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto lg:px-24 sm:px-8">
      We're revolutionizing efficiency and quality. Stay connected with our advanced technology for seamless, cost-effective results. Experience the future of image editing, tailored for the fashion and e-commerce industries.
      </p>
      <div className="container mx-auto py-2 px-4 sm:px-8">
        <div className="flex justify-center">
          <video
            controls
            autoPlay
            loop
            muted
            controlsList="nodownload"
            className="max-w-full md:max-w-3xl rounded-lg shadow-lg border-4 border-[#581C87] hover:border-[#581C87] transition-all duration-300 transform"
          >
            <source src={Ecommerce} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default ImageEditingAiExample;
