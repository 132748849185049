import React from "react";
import NotFoundImage from "../../images/Home/not-found-page.png";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center my-14">
      <h1 className="text-5xl text-purple-800 mb-4">404 - Not Found</h1>
      <p className="text-3xl text-purple-800">The page you're looking for does not exist.</p>
      <img className="w-full max-w-lg rounded-lg" src={NotFoundImage} alt="404" />
    </div>
  );
};

export default NotFoundPage;
