//... OLD DESIGN.....

// import React from 'react';
// import workflow from '../../images/Home/workflow.png';

// const EffortlessWorkflow = () => {
//   return (
//     <section className="container mx-auto px-8 bg-[#770099] text-white">
//       <div className="container mx-auto flex flex-col md:flex-row items-center">
//         <div className="md:w-2/4 mb-6 md:mb-0">
//           <img
//             src={workflow}
//             alt="Scale With Confidence"
//             className="max-w-full h-auto md:h-full rounded-lg transform transition-transform duration-300"
//           />
//         </div>
//         <div className="text-justify md:w-2/4 md:ml-6 lg:pr-8">
//           <h1 className="text-3xl font-bold md:text-5xl text-white leading-tight  mb-4">
//             Effortless Workflow
//           </h1>
//           <p className="text-base md:text-lg text-[#ffffff] max-w-md md:max-w-full">
//             At Skill Graphics, we aim to streamline your image post production process. Expect a smooth journey from image submission to the final upload, as we keep you informed every step of the way through email, instant messaging, or SMS. We've perfected a system that keeps you effortlessly connected and updated throughout the entire post production cycle.
//           </p>
//         </div>
  
//       </div>
//     </section>
//   );
// };

// export default EffortlessWorkflow;



/******new***********/

import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './EffortlessWorkflow.css';
import workflow from "../../images/Home/tree.png";
gsap.registerPlugin(ScrollTrigger);

const EffortlessWorkflow = () => {
  const stepsRef = useRef([]);

  useEffect(() => {
    stepsRef.current.forEach((step, index) => {
      gsap.fromTo(
        step,
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: step,
            start: 'top 80%',
            end: 'bottom 20%',
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
  }, []);

  return (
    <section className="container mx-auto bg-slate-200 px-4 sm:px-8 py-16 md:py-24">
      <div className="flex flex-col items-center text-center">
        <h1 className="text-3xl text-[#770099] font-bold md:text-5xl leading-tight mb-4">
          Effortless Workflow
        </h1>
        <p className="text-sm sm:text-base md:text-lg max-w-xl sm:max-w-2xl md:max-w-4xl mb-10">
          At Skill Graphics, we aim to streamline your image post production process. Expect a smooth journey from image submission to the final upload, as we keep you informed every step of the way through email, instant messaging, or SMS. We've perfected a system that keeps you effortlessly connected and updated throughout the entire post production cycle.
        </p>
        <img
          src={workflow}
          alt="Effortless Workflow"
          className="w-full h-auto rounded-lg transform transition-transform duration-300"
        />
      </div>
    </section>
  );
};

export default EffortlessWorkflow;
