import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="bg-white p-8 rounded-md shadow-lg w-full">
        <h1 className="text-4xl text-[#770099] font-bold mb-8 underline text-center">Terms of Service</h1>

        <p className="mb-6 text-justify">
          Welcome to Skill Graphics! These terms and conditions outline the rules and regulations for the use of our website and services.
        </p>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p className="text-justify">
            By accessing this website and using our services, you agree to be bound by these terms and conditions. If you disagree with any part of these terms, you may not use our website or services.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">2. Services</h2>
          <p className="text-justify">
            Our services include, but are not limited to, image post-production for Web shops/e-commerce, Photography, Fashion, Advertising, and Digital Marketing Agencies. The services are provided based on the terms outlined in this document.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">3. User Responsibilities</h2>
          <p className="text-justify">
            Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users agree not to share their account credentials with others and to notify Skill Graphics immediately of any unauthorized use of their account.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">4. Termination</h2>
          <p className="text-justify">
            Skill Graphics reserves the right to terminate or suspend access to our website and services without prior notice for any violation of these terms of service.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">5. Changes to Terms</h2>
          <p className="text-justify">
            Skill Graphics may update these terms and conditions at any time. Users are encouraged to check this page regularly for any changes. Continued use of our website and services after the posting of changes constitutes acceptance of those changes.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">6. Contact Information</h2>
          <p className="text-justify">
            For inquiries regarding our terms of service or any other matter, please contact us at <a href="mailto:support@skillgraphics.biz" className="text-purple-700">support@skillgraphics.biz</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
