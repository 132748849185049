import React from "react";
import ReactCompareImage from "react-compare-image";
import { Link } from "react-router-dom";
import beforeColorMatch from "../../images/Color_Change/Before/Before_1400px.jpg";
import afterColorMatch from "../../images/Color_Change/After/After_1400px.jpg";
import beforeInvisible from "../../images/Invisible/before/maillot_Before.png";
import afterInvisible from "../../images/Invisible/after/maillot_After.png";
import BeforeFlatlay from "../../images/Flat lay/before/1101188_khaki_p_before.png";
import afterFlatlay from "../../images/Flat lay/after/1101188_khaki_p_After.png";
import ShadowsAfter from "../../images/shadow/after/After_230120_RAW-023.jpg";
import ShadowsBefore from "../../images/shadow/before/Before_230120_RAW-023.jpg";

const serviceData = [
  {
    service: "Invisible Mannequin",
    beforeImage: beforeInvisible,
    afterImage: afterInvisible,
    path: "/invisibleMannequin",
  },
  {
    service: "Shadows",
    beforeImage: ShadowsBefore,
    afterImage: ShadowsAfter,
    path: "/shadows",
  },
  {
    service: "Color Match",
    beforeImage: beforeColorMatch,
    afterImage: afterColorMatch,
    path: "/colorMatch",
  },
  {
    service: "Flat lay",
    beforeImage: BeforeFlatlay,
    afterImage: afterFlatlay,
    path: "/flatlay",
  },
];

const GarmentsProducts = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-[#770099] text-center">
        Garments Products
      </h1>
      <div className="container mx-auto text-center">
        <p className="text-lg text-gray-700 mb-4">
          Transform your garment products into works of art with our specialized
          services <br/> in Invisible Mannequin, Shadows Enhancement, Color Match, and
          Flat Lay photography.
        </p>
      </div>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 lg:mx-10">
          {serviceData.map((data, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4"
            >
              <Link to={data.path}>
                <ReactCompareImage
                  hover
                  handleSize={40}
                  leftImage={data.beforeImage}
                  rightImage={data.afterImage}
                  leftImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                  rightImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                />
                <p className="text-base hover:underline hover:text-[#770099] sm:text-lg md:text-base lg:text-lg font-semibold mt-2">{data.service}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
   
    </div>
  );
};

export default GarmentsProducts;
