/*********old**********/
import React from "react";
import ReactCompareImage from "react-compare-image";
import beforeRetouching1 from "../../../src/images/Retouching/Before/level-02-01_Before.png"
import afterRetouching1 from "../../../src/images/Retouching/After/level-02-01_After.png"
import beforeRetouching2 from "../../../src/images/Retouching/Before/Before_original 1.jpg"
import afterRetouching2 from "../../../src/images/Retouching/After/After_original 1.jpg"
import beforeRetouching4 from "../../../src/images/Retouching/Before/level-01-01-REVIEWED.png"
import afterRetouching4 from "../../../src/images/Retouching/After/level-01-01-REVIEWED_A.png"
import beforeRetouching3 from "../../../src/images/Retouching/Before/Cecconello_to edit2_Before.png"
import afterRetouching3 from "../../../src/images/Retouching/After/Cecconello_to edit2._After.png"
import beforeRetouching5 from "../../../src/images/Retouching/Before/DSC_3186 cut out and clean leather.png"
import afterRetouching5 from "../../../src/images/Retouching/After/DSC_3186 cut out and clean leather_A.png"
import beforeRetouching6 from "../../../src/images/Retouching/Before/Before_DSC05624.jpg"
import afterRetouching6 from "../../../src/images/Retouching/After/After_DSC05624.jpg"

const serviceData = [
  {
    beforeImage: beforeRetouching4,
    afterImage: afterRetouching4,
  },
  {
    beforeImage: beforeRetouching2,
    afterImage: afterRetouching2,
  },
  {
    beforeImage: beforeRetouching5,
    afterImage: afterRetouching5,
  },
  {
    beforeImage: beforeRetouching3,
    afterImage: afterRetouching3,
  },
  {
    beforeImage: beforeRetouching1,
    afterImage: afterRetouching1,
  },
   {
    beforeImage: beforeRetouching6,
    afterImage: afterRetouching6,
  },
];

const Retouching = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Retouching</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Elevate your image to a professional standard by expertly removing any distracting props, smoothing out fabric creases, reducing wrinkles, refining shape and symmetry, and skillfully cleaning up skin blemishes, ensuring a polished and refined final result.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Retouching;


/******************NEW**********************/

// import React from 'react';
// import RetouchingBanner from './RetouchingBanner';
// import Brandings from '../Home/Brandings';
// import RetouchingPassionWork from './RetouchingPassionWork';
// import TestimonialOne from '../Home/Testimonials/TestimonialOne';
// import Services from '../Home/Services';
// import RetouchingQuestion from './RetouchingQuestion';
// import RetouchingServices from './RetouchingServices';
// import OtherBusinessServices from '../Masking/OtherBusinessServices';
// import TestimonialThree from '../Home/Testimonials/TestimonialThree';
// import HowItWorks from '../Home/HowItWorks/HowItWorks';
// import BusinessSummary from '../Home/BusinessSummary/BusinessSummary';
// import PackageSection from '../Home/PackageSection/PackageSection';
// import ServiceQuality from '../Home/ServiceQuality';
// import TalkOurProduct from '../Home/TalkOurProduct';
// import Footer from '../Shared/Footer';
// import {Helmet} from "react-helmet";
// import RetouchingHeroSections from './RetouchingHeroSections/RetouchingHeroSections';


// const Retouching = () => {
//   return (
//     <div className="container mx-auto">
//        <Helmet>
//         <meta charSet="utf-8" />
//         <meta name="description" content="Elevate your images with expert photo retouching services. Our professionals refine every detail for flawless results. Discover perfection at SkillGraphics." />
//         <title>Professional Photo Retouching Services to Perfect Your Images | Skill Graphics</title>
//       </Helmet>
//     <RetouchingBanner/>
//     <Brandings/>
//     <RetouchingPassionWork/>
//     <RetouchingHeroSections/>
//     <TestimonialOne/>
//     <Services/>
//     <RetouchingServices/>
//     <OtherBusinessServices/>
//     <TestimonialThree />
//     <HowItWorks/>
//     <BusinessSummary/>
//     <PackageSection/>
//     <RetouchingQuestion/>
//     <ServiceQuality />
//    <TalkOurProduct />
//     <Footer />
//     </div>
//   );
// };

// export default Retouching;