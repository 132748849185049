import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white shadow-md rounded-md text-justify">
      <h1 className="text-4xl font-bold mb-8 underline text-[#770099] text-center">Terms and Conditions</h1>

      <section className="mb-8">
        <p className="text-gray-700">
          By accessing this website, you agree to these terms and conditions. Welcome to our website and thank you for considering our IT services. Before using our services, please carefully read and understand these Terms and Conditions (“Terms”) as they govern your use of our website and the IT services provided by us. By accessing or using our services, you agree to comply with these terms. If you do not agree with any part of these terms, please refrain from using our services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Service Provision</h2>
        <p className="text-gray-700">
          We will provide the Services to you in accordance with these Terms and any applicable agreements or specifications agreed upon separately. We reserve the right to modify, suspend, or terminate the Services (or any part thereof) at any time, with or without prior notice.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Intellectual Property Rights</h2>
        <p className="text-gray-700">
          All intellectual property rights, including but not limited to copyrights, trademarks, and patents, relating to the Services and the content on our website, are either owned by us or licensed to us. You may not use, reproduce, modify, distribute, or create derivative works based on the Services or the content without our prior written consent.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. User Obligations</h2>
        <p className="text-gray-700">
          You agree to use the services solely for lawful purposes and in compliance with these Terms and any applicable laws and regulations. You are responsible for ensuring the confidentiality and security of any account credentials or access codes provided to you for using the services. You must not engage in any activities that may disrupt or interfere with the proper functioning of the Services or infringe upon the rights of others.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Privacy and Data Protection</h2>
        <p className="text-gray-700">
          We respect your privacy and handle your personal data in accordance with our Privacy Policy. By using our Services, you consent to the collection, processing, and storage of your personal information as outlined in our Privacy Policy. You are responsible for ensuring that any data or information you provide to us is accurate, complete, and complies with applicable data protection laws.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Payment Terms</h2>
        <p className="text-gray-700">
          If the Services require payment, you agree to pay the fees specified in the applicable agreement or invoice. Any payment terms, including payment methods, due dates, and late payment charges, will be specified in the relevant agreement or invoice.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Limitations on Liability</h2>
        <p className="text-gray-700">
          To the maximum extent permitted by law, we exclude all liability for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of our Services. We shall not be liable for any loss, damage, or delay resulting from events beyond our reasonable control, including but not limited to acts of God, natural disasters, war, or governmental actions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Termination</h2>
        <p className="text-gray-700">
          Either party may terminate the provision of Services at any time, subject to any applicable agreement or notice period. Upon termination, any outstanding fees or obligations owed to us shall become immediately due and payable.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Hyperlinking to Our Content</h2>
        <p className="text-gray-700">
          We welcome and encourage hyperlinking to our website from government agencies, search engines, news organizations, and online directory distributors. Systemwide accredited businesses may also hyperlink to our website. However, soliciting non-profit organizations, and charity fundraising groups may not hyperlink to our website. We reserve the right to approve or deny any link request at our discretion.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Iframes</h2>
        <p className="text-gray-700">
          Without our prior approval and written permission, you may not create frames around our web pages that alter in any way the visual presentation or appearance of our website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Removal of Links from Our Website</h2>
        <p className="text-gray-700">
          If you find any link on our website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to do so or respond to you directly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">11. Governing Law and Jurisdiction</h2>
        <p className="text-gray-700">
          These Terms shall be governed by and construed in accordance with the laws of the jurisdiction without regard to its conflict of laws principles. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of the jurisdiction.
        </p>
      </section>

      <section className="mb-8">
        <p className="text-gray-700">
          Please read these Terms carefully before using our Services. By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and any applicable agreements or policies.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
