/***************** Old Design *************************/

// import { Link } from 'react-router-dom';
// import React, { useState, useEffect, useRef } from 'react';
// import { FaBars, FaCamera, FaChevronDown, FaCube, FaTimes, FaVideo } from 'react-icons/fa';
// import logo from '../../images/Logo/Skill.png';

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isSmallHeader, setIsSmallHeader] = useState(false);
//   const [servicesState, setServicesState] = useState({});
//   const [currentTime, setCurrentTime] = useState('');
//   const servicesRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const today = new Date();
//       const time =
//         today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//       setCurrentTime(time);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setIsSmallHeader(true);
//       } else {
//         setIsSmallHeader(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (servicesRef.current && !servicesRef.current.contains(event.target)) {
//         setServicesState({});
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//     setServicesState({});
//   };

//   const toggleServices = (service) => {
//     setServicesState((prevServicesState) => ({
//       ...prevServicesState,
//       [service]: !prevServicesState[service],
//     }));
//   };

//   const services = [
//     {
//       name: 'Image',
//       icon: <FaCamera />,
//       submenus: [
//         { name: 'Al Services', path: '/allservices' },
//         { name: 'Masking', path: '/image-masking-services' },
//         { name: 'Retouching', path: '/photo-retouching' },
//         { name: 'Clipping', path: '/clipping-Paths' },
//         { name: 'Color', path: '/colorMatch' },
//         { name: 'Invisible', path: '/invisible' },
//         { name: 'Flatlay', path: '/flatlay' },
//         { name: 'Jewellery', path: '/jewelleryRetouching' },
//         { name: 'Model', path: '/modelRetouching' },
//         { name: 'Shadow', path: '/shadow' },
//         { name: 'Color Adjust', path: '/colorAdjust' },
//         { name: 'Manipulation', path: '/manipulation' },
//         { name: 'Restoration', path: '/restoration' },
//         { name: '360', path: '/threesixty' },
//       ],
//     },
//     {
//       name: 'VFX',
//       icon: <FaVideo />,
//       url: 'http://mvrk.tv/vfx/',
//     },
//     {
//       name: '3D',
//       icon: <FaCube />,
//       url: 'https://auleek.com/',
//     },
//   ];

//   return (
//     <header className={`${isSmallHeader ? 'h-20' : 'h-24'} container mx-auto shadow-lg bg-slate-50 text-black sticky top-0 z-40 backdrop-blur-md backdrop-hue-rotate-30 w-full transition-all duration-300 ease-in-out`}>
//       <nav className="mx-4 flex justify-between items-center h-full">
//         {/* <div className="font-semibold">
//           <Link onClick={() => window.location.href = '/'}>
//             <img
//               src={logo}
//               className={`${isSmallHeader ? 'w-16 transition-all duration-500' : 'w-24 transition-all duration-500'}`}
//               alt="logo"
//             />
//           </Link>
//         </div> */}
//          <div className="font-semibold m-0 mb-0">
//           <Link onClick={() => window.location.href = '/'}>
//             <img
//               src={logo}
//               className={`${isSmallHeader ? 'w-16 transition-all duration-500' : 'w-24 transition-all duration-500'} mb-0`} // Adjusted margin-bottom here
//               alt="logo"
//             />
//           </Link>
//         </div>
//         <div className="md:hidden">
//           <button onClick={toggleMenu}>
//             {isOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
//           </button>
//         </div>
//      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} py-5 w-full bg-[#f8fafc] absolute top-20 left-0 z-50`}>
//    <div className="mb-1">
//     <Link
//       to="/"
//       className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//     >
//       Home
//     </Link>
//   </div>
//   <div className='mb-1'>
//     <Link
//       to="/about"
//       className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//     >
//       About
//     </Link>
//   </div>
//   <div className="flex justify-center">
//   <div
//     onClick={() => toggleServices('Services')}
//     className="flex items-center font-semibold md:inline-block p-2 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//   >
//     Services <FaChevronDown className="ml-1" />
//   </div>

//   <div
//     ref={servicesRef}
//     className={`${
//       servicesState['Services'] ? 'block' : 'hidden'
//     } absolute top-40 left-1/2 transform -translate-x-1/2 bg-[#f8fafc] p-10`}
//   >
//     {services.map((service, index) => (
//       <div key={index} className="mb-2">
//         {service.url ? (
//           <a
//             href={service.url}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//           >
//             {service.icon && <span className="mr-2">{service.icon}</span>}
//             {service.name}
//           </a>
//         ) : (
//           <Link to={service.submenus ? service.path : '#'}>
//             <button
//               onMouseEnter={() => toggleServices(service.name)}
//               onClick={() => toggleServices(service.name)}
//               className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//             >
//               {service.icon && <span className="mr-2">{service.icon}</span>}
//               {service.name}
//             </button>
//           </Link>
//         )}
//         {service.submenus && servicesState[service.name] && (
//            <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute top-0 left-full bg-[#f8fafc] p-6`}>
//             {service.submenus.map((submenu, subIndex) => (
//               <Link
//                 key={subIndex}
//                 to={submenu.path}
//                 className="block font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//               >
//                 {submenu.name}
//               </Link>
//             ))}
//           </div>
//         )}
//       </div>
//            ))}
//          </div>
//        </div>
//          <div className="mb-1">
//          <Link
//          to="/contact"
//          className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//          >
//            Contact
//          </Link>
//        </div>
//        </div>
//         <div className={`md:flex md:space-x-5 hidden items-center`}>
//           <Link to="/" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out">
//             Home
//           </Link>
//           <Link to="/about" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out">
//             About
//           </Link>
//           <div className="relative">
//            <button 
//              onMouseEnter={() => toggleServices('Services')} 
//              className="flex items-center font-semibold p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//            >
//              <span>Services </span>
//              <FaChevronDown className="ml-1" />
//            </button>
//            <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-full left-0 bg-[#f8fafc] p-4`}>
//             {services.map((service, index) => (
//             <div key={index} className="mb-2">
//               {service.url ? (
//                 <a href={service.url} target="_blank" rel="noopener noreferrer" className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out">
//                   {service.icon && <span className="mr-2">{service.icon}</span>}
//                   {service.name}
//                 </a>
//               ) : (
//                 // <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-0 left-full ml-2 bg-[#f8fafc] p-4`}>
//                 <Link to={service.submenus ? service.path : '#'}> 
//                   <button
//                     onMouseEnter={() => toggleServices(service.name)}
//                     onClick={() => toggleServices(service.name)}
//                     className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                   >
//                 {service.icon && <span className="mr-2">{service.icon}</span>}
//                 {service.name}
//                  </button>
//                  </Link>
//                 )}
//                {service.submenus && servicesState[service.name] && (
//                 <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute -top-0.5 left-full bg-[#f8fafc] p-4`}>
//                    {service.submenus.map((submenu, subIndex) => (
//                      <Link  key={subIndex} to={submenu.path} className="block px-4 py-1 text-justify font-semibold hover:bg-fuchsia-800 hover:text-white transition-all duration-300 ease-in-out">
//                        {submenu.name}
//                      </Link>
//                    ))}
//                  </div>
//                )}
//              </div>
//            ))}
//            </div>
//           </div>
//           <Link to="/contact" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out">
//             Contact
//           </Link>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;


// import { Link } from 'react-router-dom';
// import React, { useState, useEffect, useRef } from 'react';
// import { FaBars, FaCamera, FaChevronDown, FaCube, FaTimes, FaVideo } from 'react-icons/fa';
// import logo from '../../images/Logo/Skill.png';

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isSmallHeader, setIsSmallHeader] = useState(false);
//   const [servicesState, setServicesState] = useState({});
//   const [currentTime, setCurrentTime] = useState('');
//   const servicesRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const today = new Date();
//       const time =
//         today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//       setCurrentTime(time);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setIsSmallHeader(true);
//       } else {
//         setIsSmallHeader(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (servicesRef.current && !servicesRef.current.contains(event.target)) {
//         setServicesState({});
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//     setServicesState({});
//   };

//   const toggleServices = (service) => {
//     setServicesState((prevServicesState) => ({
//       ...prevServicesState,
//       [service]: !prevServicesState[service],
//     }));
//   };

//   const services = [
//     {
//       name: 'Image',
//       icon: <FaCamera />,
//       submenus: [
//         { name: 'Al Services', path: '/allservices' },
//         { name: 'Masking', path: '/image-masking-services' },
//         { name: 'Retouching', path: '/photo-retouching' },
//         { name: 'Clipping', path: '/clipping-Paths' },
//         { name: 'Color', path: '/colorMatch' },
//         { name: 'Invisible', path: '/invisible' },
//         { name: 'Flatlay', path: '/flatlay' },
//         { name: 'Jewellery', path: '/jewelleryRetouching' },
//         { name: 'Model', path: '/modelRetouching' },
//         { name: 'Shadow', path: '/shadow' },
//         { name: 'Color Adjust', path: '/colorAdjust' },
//         { name: 'Manipulation', path: '/manipulation' },
//         { name: 'Restoration', path: '/restoration' },
//         { name: '360', path: '/threesixty' },
//       ],
//     },
//     {
//       name: 'VFX',
//       icon: <FaVideo />,
//       url: 'http://mvrk.tv/vfx/',
//     },
//     {
//       name: '3D',
//       icon: <FaCube />,
//       url: 'https://auleek.com/',
//     },
//   ];

//   return (
//     <header className={`${isSmallHeader ? 'h-20' : 'h-24'} container mx-auto shadow-lg bg-slate-50 text-black sticky top-0 z-40 backdrop-blur-md backdrop-hue-rotate-30 w-full transition-all duration-300 ease-in-out`}>
//       <nav className="mx-4 flex justify-between items-center h-full">
//         <div className="font-semibold m-0 mb-0">
//           <Link onClick={() => window.location.href = '/'}>
//             <img
//               src={logo}
//               className={`${isSmallHeader ? 'w-16 transition-all duration-500' : 'w-24 transition-all duration-500'} mb-0`}
//               alt="logo"
//             />
//           </Link>
//         </div>
//         <div className="md:hidden">
//           <button onClick={toggleMenu}>
//             {isOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
//           </button>
//         </div>
//         <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} py-5 w-full bg-[#f8fafc] absolute top-20 left-0 z-50`}>
//           <div className="mb-1">
//             <Link
//               to="/"
//               className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               style={{ textDecoration: 'none' }} // Ensure no underline
//             >
//               Home
//             </Link>
//           </div>
//           <div className='mb-1'>
//             <Link
//               to="/about"
//               className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               style={{ textDecoration: 'none' }} // Ensure no underline
//             >
//               About
//             </Link>
//           </div>
//           <div className="flex justify-center">
//             <div
//               onClick={() => toggleServices('Services')}
//               className="flex items-center font-semibold md:inline-block p-2 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               style={{ textDecoration: 'none' }} // Ensure no underline
//             >
//               Services <FaChevronDown className="ml-1" />
//             </div>
//             <div
//               ref={servicesRef}
//               className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-40 left-1/2 transform -translate-x-1/2 bg-[#f8fafc] p-10`}
//               style={{ textDecoration: 'none' }} // Ensure no underline
//             >
//               {services.map((service, index) => (
//                 <div key={index} className="mb-2">
//                   {service.url ? (
//                     <a
//                       href={service.url}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                       style={{ textDecoration: 'none' }} // Ensure no underline
//                     >
//                       {service.icon && <span className="mr-2">{service.icon}</span>}
//                       {service.name}
//                     </a>
//                   ) : (
//                     <Link to={service.submenus ? service.path : '#'}>
//                       <button
//                         onMouseEnter={() => toggleServices(service.name)}
//                         onClick={() => toggleServices(service.name)}
//                         className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                         style={{ textDecoration: 'none' }} // Ensure no underline
//                       >
//                         {service.icon && <span className="mr-2">{service.icon}</span>}
//                         {service.name}
//                       </button>
//                     </Link>
//                   )}
//                   {service.submenus && servicesState[service.name] && (
//                     <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute top-0 left-full bg-[#f8fafc] p-6`}>
//                       {service.submenus.map((submenu, subIndex) => (
//                         <Link
//                           key={subIndex}
//                           to={submenu.path}
//                           className="block font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                           style={{ textDecoration: 'none' }} // Ensure no underline
//                         >
//                           {submenu.name}
//                         </Link>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="mb-1">
//             <Link
//               to="/contact"
//               className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               style={{ textDecoration: 'none' }} // Ensure no underline
//             >
//               Contact
//             </Link>
//           </div>
//         </div>
//         <div className={`md:flex md:space-x-5 hidden items-center`}>
//           <Link to="/" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
//             Home
//           </Link>
//           <Link to="/about" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
//             About
//           </Link>
//           <div className="relative">
//             <button 
//               onMouseEnter={() => toggleServices('Services')} 
//               className="flex items-center font-semibold p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               style={{ textDecoration: 'none' }}
//             >
//               <span>Services </span>
//               <FaChevronDown className="ml-1" />
//             </button>
//             <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-full left-0 bg-[#f8fafc] p-4`} style={{ textDecoration: 'none' }}>
//               {services.map((service, index) => (
//                 <div key={index} className="mb-2">
//                   {service.url ? (
//                     <a href={service.url} target="_blank" rel="noopener noreferrer" className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
//                       {service.icon && <span className="mr-2">{service.icon}</span>}
//                       {service.name}
//                     </a>
//                   ) : (
//                     <Link to={service.submenus ? service.path : '#'}> 
//                       <button
//                         onMouseEnter={() => toggleServices(service.name)}
//                         onClick={() => toggleServices(service.name)}
//                         className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                         style={{ textDecoration: 'none' }}
//                       >
//                         {service.icon && <span className="mr-2">{service.icon}</span>}
//                         {service.name}
//                       </button>
//                     </Link>
//                   )}
//                   {service.submenus && servicesState[service.name] && (
//                     <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute -top-0.5 left-full bg-[#f8fafc] p-4`}>
//                       {service.submenus.map((submenu, subIndex) => (
//                         <Link  key={subIndex} to={submenu.path} className="block px-4 py-1 text-justify font-semibold hover:bg-fuchsia-800 hover:text-white transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
//                           {submenu.name}
//                         </Link>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <Link to="/contact" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
//             Contact
//           </Link>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;


import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { FaBars, FaCamera, FaChevronDown, FaCube, FaTimes, FaVideo } from 'react-icons/fa';
import logo from '../../images/Logo/Skill.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallHeader, setIsSmallHeader] = useState(false);
  const [servicesState, setServicesState] = useState({});
  const [currentTime, setCurrentTime] = useState('');
  const servicesRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const today = new Date();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      setCurrentTime(time);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSmallHeader(true);
      } else {
        setIsSmallHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target)) {
        setServicesState({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setServicesState({});
  };

  const toggleServices = (service) => {
    setServicesState((prevServicesState) => ({
      ...prevServicesState,
      [service]: !prevServicesState[service],
    }));
  };

  const services = [
    {
      name: 'Image',
      icon: <FaCamera />,
      submenus: [
        { name: 'Al Services', path: '/allservices' },
        { name: 'Masking', path: '/image-masking-services' },
        { name: 'Retouching', path: '/photo-retouching' },
        { name: 'Clipping', path: '/clipping-Paths' },
        { name: 'Color', path: '/colorMatch' },
        { name: 'Invisible', path: '/invisible' },
        { name: 'Flatlay', path: '/flatlay' },
        { name: 'Jewellery', path: '/jewelleryRetouching' },
        { name: 'Model', path: '/modelRetouching' },
        { name: 'shadows', path: '/shadows' },
        { name: 'Color Adjust', path: '/colorAdjust' },
        { name: 'Manipulation', path: '/manipulation' },
        { name: 'Restoration', path: '/restoration' },
        { name: '360', path: '/threesixty' },
      ],
    },
    {
      name: 'VFX',
      icon: <FaVideo />,
      url: 'http://mvrk.tv/vfx/',
    },
    {
      name: '3D',
      icon: <FaCube />,
      url: 'https://auleek.com/',
    },
  ];

  return (
    <header className={`${isSmallHeader ? 'h-20' : 'h-24'} container mx-auto shadow-lg bg-slate-50 text-black sticky top-0 z-40 backdrop-blur-md backdrop-hue-rotate-30 w-full transition-all duration-300 ease-in-out`}>
      <nav className="mx-4 flex justify-between items-center h-full">
        <div className="font-semibold m-0 mb-0">
          <Link onClick={() => window.location.href = '/'}>
            <img
              src={logo}
              className={`${isSmallHeader ? 'w-16 transition-all duration-500' : 'w-24 transition-all duration-500'} mb-0`}
              alt="logo"
            />
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
          </button>
        </div>
        <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} py-5 w-full bg-[#f8fafc] absolute top-20 left-0 z-50`}>
          <div className="mb-1">
            <Link
              to="/"
              className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Home
            </Link>
          </div>
          <div className='mb-1'>
            <Link
              to="/about"
              className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              About
            </Link>
          </div>
          <div className='mb-1'>
            <Link
              to="/package"
              className="font-semibold inline-block p-2 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Packages
            </Link>
          </div>
          <div className="flex justify-center">
            <div
              onClick={() => toggleServices('Services')}
              className="flex items-center font-semibold md:inline-block p-2 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Services <FaChevronDown className="ml-1" />
            </div>
            <div
              ref={servicesRef}
              className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-40 left-1/2 transform -translate-x-1/2 bg-[#f8fafc] p-10`}
              style={{ textDecoration: 'none' }}
            >
              {services.map((service, index) => (
                <div key={index} className="mb-2">
                  {service.url ? (
                    <a
                      href={service.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
                      style={{ textDecoration: 'none' }}
                    >
                      {service.icon && <span className="mr-2">{service.icon}</span>}
                      {service.name}
                    </a>
                  ) : (
                    <Link to={service.submenus ? service.path : '#'}>
                      <button
                        onMouseEnter={() => toggleServices(service.name)}
                        onClick={() => toggleServices(service.name)}
                        className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
                        style={{ textDecoration: 'none' }}
                      >
                        {service.icon && <span className="mr-2">{service.icon}</span>}
                        {service.name}
                      </button>
                    </Link>
                  )}
                  {service.submenus && servicesState[service.name] && (
                    <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute top-0 left-full bg-[#f8fafc] p-6`} style={{ textDecoration: 'none' }}>
                      {service.submenus.map((submenu, subIndex) => (
                        <Link
                          key={subIndex}
                          to={submenu.path}
                          className="block font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
                          style={{ textDecoration: 'none' }}
                        >
                          {submenu.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="mb-1">
            <Link
              to="/contact"
              className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Contact
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/workflow"
              className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Workflow
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/package"
              className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              Packages
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/whyus"
              className="font-semibold inline-block p-2  rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              WhyUs
            </Link>
          </div>
        </div>
        <div className={`md:flex md:space-x-5 hidden items-center`}>
          <Link to="/" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>Home</Link>
          <div className="relative">
            <button
              onMouseEnter={() => toggleServices('Services')}
              className="flex items-center font-semibold p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
              style={{ textDecoration: 'none' }}
            >
              <span>Services </span>
              <FaChevronDown className="ml-1" />
            </button>
            <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-full left-0 bg-[#f8fafc] p-4`} style={{ textDecoration: 'none' }}>
              {services.map((service, index) => (
                <div key={index} className="mb-2">
                  {service.url ? (
                    <a href={service.url} target="_blank" rel="noopener noreferrer" className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}> 
                      {service.icon && <span className="mr-2">{service.icon}</span>}
                      {service.name}
                    </a>
                  ) : (
                    <Link to={service.submenus ? service.path : '#'} style={{ textDecoration: 'none' }}>
                      <button
                        onMouseEnter={() => toggleServices(service.name)}
                        onClick={() => toggleServices(service.name)}
                        className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
                        style={{ textDecoration: 'none' }}
                      >
                        {service.icon && <span className="mr-2">{service.icon}</span>}
                        {service.name}
                      </button>
                    </Link>
                  )}
                  {service.submenus && servicesState[service.name] && (
                    <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'}  absolute -top-0.5 left-full bg-[#f8fafc] p-4`} style={{ textDecoration: 'none' }}>
                      {service.submenus.map((submenu, subIndex) => (
                        <Link key={subIndex} to={submenu.path} className="block px-4 py-1 text-justify font-semibold hover:bg-fuchsia-800 hover:text-white transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>
                          {submenu.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Link to="/package" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>Packages</Link>
          <Link to="/workflow" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>Workflow</Link>
          <Link to="/whyus" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>WhyUs ?</Link>
          <Link to="/about" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>About</Link>
          <Link to="/contact" className="block font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out" style={{ textDecoration: 'none' }}>Contact</Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;



/*************with Active Route**************/

// import React, { useState, useEffect, useRef } from 'react';
// import { FaBars, FaCamera, FaChevronDown, FaCube, FaTimes, FaVideo } from 'react-icons/fa';
// import { NavLink, Link, useLocation } from 'react-router-dom';
// import logo from '../../images/Logo/Skill.png';

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isSmallHeader, setIsSmallHeader] = useState(false);
//   const [servicesState, setServicesState] = useState({});
//   const [currentTime, setCurrentTime] = useState('');
//   const servicesRef = useRef(null);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const today = new Date();
//       const time =
//         today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
//       setCurrentTime(time);
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setIsSmallHeader(true);
//       } else {
//         setIsSmallHeader(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (servicesRef.current && !servicesRef.current.contains(event.target)) {
//         setServicesState({});
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//     setServicesState({});
//   };



//   const toggleServices = (service) => {
//     setServicesState((prevServicesState) => ({
//       ...prevServicesState,
//       [service]: !prevServicesState[service],
//     }));
//   };

//   const services = [
//     { name: 'All Services', path: '/allservices' },
//     { name: 'Masking', path: '/masking' },
//     { name: 'Retouching', path: '/retouching' },
//     { name: 'Clipping', path: '/clippingPaths' },
//     { name: 'Color', path: '/colorMatch' },
//     { name: 'Invisible', path: '/invisible' },
//     { name: 'Flatlay', path: '/flatlay' },
//     { name: 'Jewellery', path: '/jewellery' },
//     { name: 'Model', path: '/modelRetouching' },
//     { name: 'Shadow', path: '/shadow' },
//     { name: 'Color Adjust', path: '/colorAdjust' },
//     { name: 'Manipulation', path: '/manipulation' },
//     { name: 'Restoration', path: '/restoration' },
//     { name: '360', path: '/threesixty' },
//   ];

//   const otherServices = [
//     {
//       name: 'VFX',
//       icon: <FaVideo />,
//       url: 'http://mvrk.tv/vfx/',
//     },
//     {
//       name: '3D',
//       icon: <FaCube />,
//       url: 'https://auleek.com/',
//     },
//   ];

//   return (
//     <header className={`${isSmallHeader ? 'h-20' : 'h-24'} container mx-auto shadow-lg bg-slate-50 text-black sticky top-0 z-40 backdrop-blur-md backdrop-hue-rotate-30 w-full transition-all duration-300 ease-in-out`}>
//       <nav className="mx-4 flex justify-between items-center h-full">
//         <div className="font-semibold">
//           <Link onClick={() => window.location.href = '/'}>
//             <img
//               src={logo}
//               className={`${isSmallHeader ? 'w-16 transition-all duration-500' : 'w-24 transition-all duration-500'}`}
//               alt="logo"
//             />
//           </Link>
//         </div>
//         <div className="md:hidden">
//           <button onClick={toggleMenu}>
//             {isOpen ? <FaTimes size={25} /> : <FaBars size={25} />}
//           </button>
//         </div>
//         <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} py-5 w-full bg-[#f8fafc] absolute top-20 left-0 z-50`}>
//           <div className="mb-1">
//             <NavLink
//               end
//               to="/"
//               className={({ isActive }) =>
//                 `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//               }
//             >
//               Home
//               {location.pathname === '/' ? (
//                 <span
//                   className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                   aria-hidden="true"
//                 ></span>
//               ) : null}
//             </NavLink>
//           </div>
//           <div>
//             <NavLink
//               to="/about"
//               className={({ isActive }) =>
//                 `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//               }
//             >
//               About
//               {location.pathname === '/about' ? (
//                 <span
//                   className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                   aria-hidden="true"
//                 ></span>
//               ) : null}
//             </NavLink>
//           </div>
//           <div className="flex justify-center">
//             <div
//               onClick={() => toggleServices('Services')}
//               className={`flex items-center font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out ${servicesState['Services'] ? 'text-white bg-[#770099]' : ''}`}
//             >
//               <span>Services</span>
//               <FaChevronDown  className={`w-5 h-5 px-1 mt-1 float-right delay-400 duration-500 transition-all  ${
//                 isExpanded ? 'rotate-180' : ''
//               }`} />
//             </div>
//             <div
//               ref={servicesRef}
//               className={`${
//                 servicesState['Services'] ? 'block' : 'hidden'
//               } absolute top-36 left-1/2 transform -translate-x-1/2 bg-[#f8fafc] p-4`}
//               >
//                 {services.map((service, index) => (
//                   <div key={index} className="mb-2">
//                   <NavLink
//                     to={service.path}
//                     onClick={() => toggleServices(service.name)}
//                     className={({ isActive }) =>
//                       `${isActive ? 'font-semibold md:inline-block p-2 mt-2 md:mt-0 rounded-lg text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//                     }
//                     end
//                   >
//                     {service.name}
//                     {location.pathname === service.path ? (
//                       <span
//                         className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                         aria-hidden="true"
//                       ></span>
//                     ) : null}
//                   </NavLink>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="flex justify-center">
//             <div
//               onClick={() => toggleServices('otherServices')}
//               className={`flex items-center font-semibold md:inline-block p-2  md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out ${servicesState['otherServices'] ? 'text-white bg-[#770099]' : ''}`}
//             >
//               <span>Other</span>
//               <FaChevronDown  className={`w-5 h-5 px-1 mt-1 float-right delay-400 duration-500 transition-all  ${
//                 isExpanded ? 'rotate-180' : ''
//               }`} />
//             </div>
//             <div
//               ref={servicesRef}
//               className={`${
//                 servicesState['otherServices'] ? 'block' : 'hidden'
//               } absolute top-40 left-1/2 transform -translate-x-1/2 bg-[#f8fafc] p-4`}
//             >
//               {otherServices.map((service, index) => (
//                 <div key={index} className="mb-2">
//                   <a
//                     href={service.url}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out"
//                   >
//                     {service.icon && <span className="mr-2 text-[#770099]">{service.icon}</span>}
//                     {service.name}
//                   </a>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="mb-1">
//             <NavLink
//               to="/contact"
//               className={({ isActive }) =>
//                 `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-lg text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//               }
//               end
//             >
//               Contact
//               {location.pathname === '/contact' ? (
//                 <span
//                   className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                   aria-hidden="true"
//                 ></span>
//               ) : null}
//             </NavLink>
//           </div>
//         </div>

//         <div className={`md:flex md:space-x-5 hidden items-center`}>
//           <NavLink
//             to="/"
//             className={({ isActive }) =>
//               `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-md text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//             }
//             end
//           >
//             Home
//             {location.pathname === '/' ? (
//               <span
//                 className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                 aria-hidden="true"
//               ></span>
//             ) : null}
//           </NavLink>
//           <NavLink
//             to="/about"
//             className={({ isActive }) =>
//               `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-md text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//             }
//             end
//           >
//             About
//             {location.pathname === '/about' ? (
//               <span
//                 className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                 aria-hidden="true"
//               ></span>
//             ) : null}
//           </NavLink>
    
//           <div className="relative">
//            <button 
//              onMouseEnter={() => toggleServices('Services')} 
//              className="flex items-center font-semibold p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//            >
//              <span>Services </span>
//              <FaChevronDown  className={`w-5 h-5 px-1 mt-1 float-right delay-400 duration-500 transition-all  ${
//                isExpanded ? 'rotate-180' : ''
//              }`} />
//            </button>
//             <div ref={servicesRef} className={`${servicesState['Services'] ? 'block' : 'hidden'} absolute top-full left-0 p-4 bg-[#f8fafc]`}>
//               {services.map((service, index) => (
//                 <div className="mb-2">
//                   <Link to={service.path}  key={index} className="block px-4 py-1 hover:bg-fuchsia-800 text-justify font-semibold  transition-all duration-300 ease-in-out">
//                     <button
//                       onMouseEnter={() => toggleServices(service.name)}
//                       onClick={() => toggleServices(service.name)}
//                       className="flex items-center  hover:text-white font-semibold  transition-all duration-300 ease-in-out"
//                     >
//                       {service.name}
//                     </button>
//                   </Link>
//                 </div>
//               ))}
//             </div>

//             </div>
//             <div className="relative">
//               <button 
//                 onMouseEnter={() => toggleServices('otherServices')} 
//                 className="flex items-center font-semibold p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-fuchsia-800 transition-all duration-300 ease-in-out"
//               >
//                 <span>Other</span>
//                 <FaChevronDown  className={`w-5 h-5 px-1 mt-1 float-right delay-400 duration-500 transition-all  ${
//                   isExpanded ? 'rotate-180' : ''
//                 }`} />
//               </button>
//               <div ref={servicesRef} className={`${servicesState['otherServices'] ? 'block' : 'hidden'} absolute top-full left-0 p-4 bg-[#f8fafc]`}>
//                 {otherServices.map((service, index) => (
//                   <div key={index} className="mb-2">
//                     <a href={service.url} target="_blank" rel="noopener noreferrer" className="flex items-center font-semibold hover:text-fuchsia-800 transition-all duration-300 ease-in-out">
//                       {service.icon && <span className="mr-2 text-[#770099]">{service.icon}</span>}
//                       {service.name}
//                     </a>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           <NavLink
//             to="/contact"
//             className={({ isActive }) =>
//               `${isActive ? 'font-semibold md:inline-block p-2 mt-4 md:mt-0 rounded-md text-white bg-[#770099] transition-all duration-300 ease-in-out' : 'font-medium md:inline-block p-2 mt-4 md:mt-0 rounded-lg hover:text-white hover:bg-[#770099] transition-all duration-300 ease-in-out'}`
//             }
//             end
//           >
//             Contact
//             {location.pathname === '/contact' ? (
//               <span
//                 className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-[#770099]"
//                 aria-hidden="true"
//               ></span>
//             ) : null}
//           </NavLink>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;
