import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white shadow-md rounded-md text-justify">
      <h1 className="text-4xl text-[#770099] font-bold mb-8 underline text-center">Privacy Policy</h1>

      <section className="mb-8">
        <p className="text-gray-700">
          Your privacy is of utmost importance to Skill Graphics. We appreciate your trust and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and secure your information to ensure transparency and security. We handle your data with the utmost care, utilizing it solely for specified purposes. We value your feedback and continuously strive to treat your data with respect and diligence.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Collection and Use of Personal Information</h2>
        <p className="text-gray-700">
          Skill Graphics collects, processes, and utilizes your personal data for various purposes, including providing services on our website, generating statistics, enhancing our website and services, ensuring website security, sending newsletters, and managing contracts and inquiries. We only use your data when you have explicitly granted consent and have been informed about the specific purposes.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Categories of Personal Data</h2>
        <p className="text-gray-700">
          We collect personal data such as your name, date of birth, email address, postal address, job title, telephone number, fax number, company, products in use, and other information you provide through contact forms, discussion boards, blogs, and online job applications. This information is crucial to providing accurate and personalized services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Email Communications</h2>
        <p className="text-gray-700">
          If you provide us with your email address for communication or promotional purposes, we will use it to correspond with you or send promotional emails based on your interests. You can withdraw your consent at any time through our email subscription center.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Participation in Discussion Boards and Blogs</h2>
        <p className="text-gray-700">
          Skill Graphics offers you the opportunity to participate in discussion boards and blogs. For these purposes, we may require certain personal information (email address, first name, last name, company, country) to uniquely identify users. Please note that content published on discussion boards may be viewed by anyone on the internet.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Records of Processing Activities</h2>
        <p className="text-gray-700">
          An overview of the purposes for which Skill Graphics collects, processes, and uses personal data, as well as the categories of data subjects, is available in the public records of processing activities displayed on this website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. External Links</h2>
        <p className="text-gray-700">
          References or links to external websites are provided for informational purposes only. Skill Graphics does not endorse or assume responsibility for their content. Please review the privacy policies of external sites as they may have different policies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions about data protection and your rights, please feel free to contact us.
        </p>
      </section>

      <section className="mb-8">
        <p className="text-gray-700">
          We appreciate your visit and want to assure you that Skill Graphics will continue to prioritize the protection of your privacy and personal data.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
