import React, { useState, useEffect } from 'react';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import './Clock.css';

const ClockComponent = ({ location, timezone }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const getTimeForTimeZone = (date, timezone) => {
    return new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  };

  const formatTime = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(date);
  };

  const timeForTimeZone = getTimeForTimeZone(time, timezone);

  return (
    <div className="clock-container">
      <Clock
        value={timeForTimeZone}
        size={200}
        renderNumbers={true}
        renderSecondHand={true}
        renderMinuteHand={true}
        renderHourHand={true}
      />
      <div className="digital-clock">
        {formatTime(timeForTimeZone)}
      </div>
      <span className="location">{location}</span>
    </div>
  );
};

export default ClockComponent;
