// import React from 'react';
// import { FaTimes } from 'react-icons/fa';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";

// const ModalForm = ({ isModalOpen, closeModal }) => {
  
//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_n6d9tnw",
//         "template_rdzva4v",
//         e.target,
//         "xLipADfVOmeXFwDgG"
//       )
//       .then(
//         (result) => {
//           swal("Thank you, We have received your Test Images", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   return (
//     <div
//       className={`fixed inset-0 z-10 flex items-center justify-center overflow-y-auto ${
//         isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
//       } transition-opacity ease-in-out duration-300`}
//       aria-labelledby="modal-title"
//       role="dialog"
//       aria-modal="true"
//     >
//       <div className="fixed inset-0 bg-black opacity-50"></div>
//       <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-md">
//         <div className="bg-[#ac02db] p-4 text-white text-center flex justify-between items-center">
//           <h2 className="text-xl text-justify font-bold mb-4">
//             Send Us Your Test Images
//             <br />
//             No Charges for First Submission
//           </h2>
//           <button
//             className="text-white focus:outline-none"
//             onClick={closeModal}
//             aria-label="Close modal"
//           >
//             <FaTimes size={28} className="text-2xl bg-slate-500 rounded-full p-1" />
//           </button>
//         </div>
//         <div className="p-4">
//           <form onSubmit={sendEmail}>
//             <div className="mb-2 text-start">
//               <label htmlFor="name" className="block text-sm font-medium text-gray-700">
//                 Name:
//               </label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
//                 required
//               />
//             </div>

//             <div className="mb-2 text-start">
//               <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//                 Email:
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
//                 required
//               />
//             </div>

//             <div className="mb-2 text-start">
//               <label htmlFor="message" className="block text-sm font-medium text-gray-700">
//                 Instruction:
//               </label>
//               <textarea
//                 id="message"
//                 name="message"
//                 rows="4"
//                 className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
//                 required
//               ></textarea>
//             </div>

//             <div className="mb-2 text-start">
//               <label htmlFor="url" className="block text-sm font-medium text-gray-700">
//                 Image Url Link:
//               </label>
//               <input
//                 type="url"
//                 id="url"
//                 name="url"
//                 className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
//                 required
//               />
//             </div>

//             <div className="mt-2">
//               <button
//                 type="submit"
//                 className="w-full px-4 py-2 text-white bg-[#770099] rounded-md hover:bg-[#632e74] focus:outline-none focus:ring-2 focus:ring-blue-500"
//               >
//                 Send
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ModalForm;





import React from 'react';
import { FaTimes } from 'react-icons/fa';
import swal from 'sweetalert';
import emailjs from "@emailjs/browser";

const ModalForm = ({ isModalOpen, closeModal }) => {

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n6d9tnw",
        "template_rdzva4v",
        e.target,
        "xLipADfVOmeXFwDgG"
      )
      .then(
        (result) => {
          swal("Thank you, We have received your Test Images", "One of us will get back to you shortly", "success");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      className={`fixed inset-0 z-10 mt-20 flex items-center justify-center ${
        isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } transition-opacity ease-in-out duration-300`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-md mx-4">
        <div className="bg-[#ac02db] p-4 text-white text-center flex justify-between items-center">
          <h2 className="text-xl text-justify font-bold text-white mb-2 w-full">
            Send Us Your Test Images
            <br />
            No Charges for First Submission
          </h2>
          <button
            className="text-white focus:outline-none"
            onClick={closeModal}
            aria-label="Close modal"
          >
            <FaTimes size={28} className="text-2xl bg-slate-500 rounded-full p-1" />
          </button>
        </div>
        <div className="p-4">
          <form onSubmit={sendEmail}>
            <div className="mb-2 text-start">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
                required
              />
            </div>

            <div className="mb-2 text-start">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
                required
              />
            </div>

            <div className="mb-2 text-start">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Instruction:
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
                required
              ></textarea>
            </div>

            <div className="mb-2 text-start">
              <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                Image Url Link:
              </label>
              <input
                type="url"
                id="url"
                name="url"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full outline-offset-4 focus:outline-purple-500"
                required
              />
            </div>

            <div className="mt-2">
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-[#770099] rounded-md hover:bg-[#632e74] focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
