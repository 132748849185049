import React from "react";
import ReactCompareImage from "react-compare-image";
import beforeColorMatch1 from "../../../src/images/Color_Change/Before/1.png"
import afterColorMatch1 from "../../../src/images/Color_Change/After/2.png"
import beforeColorMatch2 from "../../../src/images/Color_Change/Before/Jacky-4036-116hi_Before.png"
import afterColorMatch2 from "../../../src/images/Color_Change/After/Jacky-4036-116hi_After.png"
import beforeColorMatch3 from "../../../src/images/Color_Change/Before/imgpsh_fullsize_anim (3) (1).png"
import afterColorMatch3 from "../../../src/images/Color_Change/After/imgpsh_fullsize_anim (2) (1).png"
import beforeColorMatch5 from "../../../src/images/Color_Change/Before/MC Gregeor 1808202212169.png"
import afterColorMatch5 from "../../../src/images/Color_Change/After/MC Gregeor 1808202212169_A.png"
import beforeColorMatch6 from "../../../src/images/Color_Change/Before/SC_SH0501_D178972.png"
import afterColorMatch6 from "../../../src/images/Color_Change/After/SC_SH0501_D178972.png"

const serviceData = [
  {
    beforeImage: beforeColorMatch1,
    afterImage: afterColorMatch1,
  },
  {
    beforeImage: beforeColorMatch2,
    afterImage: afterColorMatch2,
  },
    {
      beforeImage: beforeColorMatch5,
      afterImage: afterColorMatch5,
    },
    {
      beforeImage: beforeColorMatch3,
      afterImage: afterColorMatch3,
    },
    {
      beforeImage: beforeColorMatch6,
      afterImage: afterColorMatch6,
    },
];

const ColorMatch = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Color Match</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Supercharge your product photo editing with the artistry of color matching. Wave goodbye to returns and delight customers with spot-on, vibrant hues that elevate your visuals to a whole new level of awesome!
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColorMatch;
