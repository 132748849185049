// import React from 'react';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';
// import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import 'react-awesome-slider/dist/custom-animations/open-animation.css';
// import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
// import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';

// const AutoplaySlider = withAutoplay(AwesomeSlider);


// const OtherBusinessServices = () => (
//   <div className="py-14 container mx-auto">
//     <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//       Our Other Business Services
//     </h1>
//     {/* <Carousel
//       autoPlay={true}
//       infiniteLoop={true}
//       renderIndicator={false}
//     > */}
//        <AutoplaySlider
//      play={true}
//      cancelOnInteraction={false} 
//      interval={3000}
//      animation="cubeAnimation"
//      // animation="openAnimation"
//      // animation="fallAnimation"
//    >
//       <div>
//         <ThreeDModelling />
//       </div>
//       <div>
//         <DigitalMarketing />
//       </div>
//       <div>
//         <VisualEffects />
//       </div>
//       <div>
//         <MotionGraphics />
//       </div>   
//    </AutoplaySlider>

//     {/* </Carousel> */}
//   </div>
// );

// export default OtherBusinessServices;

// import React from 'react';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

// const AutoplaySlider = withAutoplay(AwesomeSlider);

// const OtherBusinessServices = () => (
//   <div className="py-14 container mx-auto lg:max-w-5xl">
//     <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//       Our Other Business Services
//     </h1>
//       <AutoplaySlider
//         play={true}
//         cancelOnInteraction={false} 
//         interval={3000}
//         animation="openAnimation"
//       >
//         <div>
//           <ThreeDModelling />
//         </div>
//         <div>
//           <DigitalMarketing />
//         </div>
//         <div>
//           <VisualEffects />
//         </div>
//         <div>
//           <MotionGraphics />
//         </div>   
//       </AutoplaySlider>
//     </div>
// );

// export default OtherBusinessServices;

// import React from 'react';
// import Slider from 'react-perfect-slider';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';

// const OtherBusinessServices = () => (
//   <div className="py-14 container mx-auto lg:max-w-5xl">
//     <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//       Our Other Business Services
//     </h1>
//     <Slider
//       renderControls={(next, previous) => (
//         <div>
//           <button onClick={previous}>Previous</button>
//           <button onClick={next}>Next</button>
//         </div>
//       )}
//     >
//       <div>
//         <ThreeDModelling />
//       </div>
//       <div>
//         <DigitalMarketing />
//       </div>
//       <div>
//         <VisualEffects />
//       </div>
//       <div>
//         <MotionGraphics />
//       </div>   
//     </Slider>
//   </div>
// );

// export default OtherBusinessServices;


// import React, { useRef, useEffect } from 'react';
// import Slider from 'react-perfect-slider';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';
// import { Swiper, SwiperSlide } from 'swiper/react';


// const OtherBusinessServices = () => {
//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (sliderRef.current) {
//         sliderRef.current.next();
//       }
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <div className="py-14 container mx-auto lg:max-w-5xl">
//       <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//         Our Other Business Services
//       </h1>
//       <Swiper
//   slidesPerView={1}
//   spaceBetween={30}
//   loop={true}
//   autoplay={{ delay: 3000 }}
//   navigation={{
//     prevEl: '.swiper-button-prev',
//     nextEl: '.swiper-button-next',
//   }}
//   className="mySwiper"
// >
//   <SwiperSlide>
//     <ThreeDModelling />
//   </SwiperSlide>
//   <SwiperSlide>
//     <DigitalMarketing />
//   </SwiperSlide>
//   <SwiperSlide>
//     <VisualEffects />
//   </SwiperSlide>
//   <SwiperSlide>
//     <MotionGraphics />
//   </SwiperSlide>
// </Swiper>

// <div className= " text-3xl swiper-button-prev">
//   <FaArrowLeft />
// </div>
// <div className="swiper-button-next">
//   <FaArrowRight />
// </div>


//     </div>
//   );
// };

// export default OtherBusinessServices;



// import React, { useRef, useEffect } from 'react';
// import Slider from 'react-perfect-slider';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';

// const OtherBusinessServices = () => {
//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (sliderRef.current) {
//         sliderRef.current.next();
//       }
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <div className="py-14 container mx-auto lg:max-w-5xl">
//       <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//         Our Other Business Services
//       </h1>
//       <Slider
//         ref={sliderRef}
//         renderControls={(next, previous) => (
//           <div className="slider-controls">
//             <button onClick={previous} className="slider-button"><FaArrowLeft /></button>
//             <button onClick={next} className="slider-button"><FaArrowRight /></button>
//           </div>
//         )}
//       >
//         <div>
//           <ThreeDModelling />
//         </div>
//         <div>
//           <DigitalMarketing />
//         </div>
//         <div>
//           <VisualEffects />
//         </div>
//         <div>
//           <MotionGraphics />
//         </div>   
//       </Slider>
//     </div>
//   );
// };

// export default OtherBusinessServices;

// import React, { useRef, useEffect } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';

// const OtherBusinessServices = () => {
//   const swiperRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (swiperRef.current && swiperRef.current.swiper) {
//         swiperRef.current.swiper.slideNext();
//       }
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   return (
//     <div className="py-14 container mx-auto lg:max-w-5xl">
//       <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//         Our Other Business Services
//       </h1>
//       <Swiper
//       modules={[Navigation]}
//         loop={true}
//         autoplay={{
//           delay: 3000,
//           disableOnInteraction: true
//         }}
//         spaceBetween={50}
//         navigation={{
//           prevEl: '.swiper-button-prev',
//           nextEl: '.swiper-button-next'
//         }}
//         onSwiper={(swiper) => (swiperRef.current = swiper)}
//       >
//         <SwiperSlide>
//           <ThreeDModelling />
//         </SwiperSlide>
//         <SwiperSlide>
//           <DigitalMarketing />
//         </SwiperSlide>
//         <SwiperSlide>
//           <VisualEffects />
//         </SwiperSlide>
//         <SwiperSlide>
//           <MotionGraphics />
//         </SwiperSlide>
//       <div className="swiper-button-prev"><FaArrowLeft color='#000' /></div>
//       <div className="swiper-button-next"><FaArrowRight color='#000' /></div>
//       </Swiper>
//     </div>
//   );
// };

// export default OtherBusinessServices;


// import React, { useRef, useEffect } from 'react';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// const OtherBusinessServices = () => {
//   const swiperRef = useRef(null);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (swiperRef.current && swiperRef.current.swiper) {
//         swiperRef.current.swiper.slideNext();
//       }
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const slideNext = () => {
//     if (swiperRef.current && swiperRef.current.swiper) {
//       swiperRef.current.swiper.slideNext();
//     }
//   };

//   const slidePrev = () => {
//     if (swiperRef.current && swiperRef.current.swiper) {
//       swiperRef.current.swiper.slidePrev();
//     }
//   };

//   return (
//     <div className="py-14 container mx-auto lg:max-w-5xl">
//       <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//         Our Other Business Services
//       </h1>
//       <div className="swiper-container">
//         <div className="swiper-wrapper">
//           <div className="swiper-slide">
//             <ThreeDModelling />
//           </div>
//           <div className="swiper-slide">
//             <DigitalMarketing />
//           </div>
//           <div className="swiper-slide">
//             <VisualEffects />
//           </div>
//           <div className="swiper-slide">
//             <MotionGraphics />
//           </div>
//         </div>
//         <div className="swiper-button-prev" onClick={slidePrev}><FaArrowLeft color='#000' /></div>
//         <div className="swiper-button-next" onClick={slideNext}><FaArrowRight color='#000' /></div>
//       </div>
//     </div>
//   );
// };

// export default OtherBusinessServices;




// import React from 'react';
// import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
// import DigitalMarketing from '../Home/DigitalMarketing';
// import VisualEffects from '../Home/VisualEffects/VisualEffects';
// import MotionGraphics from '../Home/MotionGraphics';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

// const AutoplaySlider = withAutoplay(AwesomeSlider);

// const OtherBusinessServices = () => (
//   <div className="py-14 container mx-auto lg:max-h-max lg:max-w-5xl">
//     <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
//       Our Other Business Services
//     </h1>
//     <div className="slider-container">
//       <AutoplaySlider
//         play={true}
//         cancelOnInteraction={false} 
//         interval={3000}
//         animation="openAnimation"
//         style={{ width: '100%', height: '500px', paddingTop: '20px' }}
//       >
//         <div>
//           <ThreeDModelling />
//         </div>
//         <div>
//           <DigitalMarketing />
//         </div>
//         <div>
//           <VisualEffects />
//         </div>
//         <div>
//           <MotionGraphics />
//         </div>   
//       </AutoplaySlider>
//     </div>
//   </div>
// );

// export default OtherBusinessServices;


import React from 'react';
import ThreeDModelling from '../Home/ThreeDModelling/ThreeDModelling';
import DigitalMarketing from '../Home/DigitalMarketing';
import VisualEffects from '../Home/VisualEffects/VisualEffects';
import MotionGraphics from '../Home/MotionGraphics';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const OtherBusinessServices = () => (
  <div className="h-screen w-full bg-gray-100"> 
    <div className="flex flex-col justify-center items-center h-full"> 
      <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">
        Our Other Business Services
      </h1>
      <div className="w-full h-full px-4">
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false} 
          interval={3000} 
          style={{ width: '100%', height: '100%' }} 
        >
          <div>
            <ThreeDModelling /> 
          </div>
          <div>
            <DigitalMarketing /> 
          </div>
          <div>
            <VisualEffects /> 
          </div>
          <div>
            <MotionGraphics /> 
          </div>  
        </AutoplaySlider>
      </div>
    </div>
  </div>
);

export default OtherBusinessServices;
