import React from "react";
import ReactCompareImage from "react-compare-image";
import beforeInvisible from "../../../src/images/Invisible/before/VJC11281E.png"
import afterInvisible from "../../../src/images/Invisible/after/VJC11281E_A.png"
import beforeInvisible1 from "../../../src/images/Invisible/before/VJC11281F.png"
import afterInvisible1 from "../../../src/images/Invisible/after/VJC11281F_A.png"
import beforeInvisible2 from "../../../src/images/Invisible/before/3 Main Image.png"
import afterInvisible2 from "../../../src/images/Invisible/after/3 Main Image2.png"
import beforeInvisible4 from "../../../src/images/Invisible/before/_O6A2061-Modifica.png"
import afterInvisible4 from "../../../src/images/Invisible/after/s_A.png"
import beforeInvisible5 from "../../../src/images/Invisible/before/Before_UMK1090K311_1.jpg"
import afterInvisible5 from "../../../src/images/Invisible/after/After_UMK1090K311_1.jpg"

const serviceData = [
  {
    beforeImage: beforeInvisible,
    afterImage: afterInvisible,
  },
  {
    beforeImage: beforeInvisible1,
    afterImage: afterInvisible1,
  },
  {
    beforeImage: beforeInvisible2,
    afterImage: afterInvisible2,
  },
  {
    beforeImage: beforeInvisible4,
    afterImage: afterInvisible4,
  },
  {
    beforeImage: beforeInvisible5,
    afterImage: afterInvisible5,
  },
];

const InvisibleMannequin = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Invisible Mannequin</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Infuse vitality into your product imagery by skillfully eliminating mannequins from your e-commerce photos. Let your products take center stage with a clean and captivating presentation that grabs attention and enhances the shopping experience.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvisibleMannequin;

/*****************************************/

// import React from 'react';
// import {Helmet} from "react-helmet";
// import Services from '../Home/Services';
// import TestimonialOne from '../Home/Testimonials/TestimonialOne';
// import Brandings from '../Home/Brandings';
// import Footer from '../Shared/Footer';
// import TalkOurProduct from '../Home/TalkOurProduct';
// import ServiceQuality from '../Home/ServiceQuality';
// import PackageSection from '../Home/PackageSection/PackageSection';
// import BusinessSummary from '../Home/BusinessSummary/BusinessSummary';
// import HowItWorks from '../Home/HowItWorks/HowItWorks';
// import TestimonialThree from '../Home/Testimonials/TestimonialThree';
// import OtherBusinessServices from '../Masking/OtherBusinessServices';
// import InvisibleMannequinBanner from './InvisibleMannequinBanner';
// import InvisibleMannequinPassionWork from './InvisibleMannequinPassionWork';
// import InvisibleMannequinServices from './InvisibleMannequinServices';
// import InvisibleMannequinQuestion from './InvisibleMannequinQuestion';

// const InvisibleMannequin = () => {
//   return (
//     <div className="container mx-auto">
//     <Helmet>
//      <meta charSet="utf-8" />
//      <meta name="description" content="Experience top-notch invisible ghost mannequin services for your clothing products. Enhance your brand's appeal online and boost your online store sales." />
//      <title>Best Invisible ghost mannequin service for Clothing Products</title>
//    </Helmet>
//  <InvisibleMannequinBanner/>
//  <Brandings/>
//  <InvisibleMannequinPassionWork/>
//  {/* <ClippingPathsHeroSections/> */}
//  <TestimonialOne/>
//  <Services/>
//  <InvisibleMannequinServices/>
//  <OtherBusinessServices/>
//  <TestimonialThree />
//  <HowItWorks/>
//  <BusinessSummary/>
//  <PackageSection/>
//  <InvisibleMannequinQuestion/>
//  <ServiceQuality />
// <TalkOurProduct />
//  <Footer />
//  </div>
//   );
// };

// export default InvisibleMannequin;
