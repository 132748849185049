import React from 'react';
import ImageAi from '../../../images/Home/imgpsh_fullsize_anim (1) (1).png';
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';

const ImageEditingAi = () => {
  return (
    <section className=" px-4 py-4 container mx-auto bg-white ">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-2/4 py-12 md:mb-0">
          <img
            src={ImageAi}
            alt="Editorial Retouching"
            className="max-w-full rounded-lg hover:scale-105 transform transition-transform duration-300"
          />
        </div>
        <div className="text-justify md:w-2/4 lg:ml-6">
        <h1 className="text-3xl font-bold md:text-5xl text-black leading-tight">
          AI-Powered Image <br/> Editing Revolution
        </h1>
        <p className="py-4 text-base md:text-lg text-[#303134] max-w-md md:max-w-full">
            Our team of seasoned experts combines artistic flair with technical expertise to deliver exceptional post-production services tailored to your specific needs. Whether it's retouching portraits, enhancing product images, or perfecting your marketing visuals, our AI-powered solutions are designed to elevate your content to new heights.
          </p>
          <Link to="/imageEditingAiExample">
          
            <button className="bg-[#770099] hover:bg-[#950cba] text-white px-6 py-3 rounded flex items-center focus:outline-none focus:ring focus:border-purple-300">
              Explore More
              <FaArrowCircleRight className="ml-2" />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ImageEditingAi;
