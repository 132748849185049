import React from 'react';
import motion from '../../images/Animation/motion-graphics.gif';
import { Fa500Px, FaArrowRight, FaDailymotion, FaParagraph } from 'react-icons/fa';

const MotionGraphics = () => {
  return (
    <section className="container mx-auto py-12 px-4 md:px-14 bg-white">
      <div className="flex flex-col md:flex-row items-center">
       <div className="md:w-2/4 mb-6 md:mb-0">
          <img
            src={motion}
            alt="Scale With Confidence"
            className="max-w-full rounded-lg hover:scale-105 transform transition-transform duration-300"
          />
        </div>
        <div className="text-justify md:w-2/4 md:ml-6">
          <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight">
            
            Motion Graphics
          </h1>
          <p className="py-4 text-base md:text-lg text-[#646a76] max-w-md md:max-w-full">
            Elevate your projects with Skill Graphics' transformative motion graphics. 
            Our distinctive approach is crafted to perfectly align with your creative vision, 
            delivering a revolutionized experience in visual storytelling.
          </p>
            <a
          href="https://justanx.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link MVRK"
        >
            <button className="bg-[#770099] hover:bg-[#950cba] text-white px-6 py-3 rounded flex items-center focus:outline-none focus:ring focus:border-purple-300">
              Explore More
              <FaArrowRight className="ml-2" />
            </button>
            </a>
       
        </div>
      </div>
    </section>
  );
};

export default MotionGraphics