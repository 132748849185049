import React from "react";
import ReactCompareImage from "react-compare-image";
import ShadowsAfter1 from "../../../src/images/shadow/after/After_IMG-000065.jpg"
import ShadowsBefore1 from "../../../src/images/shadow/before/Before_IMG-000065.jpg"
import ShadowsAfter2 from "../../../src/images/shadow/after/After_DSC_5036.jpg"
import ShadowsBefore2 from "../../../src/images/shadow/before/Before_DSC_5036.jpg"
import ShadowsAfter3 from "../../../src/images/shadow/after/104046_54517_1 (1).png"
import ShadowsBefore3 from "../../../src/images/shadow/before/104046_54517_1.png"
import ShadowsAfter4 from "../../../src/images/shadow/after/22SHO003_A.png"
import ShadowsBefore4 from "../../../src/images/shadow/before/22SHO003.png"
import ShadowsAfter5 from "../../../src/images/shadow/after/After_BN22W002-A1.jpg"
import ShadowsBefore5 from "../../../src/images/shadow/before/_Before_BN22W002-A1.jpg"

const serviceData = [
  {
    beforeImage: ShadowsBefore3,
    afterImage: ShadowsAfter3,
  },
  {
    beforeImage: ShadowsBefore4,
    afterImage: ShadowsAfter4,
  },
  {
    beforeImage: ShadowsBefore5,
    afterImage: ShadowsAfter5,
  },
  {
    beforeImage: ShadowsBefore1,
    afterImage: ShadowsAfter1,
  },
  {
    beforeImage: ShadowsBefore2,
    afterImage: ShadowsAfter2,
  },
];

const Shadows = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Shadow Removal</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Infuse your product images with depth, perspective, and a touch of professionalism by artfully integrating natural, reflection, or drop shadows. Elevate your visual storytelling with shadows that bring a cohesive and polished feel, giving your products that extra touch of sophistication.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shadows;
