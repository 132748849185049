// import PhotoAlbum from "react-photo-album";
// import photos from "./Photos";

// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";


// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Zoom from "yet-another-react-lightbox/plugins/zoom";
// import "yet-another-react-lightbox/plugins/thumbnails.css";
// import { useState } from "react";

// export default function PhotoGallery() {
//     const [index, setIndex] = useState(-1);
//     return (
//         <div className="py-12 container mx-auto  bg-gradient-to-r from-[#f10aa8] to-[#7e26a1]">
// <h1 className="text-3xl font-bold  md:text-5xl mb-8 text-white text-center">Our Photo Gallery</h1>
// <PhotoAlbum photos={photos}  onClick={({ index }) => setIndex(index)} layout="rows"/>
// <Lightbox
//                 slides={photos}
//                 open={index >= 0}
//                 index={index}
//                 close={() => setIndex(-1)}
//                 // enable optional lightbox plugins
//                 plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
//                 />
// </div>
//     ) 
// }

//layout="columns"
//layout="masonry"
//layout="rows" 
/************************************************/


// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
// import officephoto from "../../../images/IMG_1254-scaled.jpg";
// import officephoto2 from "../../../images/IMG_1256-scaled.jpg";
// import officephoto3 from "../../../images/IMG_1257-scaled.jpg";
// import annualcoxbazar from "../../../images/annual coxbazar.jpg";
// import food_fastive from "../../../images/food_fastive.jpg";
// import food_fastive2 from "../../../images/food_fastive2.jpg";
// import yearsdone from "../../../images/yearsdone.jpg";
// import tourCoxbazar from "../../../images/tourcoxbazar.jpeg"
// import coxbazar from "../../../images/coxbazar.jpg"
// import coxbazartour from "../../../images/coxbazar-tour.jpg"
// import coxbazarSeaBeach from "../../../images/coxbazarSeaBeach.jpg"
// import enjoytour from "../../../images/enjoytour.jpg"
// import celebration from "../../../images/celebration.jpeg"
// // import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
// // import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
// import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import 'react-awesome-slider/dist/custom-animations/open-animation.css';
// import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
// import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';

// const photos = [
//     {
//       src: yearsdone,
//       width: 1600,
//       height: 900,
   
//     },
//     {
//       src: food_fastive,
//       width: 1600,
//       height: 900,

//     },
//     {
//       src: food_fastive2,
//       width: 1600,
//       height: 900,
  
//     },
//     {
//       src: annualcoxbazar,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//         src: tourCoxbazar,
//         width: 800,
//         height: 600,
       
//       },
//     {
//       src: officephoto3,
//       width: 1600,
//       height: 900,
    
//     },
//     {
//       src: officephoto2,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: officephoto,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: coxbazar,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: coxbazartour,
//       width: 1600,
//       height: 900,
     
//     },
//     {
//       src: coxbazarSeaBeach,
//       width: 1600,
//       height: 900,
     
//     },
//     {
//       src: celebration,
//       width: 1600,
//       height: 900,
     
//     },
//   ];


//  export default function PhotoGallery() {

    

//     return (
//         <div className="py-12 container mx-auto max-w-3xl">
//  <h1 className="text-3xl font-bold md:text-5xl mb-8 text-[#770099] text-center">Our Photo Gallery</h1>
//   <AwesomeSlider className='container mx-auto ' animation="fallAnimation">

//   {photos.map((photo, idx) => (
//         <div
        
//         data-src={photo.src} 
//                         key={idx}
//                         // src={photo.src}
//                         alt={photo.alt}
//                         className="cursor-pointer w-auto"
//                         />
//                 ))}
//   </AwesomeSlider>
// </div>

// // {/* <div className="py-16 container mx-auto bg-[#770099]">
// //       <h1 className="text-3xl font-bold md:text-5xl mb-8 text-white text-center">Our Photo Gallery</h1>
// //       <AwesomeSlider className='container mx-auto custom-slider' animation="openAnimation">
// //         {photos.map((photo, idx) => (
// //           <div
// //             data-src={photo.src}
// //             key={idx}
// //             className="cursor-pointer w-auto"
// //           />
// //         ))}
// //       </AwesomeSlider>
// //     </div> */}

//     )
//  }


////***********************************************///


import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import yearsdone from "../../../images/Gallery/yearsdone.jpg";
import tourcoxbazar from "../../../images/Gallery/tourcoxbazar.jpeg";
import food_fastive2 from "../../../images/Gallery/food_fastive2.jpg";
import food_fastive from "../../../images/Gallery/food_fastive.jpg";
import coxbazarSeaBeach from "../../../images/Gallery/coxbazarSeaBeach.jpg";
import coxbazarTour from "../../../images/Gallery/coxbazar-tour.jpg";
import celebration from "../../../images/Gallery/celebration.jpeg";
import annualCoxbazar from "../../../images/Gallery/annual coxbazar.jpg";
import Studio1 from "../../../images/Gallery/Studio-1.jpeg";
import Studio2 from "../../../images/Gallery/Studio-2.jpeg";
import Studio3 from "../../../images/Gallery/Studio-3.jpeg";
import Studio4 from "../../../images/Gallery/Studio-4.jpeg";
import Studio5 from "../../../images/Gallery/Studio-5.jpeg";
import Studio6 from "../../../images/Gallery/Studio-6.jpeg";
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
const photos = [
    {
      src: yearsdone,
      width: 1600,
      height: 900,
   
    },
    {
      src: food_fastive,
      width: 1600,
      height: 900,

    },
    {
      src: food_fastive2,
      width: 1600,
      height: 900,
  
    },
    {
      src: tourcoxbazar,
      width: 1600,
      height: 900,
      
    },
    {
        src: coxbazarTour,
        width: 800,
        height: 600,
       
      },
    {
      src: annualCoxbazar,
      width: 1600,
      height: 900,
    
    },
    {
      src: coxbazarSeaBeach,
      width: 1600,
      height: 900,
    
    },
    {
      src: celebration,
      width: 1600,
      height: 900,
    
    },
    {
      src: Studio1,
      width: 1600,
      height: 900,
      
    },
    {
      src: Studio2,
      width: 1600,
      height: 900,
      
    },
    {
      src: Studio3,
      width: 1600,
      height: 900,
      
    },
    {
      src: Studio4,
      width: 1600,
      height: 900,
     
    },
    {
      src: Studio5,
      width: 1600,
      height: 900,
     
    },
    {
      src: Studio6,
      width: 1600,
      height: 900,
     
    },
  ];

  const AutoplaySlider = withAutoplay(AwesomeSlider);


 export default function PhotoGallery() {

    return (
  <div className="py-14 container mx-auto lg:max-w-3xl">
  <h1 className="text-3xl font-bold md:text-5xl mb-8 text-white text-center">Our Photo Gallery</h1>
  {/* <div className='md:container md:mx-auto'> */}
   <AutoplaySlider
     play={true}
     cancelOnInteraction={false} 
     interval={3000}
     animation="cubeAnimation"
     // animation="openAnimation"
     // animation="fallAnimation"
   >
  {photos.map((photo, idx) => (
        <div
        data-src={photo.src} 
        key={idx}
        alt={photo.alt}
        className="cursor-pointer w-auto"
        />
        ))}
  </AutoplaySlider>
  </div>
// </div>
    )
 }


// import React from 'react';
// import AwesomeSlider from 'react-awesome-slider';
// // import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
// // import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
// import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';

// // Import images
// import officephoto from "../../../images/IMG_1254-scaled.jpg";
// import officephoto2 from "../../../images/IMG_1256-scaled.jpg";
// import officephoto3 from "../../../images/IMG_1257-scaled.jpg";
// import annualcoxbazar from "../../../images/annual coxbazar.jpg";
// import food_fastive from "../../../images/food_fastive.jpg";
// import food_fastive2 from "../../../images/food_fastive2.jpg";
// import yearsdone from "../../../images/yearsdone.jpg";
// import tourCoxbazar from "../../../images/tourcoxbazar.jpeg"
// import coxbazar from "../../../images/coxbazar.jpg"
// import coxbazartour from "../../../images/coxbazar-tour.jpg"
// import coxbazarSeaBeach from "../../../images/coxbazarSeaBeach.jpg"
// import enjoytour from "../../../images/enjoytour.jpg"
// import celebration from "../../../images/celebration.jpeg"


// const photos = [
//     {
//       src: yearsdone,
//       width: 1600,
//       height: 900,
   
//     },
//     {
//       src: food_fastive,
//       width: 1600,
//       height: 900,

//     },
//     {
//       src: food_fastive2,
//       width: 1600,
//       height: 900,
  
//     },
//     {
//       src: annualcoxbazar,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//         src: tourCoxbazar,
//         width: 800,
//         height: 600,
       
//       },
//     {
//       src: officephoto3,
//       width: 1600,
//       height: 900,
    
//     },
//     {
//       src: officephoto2,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: officephoto,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: coxbazar,
//       width: 1600,
//       height: 900,
      
//     },
//     {
//       src: coxbazartour,
//       width: 1600,
//       height: 900,
     
//     },
//     {
//       src: coxbazarSeaBeach,
//       width: 1600,
//       height: 900,
     
//     },
//     {
//       src: celebration,
//       width: 1600,
//       height: 900,
     
//     },
//   ];

// const PhotoSlide = ({ src, alt, width, height }) => (
//   <div
//     data-src={src}
//     key={src}
//     alt={alt}
//     style={{ width, height }}
//     className="custom-slide-class cursor-pointer"
//   />
// );

// const PhotoGallery = () => (
//   <div className="py-16 container mx-auto bg-[#770099]">
//     <h1 className="text-3xl font-bold md:text-5xl mb-8 text-white text-center">Our Photo Gallery</h1>
//     <AwesomeSlider animation="foldOutAnimation" cssModule={StyleSheet}>
//       {photos.map((photo) => (
//         <PhotoSlide key={photo.src} {...photo} />
//       ))}
//     </AwesomeSlider>
//   </div>
// );

// export default PhotoGallery;

