// import React, { useEffect, useState } from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import Clock from '../Clock/Clock';


// const TalkOurProduct = () => {

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_4yy12ac",
//         "template_9rm3pr9",
//         e.target,
//         "2rnf4az-znx3DDJz4"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };
  
//     const [visitorTimeZone, setVisitorTimeZone] = useState("");

//     const getVisitorCountry = async () => {
//       try {
//         // const response = await fetch('https://ipapi.co/json/');
//         const response = await fetch('https://ipinfo.io/json');
//         const data = await response.json();
//         setVisitorTimeZone(data);
//       } catch (error) {
//         console.error('Error fetching user location:', error);
//       }
//     };
    
//   useEffect(() => {

//     getVisitorCountry();
//   }, []);

//   console.log(visitorTimeZone?.timezone, visitorTimeZone?.country_name)

//   return (
//     <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
//       <div className="container mx-auto flex flex-col md:flex-row items-center">
//       <div className="md:w-1/2 flex justify-center md:justify-start mb-5 md:mb-0">

//           <Clock timezone="Asia/Dhaka" location="DHAKA" />
        
//         {
//           visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
//             <>
//               <Clock timezone="Europe/Berlin" location="CET" />
//             </>
//           ) : (
//             <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
//           )
//         }
//       </div>
//         <div className="md:w-2/4  py-10 md:mb-0">
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//               <div className="p-4 text-justify rounded-lg">
//                 <p className="text-xl font-semibold mb-2">
//                   <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//                   Manhattan House, Singapore
//                 </p>
//                 <div className='ml-8'>
//                 <p>151 CHIN SWEE ROAD, #02-24</p>
//                 <p>169876, Singapore</p>
//                 </div>
//               </div>
              
//               <div className="p-4 rounded-lg text-justify">
//                 <p className="text-xl font-semibold mb-2">
//                   <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//                   Dubai, UAE
//                 </p>
//                 <div className='ml-8'>
//                 <p>Business Centre, Sharjah Publishing City Free Zone, Sharjah</p>
//                 </div>
//               </div>

//               <div className="p-4 text-justify rounded-lg ">
//                 <p className="text-xl font-semibold mb-2">
//                   <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
//                  Dhaka, Bangladesh
//                 </p>
//                 <div className='ml-8'>
//                 <p>Mohakhali DOHS</p>
//                 <p>Road #06, House #112</p>
//                 </div>
//               </div>
//               <div className="p-4 text-justify rounded-lg ">
//                 <p className="text-xl font-semibold mb-2">
//                   <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//                   Natore, Bangladesh
//                 </p>
//                 <div className='ml-8'>
//                 <p>Natore Sadar, Natore</p>
//                 <p>Chaul Patty, kanaikhali</p>
//                 </div>
//               </div>
//               <div className="p-4 text-justify rounded-lg ">
//                 <p className="text-xl font-semibold mb-2">
//                   <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//                   2248 Broadway, United States
//                 </p>
//                 <div className='ml-8'>
//                 <p>#1619, New York, NY 10024</p>
//                 </div>
//               </div>
//           </div>
//         </div>
//       </div>
//     </section>
// );
// };

// export default TalkOurProduct;



import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import swal from 'sweetalert';
import emailjs from "@emailjs/browser";
import Clock from '../Clock/Clock';
import './TalkOurProduct.css';

const TalkOurProduct = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4yy12ac",
        "template_9rm3pr9",
        e.target,
        "2rnf4az-znx3DDJz4"
      )
      .then(
        (result) => {
          swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
          e.target.reset();
        },
        (error) => {
          console.error(error.text);
        }
      );
  };

  const [visitorTimeZone, setVisitorTimeZone] = useState("");

  const getVisitorCountry = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      const data = await response.json();
      setVisitorTimeZone(data);
    } catch (error) {
      console.error('Error fetching user location:', error);
    }
  };

  useEffect(() => {
    getVisitorCountry();
  }, []);

  return (
    <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="clocks md:w-1/2 flex justify-between md:justify-start mb-5 md:mb-0">
          <Clock timezone="Asia/Dhaka" location="DHAKA" />
          {visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
            <Clock timezone="Europe/Berlin" location="CET" />
          ) : (
            <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
          )}
        </div>
          <div className="md:w-2/4 py-10 md:mb-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div className="grid grid-cols-1 gap-2">
      <h1 className="text-2xl office-name text-justify ml-10 mb-0 font-bold">Corporate Office</h1>
            <div className="p-4 text-justify rounded-lg">
              <p className="text-xl font-semibold mb-2 ">
                <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
                Dubai
              </p>
              <div className="ml-8">
                <p>Business Centre, Sharjah <br/> Publishing City Free Zone, <br/> Sharjah, UAE </p>   
              </div>
            </div>
            </div>

            <div className="col-span-2 grid md:grid-cols-2">
            <h1 className="text-2xl text-justify ml-10  font-bold mb-0">Regional Offices</h1> <br/>

            <div className="p-4 text-justify rounded-lg">
              <p className="text-xl font-semibold mb-2">
                <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
                Singapore
              </p>
              <div className="mb-0 text-base ml-8">
                <p>151 CHIN SWEE ROAD, #02-24</p>
                <p>Manhattan House, 169876</p>
              </div>
            </div>

            <div className="p-4 text-justify rounded-lg">
              <p className="text-xl font-semibold mb-2">
                <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
                United States
              </p>
              <div className="mb-0 ml-8">
                <p>2248 Broadway,, #1619</p>
                <p> NY 10024, New York</p>
              </div>
            </div>
            <h1 className="text-2xl text-justify ml-10  font-bold mb-0">Back Offices</h1> <br/>

            <div className="p-4 text-justify rounded-lg">
              <p className="text-xl font-semibold mb-2">
                <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
                Bangladesh
              </p>
              <div className='ml-8'>
                <p>Road #06, House #112</p>
                <p>Mohakhali DOHS, Dhaka</p>
              </div>
            </div>

            <div className="p-4 text-justify rounded-lg ">
              <p className="text-xl font-semibold mb-2">
                <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
                Bangladesh
              </p>
              <div className='ml-8'>
                <p>Natore Sadar, Chaul Patty</p>
                <p>Kanaikhali, Natore</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TalkOurProduct;




// import React, { useEffect, useState } from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import swal from 'sweetalert';
// import emailjs from '@emailjs/browser';
// import Clock from '../Clock/Clock';
// import './TalkOurProduct.css';

// const TalkOurProduct = () => {
//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         'service_4yy12ac',
//         'template_9rm3pr9',
//         e.target,
//         '2rnf4az-znx3DDJz4'
//       )
//       .then(
//         (result) => {
//           swal('Thank you for reaching out', 'One of us will get back to you shortly', 'success');
//           e.target.reset();
//         },
//         (error) => {
//           console.error(error.text);
//         }
//       );
//   };

//   const [visitorTimeZone, setVisitorTimeZone] = useState('');

//   const getVisitorCountry = async () => {
//     try {
//       const response = await fetch('https://ipinfo.io/json');
//       const data = await response.json();
//       setVisitorTimeZone(data);
//     } catch (error) {
//       console.error('Error fetching user location:', error);
//     }
//   };

//   useEffect(() => {
//     getVisitorCountry();
//   }, []);

//   return (
//     <section className="talk-our-product-section bg-[#7D2098] text-white px-4 py-10 container mx-auto">
//       <div className="container mx-auto flex flex-col md:flex-row items-start md:items-center">
        // <div className="clocks md:w-1/2 flex justify-between md:justify-start mb-5 md:mb-0">
        //   <Clock timezone="Asia/Dhaka" location="DHAKA" />
        //   {visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
        //     <Clock timezone="Europe/Berlin" location="CET" />
        //   ) : (
        //     <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
        //   )}
        // </div>
//         <div className="office-info md:w-1/2">
//           <div className="office-section mb-8">
//             <h2 className="section-title">Corporate Office</h2>
//             <div className="office-detail">
//               <p className="office-location">
//                 <FaMapMarkerAlt color="#EA4335" className="inline-block mr-2" />
//                 Dubai
//               </p>
//               <p className="office-address">Business Centre, Sharjah Publishing City Free Zone, Sharjah, UAE</p>
//             </div>
//           </div>
//           <div className="office-section mb-8">
//             <h2 className="section-title">Regional Offices</h2>
//             <div className="office-row">
//               <div className="office-detail">
//                 <p className="office-location">
//                   <FaMapMarkerAlt color="#EA4335" className="inline-block mr-2" />
//                   Singapore
//                 </p>
//                 <p className="office-address">151 CHIN SWEE ROAD, #02-24, Manhattan House, 169876, Singapore</p>
//               </div>
//               <div className="office-detail">
//                 <p className="office-location">
//                   <FaMapMarkerAlt color="#EA4335" className="inline-block mr-2" />
//                   United States
//                 </p>
//                 <p className="office-address">2248 Broadway, #1619, NY 10024, New York</p>
//               </div>
//             </div>
//           </div>
//           <div className="office-section">
//             <h2 className="section-title">Back Offices</h2>
//             <div className="office-row">
//               <div className="office-detail">
//                 <p className="office-location">
//                   <FaMapMarkerAlt color="#EA4335" className="inline-block mr-2" />
//                   Bangladesh
//                 </p>
//                 <p className="office-address">Road #06, House #112, Mohakhali DOHS, Dhaka</p>
//               </div>
//               <div className="office-detail">
//                 <p className="office-location">
//                   <FaMapMarkerAlt color="#EA4335" className="inline-block mr-2" />
//                   Bangladesh
//                 </p>
//                 <p className="office-address">Natore Sadar, Chaul Patty, Kanaikhali, Natore</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TalkOurProduct;



// import React, { useEffect, useState } from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import Clock from '../Clock/Clock';
// import './AnimatedBorder.css'; // Import custom CSS for animations

// const TalkOurProduct = () => {
//   const [visitorTimeZone, setVisitorTimeZone] = useState("");

//   const getVisitorCountry = async () => {
//     try {
//       const response = await fetch('https://ipinfo.io/json');
//       const data = await response.json();
//       setVisitorTimeZone(data);
//     } catch (error) {
//       console.error('Error fetching user location:', error);
//     }
//   };

//   useEffect(() => {
//     getVisitorCountry();
//   }, []);

//   return (
//     <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
//       <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
//         {/* Corporate Office */}
//         <div className="animated-border flex flex-col items-center">
//           <Clock timezone="Asia/Dhaka" location="Dhaka" />
//           <div className="w-3/4 p-4 text-justify rounded-lg">
//             <p className="text-xl font-semibold mb-2">
//               <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
//               Corporate Office: Dubai
//             </p>
//             <div className="ml-8">
//               <p>Business Centre, Sharjah Publishing City Free Zone, Sharjah, UAE</p>
//             </div>
//           </div>
//         </div>

//         {/* Regional Offices */}
//         <div className="animated-border flex flex-col items-center">
//           <div className="w-3/4 p-4 text-justify rounded-lg">
//             <p className="text-xl font-semibold mb-2">Singapore</p>
//             <div className="ml-8">
//               <p>151 CHIN SWEE ROAD, #02-24</p>
//               <p>Manhattan House, 169876, Singapore</p>
//             </div>
//           </div>

//           <div className="w-3/4 p-4 text-justify rounded-lg mt-6">
//             <p className="text-xl font-semibold mb-2">United States</p>
//             <div className="ml-8">
//               <p>2248 Broadway, #1619</p>
//               <p>NY 10024, New York</p>
//             </div>
//           </div>
//         </div>

//         {/* Back Offices */}
//         <div className="animated-border flex flex-col items-center">
//           <div className="w-3/4 p-4 text-justify rounded-lg">
//             <p className="text-xl font-semibold mb-2">Bangladesh</p>
//             <div className="ml-8">
//               <p>Road #06, House #112</p>
//               <p>Mohakhali DOHS, Dhaka</p>
//             </div>
//           </div>

//           <div className="w-3/4 p-4 text-justify rounded-lg mt-6">
//             <p className="text-xl font-semibold mb-2">Bangladesh</p>
//             <div className="ml-8">
//               <p>Natore Sadar, Chaul Patty</p>
//               <p>Kanaikhali, Natore</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TalkOurProduct;



// import React, { useEffect, useState } from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import Clock from '../Clock/Clock';

// const TalkOurProduct = () => {
//   const [visitorTimeZone, setVisitorTimeZone] = useState("");

//   const getVisitorCountry = async () => {
//     try {
//       const response = await fetch('https://ipinfo.io/json');
//       const data = await response.json();
//       setVisitorTimeZone(data);
//     } catch (error) {
//       console.error('Error fetching user location:', error);
//     }
//   };

//   useEffect(() => {
//     getVisitorCountry();
//   }, []);

//   return (
//     <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
//       <div className="container mx-auto flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 flex justify-center md:justify-start mb-5 md:mb-0">
//           <Clock timezone="Asia/Dhaka" location="Dhaka" />
//           {visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
//             <Clock timezone="Europe/Berlin" location="CET" />
//           ) : (
//             <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
//           )}
//         </div>
//       </div>

//       {/* Corporate Office */}
//       <h2 className="text-2xl font-bold mb-4">Corporate Office</h2>
//       <div className="p-4 text-justify rounded-lg">
//         <p className="text-xl font-semibold mb-2">
//           Dubai
//         </p>
//         <div className="ml-8">
//           <p>Business Centre, Sharjah Publishing City Free Zone, Sharjah, UAE</p>
//         </div>
//       </div>

//       {/* Regional Offices */}
//       <h2 className="text-2xl font-bold mb-4">Regional Offices</h2>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         <div className="p-4 text-justify rounded-lg">
//           <p className="text-xl font-semibold mb-2">Singapore</p>
//           <div className="ml-8">
//             <p>151 CHIN SWEE ROAD, #02-24</p>
//             <p>Manhattan House, 169876, Singapore</p>
//           </div>
//         </div>

//         <div className="p-4 text-justify rounded-lg">
//           <p className="text-xl font-semibold mb-2">United States</p>
//           <div className="ml-8">
//             <p>2248 Broadway, #1619</p>
//             <p>NY 10024, New York</p>
//           </div>
//         </div>
//       </div>

//       {/* Back Offices */}
//       <h2 className="text-2xl font-bold mb-4">Back Offices</h2>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         <div className="p-4 text-justify rounded-lg">
//           <p className="text-xl font-semibold mb-2">Bangladesh</p>
//           <div className="ml-8">
//             <p>Road #06, House #112</p>
//             <p>Mohakhali DOHS, Dhaka</p>
//           </div>
//         </div>

//         <div className="p-4 text-justify rounded-lg">
//           <p className="text-xl font-semibold mb-2">Bangladesh</p>
//           <div className="ml-8">
//             <p>Natore Sadar, Chaul Patty</p>
//             <p>Kanaikhali, Natore</p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TalkOurProduct;


/**********************************/

// {/* <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
// <div className="container mx-auto flex flex-col md:flex-row items-center">
// <div className="md:w-2/4 md:mr-6 text-justify lg:ml-16">
//         <p className="text-2xl font-semibold mb-4">Connect with Our Team</p>
//         <p className="mb-4">
//         Schedule a brief conversation with one of our product experts to address your image and post-production needs.
//         </p>
//         <div className="flex justify-start">
//         <form  onSubmit={sendEmail}>
//           <input
//             type="email"
//             placeholder="Your Email"
//             className="px-4 py-2 text-black border border-gray-300 rounded-l focus:outline-none focus:ring focus:border-blue-300"
//             required
//           />
//           <button type='submit' className="bg-[#770099] hover:bg-[#631976]  text-white px-4 py-2 rounded-r focus:outline-none focus:ring focus:border-purple-300">
//             Get Started
//           </button>
//           </form>
//         </div>
//   </div>

//   <div className="md:w-2/4 py-10 md:mb-0">
//   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//         <div className="p-4 text-justify rounded-lg">
//           <p className="text-xl font-semibold mb-2">
//             <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//             Manhattan House, Singapore
//           </p>
//           <div className='ml-8'>
//           <p>151 CHIN SWEE ROAD, #02-24</p>
//           <p>169876, Singapore</p>
//           </div>
//         </div>
        
//         <div className="p-4 rounded-lg text-justify">
//           <p className="text-xl font-semibold mb-2">
//             <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//             Dubai, UAE
//           </p>
//           <div className='ml-8'>
//           <p>Business Centre, Sharjah Publishing City Free Zone, Sharjah</p>
//           </div>
//         </div>

//         <div className="p-4 text-justify rounded-lg ">
//           <p className="text-xl font-semibold mb-2">
//             <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
//            Dhaka, Bangladesh
//           </p>
//           <div className='ml-8'>
//           <p>Mohakhali DOHS</p>
//           <p>Road #06, House #112</p>
//           </div>
//         </div>
//         <div className="p-4 text-justify rounded-lg ">
//           <p className="text-xl font-semibold mb-2">
//             <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//             Natore, Bangladesh
//           </p>
//           <div className='ml-8'>
//           <p>Natore Sadar, Natore</p>
//           <p>Chaul Patty, kanaikhali</p>
//           </div>
//         </div>
//     </div>
//   </div>
// </div>

// <div className="hidden md:block">
//   <div className="flex justify-center pb-5">
//   <Clock timezone="Asia/Dhaka" location="DHAKA" />

// {
//   visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
//     <>     
//       <Clock timezone="Europe/Berlin" location="CET" />
//     </>
//   ) : (
//     <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
//   )
// }
//   </div>
// </div>
// </section> */}

//    <section className="px-4 py-10 container mx-auto bg-[#7D2098] text-white">
//  <div className="container mx-auto flex flex-col md:flex-row items-center">
//  <div className="md:w-2/4 md:mr-6 text-justify lg:ml-16">
//          <p className="text-2xl font-semibold mb-4">Connect with Our Team</p>
//          <p className="mb-4">
//          Schedule a brief conversation with one of our product experts to address your image and post-production needs.
//          </p>
//          <div className="flex justify-start">
//          <form  onSubmit={sendEmail}>
//            <input
//              type="email"
//              placeholder="Your Email"
//              className="px-4 py-2 text-black border border-gray-300 rounded-l focus:outline-none focus:ring focus:border-blue-300"
//              required
//            />
//            <button type='submit' className="bg-[#770099] hover:bg-[#631976]  text-white px-4 py-2 rounded-r focus:outline-none focus:ring focus:border-purple-300">
//              Get Started
//            </button>
//            </form>
//          </div>
//    </div>
//    <div className="md:w-2/4 py-10 md:mb-0">
//    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
//          <div className="p-4 text-justify rounded-lg">
//            <p className="text-xl font-semibold mb-2">
//              <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//              Manhattan House, Singapore
//            </p>
//            <div className='ml-8'>
//            <p>151 CHIN SWEE ROAD, #02-24</p>
//            <p>169876, Singapore</p>
//            </div>
//          </div>      
//          <div className="p-4 rounded-lg text-justify">
//            <p className="text-xl font-semibold mb-2">
//              <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//              Dubai, UAE
//            </p>
//            <div className='ml-8'>
//            <p>Business Centre, Sharjah Publishing City Free Zone, Sharjah</p>
//            </div>
//          </div>
//          <div className="p-4 text-justify rounded-lg ">
//            <p className="text-xl font-semibold mb-2">
//              <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" />
//             Dhaka, Bangladesh
//            </p>
//            <div className='ml-8'>
//            <p>Mohakhali DOHS</p>
//            <p>Road #06, House #112</p>
//            </div>
//          </div>
//          <div className="p-4 text-justify rounded-lg ">
//            <p className="text-xl font-semibold mb-2">
//              <FaMapMarkerAlt color='#EA4335' className="inline-block mr-2" /> 
//              Natore, Bangladesh
//            </p>
//            <div className='ml-8'>
//            <p>Natore Sadar, Natore</p>
//            <p>Chaul Patty, kanaikhali</p>
//            </div>
//          </div>
//      </div>
//    </div>
//  </div>
//    <div className="flex justify-items-center pb-5">
//    <Clock timezone="Asia/Dhaka" location="DHAKA" />
        
//         {
//           visitorTimeZone?.timezone === 'Asia/Dhaka' ? (
//             <>
//               <Clock timezone="Europe/Berlin" location="CET" />
//             </>
//           ) : (
//             <Clock timezone={visitorTimeZone?.timezone} location={visitorTimeZone?.region} />
//           )
//         }
//  </div>
//  </section>
