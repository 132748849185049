import React from 'react';
import { FaClock, FaDollarSign, FaStar, FaCalendarAlt } from 'react-icons/fa';

const sections = [
  {
    title: 'Turn Around',
    content: 'Quick and efficient turnaround time for all your image editing needs. We ensure fast delivery without compromising on quality.',
    icon: <FaClock className="text-[#770099] text-2xl" />,
    borderColor: 'border-[#770099]'
  },
  {
    title: 'Pricing',
    content: 'Transparent pricing that fits your budget. Get professional photo editing services without breaking the bank.',
    icon: <FaDollarSign className="text-[#770099] text-2xl" />,
    borderColor: 'border-[#770099]'
  },
  {
    title: 'Quality',
    content: 'Unmatched quality in every edit. We pay attention to detail to ensure your product images stand out and leave a lasting impression.',
    icon: <FaStar className="text-[#770099] text-2xl" />,
    borderColor: 'border-[#770099]'
  },
  {
    title: 'Availability',
    content: 'We are available whenever you need us. Our team is dedicated to providing excellent service and support to meet your requirements.',
    icon: <FaCalendarAlt className="text-[#770099] text-2xl" />,
    borderColor: 'border-[#770099]'
  },
];

const Services = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-3 leading-tight text-center">
      Why To Choose SkillGraphics
      </h1>
      <p className="lg:w-auto lg:px-24 text-base mb-10 text-center">
        Quality, Commitment, and Reliability. In order to ensure the reliability
        of our customers, we have incorporated order management with integrated
        automated workflow management, therefore guaranteeing timely delivery
        of any number of images without compromising the quality.
      </p>
      <div className="relative grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {sections.map((section, index) => (
          <div
            key={index}
            className="border rounded-lg shadow-lg p-6 bg-white relative transform transition duration-500 hover:scale-105"
          >
            <div className="flex items-center justify-center mb-4">
              <div
                className={`w-10 h-10 rounded-full border-4 ${section.borderColor} flex items-center justify-center bg-white transition-all duration-300 mr-4`}
              >
                {section.icon}
              </div>
              <h2 className="text-xl font-bold text-center">{section.title}</h2>
            </div>
            <p className="text-center">{section.content}</p>
            {index % 2 === 0 && index < sections.length - 1 && (
              <div className={`hidden md:block absolute top-1/2 transform -translate-y-1/2 left-full w-16 border-t-2 border-dashed border-gray-500`}></div>
            )}
            {index % 2 === 1 && index < sections.length - 1 && index < sections.length - 2 && (
              <div className={`hidden lg:block absolute top-1/2 transform -translate-y-1/2 left-full w-16 border-t-2 border-dashed border-gray-500`}></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
