import React, { useState } from 'react';
import PackageCard from './PackageCard';
import ModalPackageForm from '../../ModalPackageForm/ModalPackageForm';
import ModalChoosePackageForm from '../ModalChoosePackageForm/ModalChoosePackageForm';

const PackageSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalChoosePlanOpen, setIsModalChoosePlanOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalChoosePlanOpen(false);
    
  };

  const handleChoosePlan = () => {
    setIsModalChoosePlanOpen(true);
  };
  const handleCustomizePlan = () => {
    setIsModalOpen(true);
  };

  const handleFormSubmit = () => {
    setIsModalOpen(false); 
  };

    const packages = [
        {
          title: 'STANDARD',
          time: '24 HOUR',
          description: 'Pick Our Standard delivery option with no extra charge.',
          features: [
            'Any Number of images.',
          ],
        },
        {
          title: 'EXPRESS',
          time: '12 HOUR / 18 HOUR',
          description: 'Pick Our Express delivery option with 50% additional charge.',
          features: [
            'Up to 500 - 1000 images.',
          ],
        },
        {
          title: 'URGENT',
          time: '6 HOUR',
          description: 'Pick Our Urgent delivery option with 100% additional charge.',
          features: [
            'Up to 100 - 500 images.',
          ],
        },
        {
          title: 'CUSTOMIZE',
          time: 'flexible Hour / Day',
          description: 'Your can customize, your order be delivered on your time zone up.',
          features: [
            'Any Number of images.',
          ],
        },
      ];
  return (
    <section className="py-12 container mx-auto bg-[#770099] text-white ">
      <h2 className="text-3xl font-extrabold md:text-5xl mb-3 text-center text-white ">Choose Your Perfect Package</h2>
      <p className="text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto lg:px-24 sm:px-8">
      Our post production system has been built from the group up with large volume orders in mind. We use both automated and manual quality control systems, so rest assured that your images will turn out just as you wanted them.
      </p>
      <section className="my-5 lg:mx-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {packages.map((pack, index) => (
            <PackageCard key={index} pack={pack} handleCustomizePlan={handleCustomizePlan} handleChoosePlan={handleChoosePlan} />
          ))}
        </div>
      </section>
      {isModalOpen && <ModalPackageForm isModalOpen={isModalOpen} closeModal={closeModal} onSubmit={handleFormSubmit}  />}
      {isModalChoosePlanOpen && <ModalChoosePackageForm isModalChoosePlanOpen={isModalChoosePlanOpen} closeModal={closeModal} onSubmit={handleFormSubmit}  />}
    </section>
  );
};

export default PackageSection;
