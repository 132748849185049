import React, { useEffect, useRef } from "react";
import Footer from '../Shared/Footer';
import Brandings from './Brandings';
import OurTeam from './OurTeam/OurTeam';
import Banner from './Banner'; 
import BusinessSummary from './BusinessSummary/BusinessSummary';
import Services from './Services';
import Questions from './Questions';
import ServiceQuality from './ServiceQuality';
import TalkOurProduct from './TalkOurProduct';
import ProductionPhotoEditing from './ProductionPhotoEditing';
import TestimonialOne from './Testimonials/TestimonialOne';
import TestimonialTwo from './Testimonials/TestimonialTwo';
import TestimonialThree from './Testimonials/TestimonialThree';
import PhotoGallery from './PhotoGallery/PhotoGallery';
import PackageSection from "./PackageSection/PackageSection";
import ImageEditingAi from "./ImageEditingAi/ImageEditingAi";
import ThreeDModelling from "./ThreeDModelling/ThreeDModelling";
import OurServices from "./OurServices/OurServices";
import DigitalMarketing from "./DigitalMarketing";
import EffortlessWorkflow from "./EffortlessWorkflow";
import MotionGraphics from "./MotionGraphics";
import EcommerceVideoEditing from "./EcommerceVideoEditing/EcommerceVideoEditing";
import VisualEffects from "./VisualEffects/VisualEffects";
import OtherBusinessServices from "../Masking/OtherBusinessServices";


const Home = () => {
  const myRef = useRef(null);

  useEffect(() => {
    const moveFocus = (e) => {
      const node = myRef.current;
      const active = document.activeElement;

      if (e.keyCode === 40 && active.nextSibling) {
        active.nextSibling.focus();
      }

      if (e.keyCode === 38 && active.previousSibling) {
        active.previousSibling.focus();
      }
    };

    const node = myRef.current;

    if (node) {
      node.addEventListener("keydown", moveFocus);
    }

    return () => {
      if (node) {
        node.removeEventListener("keydown", moveFocus);
      }
    };
  }, []);

  return (
    <div ref={myRef}>
    <div tabIndex="0"><Banner /></div>
    <div tabIndex="1"><Brandings/></div>
     <div tabIndex="2"> <ImageEditingAi /></div>
     <div tabIndex="3"> <TestimonialOne /></div>
     <div tabIndex="4"><OurServices/></div>
     <div tabIndex="5"> <TestimonialTwo /></div>
     <div tabIndex="6"> <EcommerceVideoEditing /></div>
     <div tabIndex="7"> <TestimonialThree /></div>
     {/* <div tabIndex="7"> <PhotoGallery /></div> */}
     <div tabIndex="8"> <VisualEffects /></div>
     <div tabIndex="10"> <DigitalMarketing /></div>
     <div tabIndex="9"> <ThreeDModelling /></div>
     <div tabIndex="11"> <MotionGraphics/></div>
     <div tabIndex="12"> <Questions /></div>
     <div tabIndex="13"> <ServiceQuality /></div>
     <div tabIndex="14"> <TalkOurProduct /></div>
     <div tabIndex="15"> <Footer /></div>
        </div>  
  );
};

export default Home;
