// import Typography from 'typography';
// import fairyGatesTheme from 'typography-theme-fairy-gates';

// fairyGatesTheme.baseFontSize = '20px';
// fairyGatesTheme.baseLineHeight = 1.5;

// fairyGatesTheme.overrideStyles = ({ rhythm }, options, styles) => ({
//   'body': {
//     margin: 0,
//     fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
//   },
//   'code': {
//     fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
//   },
//   'h1, h2, h3, h4, h5, h6': {
//     marginTop: 0,
//     marginBottom: 0,
//   },
// //   'h1, h2, h3, h4, h5, h6': {
// //   marginTop: rhythm(1), 
// //   marginBottom: rhythm(1), 
// // },
//   'p': {
//     marginBottom: 0,
//   },
//   'nav Link': {
//     textDecoration: 'none',
//     color: 'inherit',
//   },
//   'ul, li:hover': {
//     color: 'white',
//   },
// });

// const typography = new Typography(fairyGatesTheme);

// export const { scale, rhythm, options } = typography;
// typography.injectStyles();

// export default typography;


import Typography from 'typography';
import fairyGatesTheme from 'typography-theme-fairy-gates';

fairyGatesTheme.baseFontSize = '20px';
fairyGatesTheme.baseLineHeight = 1.5;

fairyGatesTheme.overrideStyles = ({ rhythm }, options, styles) => ({
  'body': {
    margin: 0,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  'code': {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  'h1, h2, h3, h4, h5, h6': {
    marginTop: 0,
    marginBottom: 0,
  },
  'p': {
    marginBottom: 0,
  },
  'nav Link': {
    textDecoration: 'none',
    color: 'inherit',
  },
  'ul, li:hover': {
    color: 'white',
  },
  'form': {
    fontSize: '16px',
    lineHeight: '1.4',
  },
  'form input, form textarea': {
    fontSize: '14px',
  },
});

const typography = new Typography(fairyGatesTheme);

export const { scale, rhythm, options } = typography;
typography.injectStyles();

export default typography;



// import Typography from 'typography';
// import fairyGatesTheme from 'typography-theme-fairy-gates';

// fairyGatesTheme.baseFontSize = '20px';
// fairyGatesTheme.baseLineHeight = 1;

// fairyGatesTheme.overrideStyles = ({ rhythm }, options, styles) => ({
//   'body': {
//     margin: 0,
//     fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
//   },
//   'code': {
//     fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
//   },
//   'h1, h2, h3, h4, h5, h6': {
//     marginTop: rhythm(1),
//     marginBottom: rhythm(1),
//   },
//   'p': {
//     marginTop: rhythm(0.5),
//     marginBottom: rhythm(0.5),
//   },
//   'nav a': {  // Corrected to target nav links properly
//     textDecoration: 'none',
//     color: 'inherit',
//   },
//   'ul, li:hover': {
//     color: 'white',
//   },
// });

// const typography = new Typography(fairyGatesTheme);

// export const { scale, rhythm, options } = typography;
// typography.injectStyles();

// export default typography;
