// import React from 'react';

// const Disclaimer = () => {
//   return (
//     <div className="container mx-auto my-8">
//       <h1 className="text-3xl font-bold mb-4">Disclaimer</h1>

//       <div className="prose">
//         <p>
//           Skill Graphics is a professional Image Post Production Company with its main studio located in Dhaka, Bangladesh (GMT+6). Our commitment is to provide high-quality services to reduce the cost of image post-production for Web shops/e-commerce, Photography, Fashion, Advertising, and Digital Marketing Agencies.
//         </p>

//         <h2>Service Limitations</h2>
//         <p>
//           While we strive to deliver top-notch Image Post Production services, it's important to note that our services are subject to the inherent limitations of digital media and technology. Skill Graphics does not guarantee absolute perfection, and results may vary based on factors beyond our control.
//         </p>

//         <h2>Professional Advice</h2>
//         <p>
//           The information provided on our website and through our services is for general informational purposes only. It does not constitute professional advice and should not be relied upon as such. For specific concerns or professional guidance related to image post-production, we recommend consulting with qualified professionals.
//         </p>

//         <h2>Intellectual Property</h2>
//         <p>
//           All content, including but not limited to images, graphics, and text, provided by Skill Graphics is subject to copyright and intellectual property laws. Users are prohibited from reproducing, distributing, or using our content without explicit permission.
//         </p>

//         <h2>Privacy Policy</h2>
//         <p>
//           Skill Graphics values the privacy of our users. Our Privacy Policy outlines how we collect, use, and protect personal information. By using our services, users agree to the terms outlined in our Privacy Policy.
//         </p>

//         <h2>Changes to Terms</h2>
//         <p>
//           Skill Graphics reserves the right to update, modify, or revise these terms and conditions at any time. Users are encouraged to review the terms periodically for any changes. Continued use of our services implies acceptance of the updated terms.
//         </p>

//         <h2>Contact Information</h2>
//         <p>
//           For inquiries regarding our legal disclaimer or any other matter, please contact us at <a href="mailto:contact@skillgraphics.biz">contact@skillgraphics.biz</a>.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Disclaimer;


import React from 'react';

const Disclaimer = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="bg-white p-8 rounded-md shadow-lg w-full">
        <h1 className="text-4xl text-[#770099] font-bold mb-8 underline text-center">Disclaimer</h1>
        <p className="mb-6 text-justify">
          Skill Graphics is a professional Image Post Production Company with its main studio located in Dhaka, Bangladesh (GMT+6). Our commitment is to provide high-quality services to reduce the cost of image post-production for Web shops/e-commerce, Photography, Fashion, Advertising, and Digital Marketing Agencies.
        </p>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Service Limitations:</h2>
          <p className="text-justify">
            While we strive to deliver top-notch Image Post Production services, it's important to note that our services are subject to the inherent limitations of digital media and technology. Skill Graphics does not guarantee absolute perfection, and results may vary based on factors beyond our control.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Professional Advice:</h2>
          <p className="text-justify">
            The information provided on our website and through our services is for general informational purposes only. It does not constitute professional advice and should not be relied upon as such. For specific concerns or professional guidance related to image post-production, we recommend consulting with qualified professionals.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Intellectual Property:</h2>
          <p className="text-justify">
            All content, including but not limited to images, graphics, and text, provided by Skill Graphics is subject to copyright and intellectual property laws. Users are prohibited from reproducing, distributing, or using our content without explicit permission.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Privacy Policy:</h2>
          <p className="text-justify">
            Skill Graphics values the privacy of our users. Our Privacy Policy outlines how we collect, use, and protect personal information. By using our services, users agree to the terms outlined in our Privacy Policy.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Changes to Terms:</h2>
          <p className="text-justify">
            Skill Graphics reserves the right to update, modify, or revise these terms and conditions at any time. Users are encouraged to review the terms periodically for any changes. Continued use of our services implies acceptance of the updated terms.
          </p>
        </div>

        <div className="mb-6 text-justify">
          <h2 className="text-xl font-semibold mb-2">Contact Information:</h2>
          <p className="text-justify">
            For inquiries regarding our legal disclaimer or any other matter, please contact us at <a href="mailto:support@skillgraphics.biz" className="text-purple-700">support@skillgraphics.biz</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
