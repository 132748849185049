
// import React, { useState, useEffect } from 'react';
// import {
//   FaArrowCircleUp,
//   FaArrowCircleDown,
//   FaFacebookMessenger,
//   FaWhatsapp,
//   FaSkype,
// } from 'react-icons/fa';
// import ModalForm from '../ModalForm/ModalForm';

// const ScrollButton = () => {
//   const [visible, setVisible] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);


//   const toggleVisible = () => {
//     const scrolled = document.documentElement.scrollTop;
//     if (scrolled > 300) {
//       setVisible(true);
//     } else {
//       setVisible(false);
//     }
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({
//       top: document.documentElement.scrollHeight,
//       behavior: 'smooth',
//     });
//   };


//   const handleTestButtonClick = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };



//   useEffect(() => {
//     window.addEventListener('scroll', toggleVisible);
//     return () => {
//       window.removeEventListener('scroll', toggleVisible);
//     };
//   }, []);

//   return (
//     <div>
//       {visible ? (
//         <button
//           className="fixed right-0 bottom-0 p-4 z-10 cursor-pointer bg-[#581C87] text-white rounded-full hover:scale-110 transition-all duration-500"
//           onClick={scrollToTop}
//         >
//           <FaArrowCircleUp className="text-3xl" />
//         </button>
//       ) : (
//         <button
//           className="fixed right-0 bottom-0 p-4 z-10 cursor-pointer bg-[#581C87] text-white rounded-full hover:scale-110 transition-all duration-500"
//           onClick={scrollToBottom}
//         >
//           <FaArrowCircleDown className="text-3xl" />
//         </button>
//       )}

//       <a
//         href="https://www.facebook.com/skillgraphics.biz"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="fixed right-16 bottom-0 p-4 z-10 cursor-pointer bg-blue-500 text-white rounded-full hover:scale-110 transition-all duration-300"
//         // onClick={handleMessengerClick}
//       >
//         <FaFacebookMessenger className="text-3xl" />
//       </a>
//       {/* <button
//         className="fixed right-16 bottom-0 p-4 z-10 cursor-pointer bg-blue-500 text-white rounded-full hover:scale-110 transition-all duration-300"
//         onClick={handleMessengerClick}
//       >
//         <FaFacebookMessenger className="text-3xl" />
//       </button> */}
//       <a
//        href="https://api.whatsapp.com/send?phone=8801723099983"
//        target="_blank"
//        rel="noopener noreferrer"
//         className="fixed right-32 bottom-0 p-4 z-10 cursor-pointer bg-green-500 text-white rounded-full hover:scale-110 transition-all duration-300"
//         // onClick={handleWhatsappClick}
//       >
//         <FaWhatsapp className="text-3xl" />
//       </a>

//       <a
//         href="https://join.skype.com/invite/JCh6h87c0V7C"
//         target="_blank"
//         rel="noopener noreferrer"
//         className="fixed right-48 bottom-0 p-4 z-10 cursor-pointer bg-sky-500 text-white rounded-full hover:scale-110 transition-all duration-300"
//         // onClick={handleSkypeClick}
//       >
//         <FaSkype className="text-3xl" />
//       </a>

//       <button
//         className="fixed right-64 bottom-0 font-semibold p-2 px-5 z-10 cursor-pointer bg-[#AC02DB] text-white rounded-full hover:scale-110 transition-all duration-300"
//         onClick={handleTestButtonClick}
//       >
//         Try <br/> us
//       </button>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal}/>
//       {/* {selectedChat && renderChatWindow()} */}
//     </div>
//   );
// };

// export default ScrollButton;


import React, { useState, useEffect } from 'react';
import {
  FaArrowCircleUp,
  FaArrowCircleDown,
  FaFacebookMessenger,
  FaWhatsapp,
  FaSkype,
} from 'react-icons/fa';
import ModalForm from '../ModalForm/ModalForm';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setHasScrolled(scrolled > 0);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleTestButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <div>
      {hasScrolled && (
        <>
          {visible ? (
            <button
              className="fixed right-0 bottom-0 p-4 z-10 cursor-pointer bg-[#581C87] text-white rounded-full hover:scale-110 transition-all duration-500"
              onClick={scrollToTop}
            >
              <FaArrowCircleUp className="text-3xl" />
            </button>
          ) : (
            <button
              className="fixed right-0 bottom-0 p-4 z-10 cursor-pointer bg-[#581C87] text-white rounded-full hover:scale-110 transition-all duration-500"
              onClick={scrollToBottom}
            >
              <FaArrowCircleDown className="text-3xl" />
            </button>
          )}

          <a
            href="https://www.facebook.com/skillgraphics.biz"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed right-16 bottom-0 p-4 z-10 cursor-pointer bg-blue-500 text-white rounded-full hover:scale-110 transition-all duration-300"
          >
            <FaFacebookMessenger className="text-3xl" />
          </a>

          <a
            href="https://api.whatsapp.com/send?phone=8801723099983"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed right-32 bottom-0 p-4 z-10 cursor-pointer bg-green-500 text-white rounded-full hover:scale-110 transition-all duration-300"
          >
            <FaWhatsapp className="text-3xl" />
          </a>

          <a
            href="https://join.skype.com/invite/JCh6h87c0V7C"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed right-48 bottom-0 p-4 z-10 cursor-pointer bg-sky-500 text-white rounded-full hover:scale-110 transition-all duration-300"
          >
            <FaSkype className="text-3xl" />
          </a>

          <button
            className="fixed right-64 bottom-0 font-semibold  p-2 px-5 z-10 cursor-pointer bg-[#AC02DB] text-white rounded-full hover:scale-110 transition-all duration-300"
            onClick={handleTestButtonClick}
          >
            Try <br /> us
          </button>
        </>
      )}
      <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default ScrollButton;

