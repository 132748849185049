import React from "react";
import ReactCompareImage from "react-compare-image";
import { Link } from "react-router-dom";
import beforeRetouching from "../../../images/Retouching/Before/Skill_PMS.appref-ms.png";
import afterRetouching from "../../../images/Retouching/After/Front_FC-930EM3H6-MockUp_A.png";
import JewelleryAfter from "../../../images/Jewellery Retouching/after/2.jpg";
import jewelleryBefore from "../../../images/Jewellery Retouching/before/1.jpg";
import ModelAfter from "../../../images/Model Retouch/After/Novia-350_After.jpg";
import ModelBefore from "../../../images/Model Retouch/Before/Novia-350_B.jpg";

const serviceData = [
  {
    service: "Retouching",
    beforeImage: beforeRetouching,
    afterImage: afterRetouching,
    path: "/photo-retouching",
  },
  {
    service: "Jewellery Retouching",
    beforeImage: jewelleryBefore,
    afterImage: JewelleryAfter,
    path: "/jewelleryRetouching",
  },
  {
    service: "Model Retouching",
    beforeImage: ModelBefore,
    afterImage: ModelAfter,
    path: "/modelRetouching",
  },
];

const EcommerceRetouching = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-[#770099] text-center">
        Ecommerce Retouching
      </h1>
      <p className="text-lg text-gray-700 mb-4 text-center">
        Elevate your online store with our Ecommerce Retouching services! <br/>
        Captivate your customers with stunning product images that showcase your
      merchandise in the best light possible...
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:mx-10">
          {serviceData.map((data, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4"
            >
              <Link to={data.path}>
                <ReactCompareImage
                  hover
                  handleSize={40}
                  rightImage={data.afterImage}
                  leftImage={data.beforeImage}
                  leftImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                  rightImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                />
                <p className="text-base hover:underline hover:text-[#770099] sm:text-lg md:text-base lg:text-lg font-semibold mt-2">{data.service}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EcommerceRetouching;
