import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { FaChevronDown  } from 'react-icons/fa';

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
          <h3 className="text-lg font-semibold">{header}</h3>
        <div
          className={`ml-auto transition-transform duration-200 ease-out ${
            isEnter && "rotate-180"
          }`}
        ><FaChevronDown /></div>
          
      </>
    )}
    className="border-b"
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full p-2 text-left hover:bg-slate-100 ${
          isEnter && "bg-slate-200"
        }`
    }}
    contentProps={{
      className: "transition-height duration-200 ease-out"
    }}
    panelProps={{ className: "p-4" }}
  />
);

export default function Questions() {

  const items = [
    {
      header: 'How do I submit my images for editing at Skill Graphics?',
      content: "It's easy! Simply contact us to discuss your project and preferred submission method, whether it's through email, FTP, or another convenient option."
    },
    {
      header: 'What Types of Professional Photo Editing Services Are Available ?',
      content: "We offer a wide range of professional photo editing services, including but not limited to: 1.Masking  2.Retouching 3.Clipping 4.Color 5.Ghost Mannequin 6.Flatlay 7.Jewellery 8.Model Retouch 9.Shadow 10.Color Adjustment 11.Manipulation 12.Restoration 13.360"     
    },
    {
      header: "What's the Turnaround Time for Your Professional Photo Editing Services?",
      content: 'Our commitment to timely delivery means that every project is ensured at least one hour prior to the agreed deadline.'
    },
    {
      header: 'Can Skill Graphics Accommodate My Bulk Photo Editing Requirements?',
      content: 'Absolutely. We handle daily production volumes of thousands of images, so whether you have a small or large batch of photos, Skill Graphics can scale to meet your requirements.'
    },
    {
      header: 'What Level of Customer Support Can I Expect from Skill Graphics?',
      content: 'Skill Graphics is committed to exceptional customer support. From the moment we receive your images to the final upload, we keep you informed through various channels such as email, instant messaging, or SMS.'
    },
    {
      header: 'Do You Provide Retouching Services for Pet, Portrait, Real Estate, or Wedding Photos?',
      content: 'Yes, our team offers customized services to meet your specific requirements and preferences, including retouching services for a wide range of photo types, from pet and portrait images to real estate and wedding photos.'
    },
  ];

  return (
<section className="container mx-auto py-12 md:py-12 relative">
<div className="flex flex-col md:flex-row items-center gap-8 lg:mx-10">
  <div className="md:w-2/4 py-8 md:py-12 md:mb-0 text-center md:text-left">
    <div className="flex items-center space-x-4">
      <div className="text-justify">
        <h2 className="text-3xl md:text-5xl lg:text-5xl font-bold leading-tight text-[#770099]">
        Your Questions, <br  /> Our Solutions
        </h2>
        <p className="text-gray-600 mt-4 md:mt-6 text-base md:text-lg">
        Working with an image editing service provider should be a smooth, frustration-free experience. At Skill Graphics, we're here to address your concerns and streamline your creative process. Explore our frequently asked questions for clarity and confidence.
        </p>
      </div>
    </div>
  </div>

  <div className="md:w-2/3 md:mb-0 my-4 border-t">
  <Accordion transition transitionTimeout={200}>
      {items.map(({ header, content }, i) => (
        <AccordionItem header={header} key={i}>
          <p className="text-justify">
          {content}
          </p>
        </AccordionItem>
      ))} 
      </Accordion>
  </div>
  </div>
</section>
  );
}

