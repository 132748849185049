// import React from "react";
// import ReactCompareImage from "react-compare-image";
// import BeforeManipulation from "../../../images/Manipulation/before/IMAGE-01.png"
// import AfterManipulation from "../../../images/Manipulation/after/IMAGE-01_A.png"
// import BeforeRestoration from "../../../images/Restoration/before/JKane01.png"
// import AfterRestoration from "../../../images/Restoration/after/JKane01_A.png"
// import BeforeFurniture from "../../../images/Furniture/before/Furniture1 (1).png"
// import afterFurniture from "../../../images/Furniture/after/Furniture2 (1).png"
// import { Link } from "react-router-dom";

// const serviceData = [
//   {
//     service: "Manipulation",
//     beforeImage: BeforeManipulation,
//     afterImage: AfterManipulation,
//     path: "/manipulation"
//   },
//   {
//     service: "Restoration",
//     beforeImage: BeforeRestoration,
//     afterImage: AfterRestoration,
//     path: "/restoration"
//   },
//   {
//     service: "  Color Adjustment",
//     beforeImage: BeforeFurniture,
//     afterImage: afterFurniture,
//     path: "/furniture"
//   },
// ];

// const OtherServices = () => {
//   return (
//     <div className="bg-[#f2f2f2] container mx-auto">
//       <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-[#770099] text-center">Other Services</h1>
     
//       <div className="container mx-auto py-8 px-4 sm:px-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:mx-20">
//           {serviceData.map((data, index) => (
//             <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4"> 
//             <Link to={data.path}>
//               <ReactCompareImage
//                 hover
//                 handleSize={40}
//                 leftImage={data.beforeImage}
//                 rightImage={data.afterImage}
//                 leftImageCss={{
//                   width: "100%",
//                   height: "auto",
//                 }}
//                 rightImageCss={{
//                   width: "100%",
//                   height: "auto",
//                 }}
//                 // leftImageLabel="Before"
//                 // rightImageLabel="After"
//               />
//                 <p className="text-base hover:underline hover:text-[#770099] sm:text-lg md:text-base lg:text-lg font-semibold mt-2">{data.service}</p>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default OtherServices;


import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeManipulation from "../../../images/Manipulation/before/IMAGE-01.png";
import AfterManipulation from "../../../images/Manipulation/after/IMAGE-01_A.png";
import BeforeRestoration from "../../../images/Restoration/before/JKane01.png";
import AfterRestoration from "../../../images/Restoration/after/JKane01_A.png";
import BeforeFurniture from "../../../images/Furniture/before/Furniture1 (1).png";
import afterFurniture from "../../../images/Furniture/after/Furniture2 (1).png";
import { Link } from "react-router-dom";

const serviceData = [
  {
    service: "Manipulation",
    beforeImage: BeforeManipulation,
    afterImage: AfterManipulation,
    path: "/manipulation"
  },
  {
    service: "Restoration",
    beforeImage: BeforeRestoration,
    afterImage: AfterRestoration,
    path: "/restoration"
  },
  {
    service: "  Color Adjustment",
    beforeImage: BeforeFurniture,
    afterImage: afterFurniture,
    path: "/furniture"
  },
];

const OtherServices = () => {
  return (
    <div className="bg-[#f2f2f2] container pb-12 mx-auto">
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-[#770099] text-center">Other Services</h1>
      <p className="text-lg text-gray-700 mb-4 text-center lg:px-20">
         Unleash the magic of image manipulation, restore memories with a touch of enchantment, <br/> and infuse vibrancy into furniture photography with our Color Adjustment expertise.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:mx-10">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <Link to={data.path}>
                <ReactCompareImage
                  hover
                  handleSize={40}
                  leftImage={data.beforeImage}
                  rightImage={data.afterImage}
                  leftImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                  rightImageCss={{
                    width: "100%",
                    height: "auto",
                  }}
                />
                <p className="text-base hover:underline hover:text-[#770099] sm:text-lg md:text-base lg:text-lg font-semibold mt-2">
                  {data.service}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default OtherServices;
