import React from 'react';
import Scale from '../../images/studio/WhatsApp Image 2023-12-07 at 5.14.59 PM (1).jpeg';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const ServiceQuality = () => {
  return (
    <section className="px-4 container mx-auto py-10 bg-[#770099]">
      <div className="container mx-auto flex flex-col md:flex-row items-center">  
        <div className="md:w-2/4 lg:mr-6 px-10 text-left">
          <div className="flex items-center space-x-4  mb-4">
            <AiOutlineCheckCircle className="w-6 h-6 text-green-600" />
            <p className='text-white text-2xl font-bold mb-1' >24/6 Support</p>
          </div>
          <p className="text-white ms-10 mb-4">
          Our commitment to your success knows no time constraints. Place orders and access our services round the clock. 
          </p>

          <div className="flex items-center space-x-4  mb-4">
            <AiOutlineCheckCircle className="w-6 h-6 text-green-600" />
            <p className='text-slate-50 text-2xl font-bold mb-1'>Satisfaction Assured</p>
          </div>
          <p className="text-slate-50 ms-10 mb-4">
          We deliver processed images exactly to your specifications, ensuring your vision is met with precision.
          </p>

          <div className="flex items-center space-x-4 mb-4">
            <AiOutlineCheckCircle className="w-6 h-6 text-green-600" />
            <p className='text-white text-2xl font-bold mb-1'>Customized Solutions</p>
          </div>
          <p className="text-white ms-10">
          Our services are tailored to your preferences, providing customized solutions to match the diversity of your requirements.
          </p>
        </div>
        <div className="md:w-2/4 lg:pr-10  py-8 md:mb-0">
          <img
            src={Scale}
            alt="Scale With Confidence"
            className="md:w-full lg:max-w-2xl rounded-lg  transform transition-transform duration-300"
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceQuality;
