////////////////////////////

// import React, { useState } from 'react';
// import Scale from '../../images/Home/banner1.jpeg';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import ModalForm from '../ModalForm/ModalForm';


// const Banner = () => {

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_nzpnxuh",
//         "template_74tam52",
//         e.target,
//         "4sUKZTUfMXeJ0lrt7"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   const handleTestButtonClick = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
//       <div className="flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 md:pr-8 text-start md:text-left">
//           <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
//             Professional Photo Retouching <br/> at your Fingertips 
//           </h1>

//           <p className="text-base md:text-lg text-[#646a76] mb-6">
//             Experience the Skill Graphics Difference.
//           </p>

//           <div className="flex justify-start">
//              <form  onSubmit={sendEmail}>
//                 <input
//                   type="email"
//                   name='email'
//                   placeholder="Your Email"
//                   className="px-4 py-2 border border-gray-300 rounded-l focus:outline-none focus:ring focus:border-blue-300"
//                   required
//                 />
//                 <button type='submit' className="bg-[#770099] hover:bg-[#631976]  text-white px-4 py-2 rounded-r focus:outline-none focus:ring focus:border-purple-300">
//                   Get Started
//                 </button>
//              </form>
//           </div>
//         </div>
//         <div className="md:w-1/2 mt-6 md:mt-0 text-center md:text-right">
//           <img
//             src={Scale}
//             alt="Scale With Confidence"
//             className="max-w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
//           />
//         </div>
//       </div>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal}/>
//     </section>
//   );
// };

// export default Banner;

////

// import React, { useState } from 'react';
// import Slider from "react-slick";
// import banner1 from '../../images/Home/Banner/Clipping.jpg';
// import banner2 from '../../images/Home/Banner/Flatlay.jpg';
// import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
// import banner4 from '../../images/Home/Banner/Jewelry.jpg';
// import banner5 from '../../images/Home/Banner/Manipulation.jpg';
// import banner6 from '../../images/Home/Banner/Masking.jpg';
// import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
// import banner8 from '../../images/Home/Banner/Shoe.jpg';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import ModalForm from '../ModalForm/ModalForm';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// const Banner = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_nzpnxuh",
//         "template_74tam52",
//         e.target,
//         "4sUKZTUfMXeJ0lrt7"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     adaptiveHeight: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       }
//     ]
//   };

//   const slides = [
//     { image: banner1 },
//     { image: banner2 },
//     { image: banner3 },
//     { image: banner4 },
//     { image: banner5 },
//     { image: banner6 },
//     { image: banner7 },
//     { image: banner8 },
//   ];

//   return (
//     <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
//       <div className="flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 md:pr-8 text-start md:text-left">
//           <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
//             Professional Photo Retouching <br /> at your Fingertips 
//           </h1>
//           <p className="text-base md:text-lg text-[#646a76] mb-6">
//             Experience the Skill Graphics Difference.
//           </p>
//           <div className="flex justify-start">
//             <form onSubmit={sendEmail} className="flex flex-col md:flex-row w-full md:w-auto">
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Your Email"
//                 className="px-4 py-2 border border-gray-300 rounded-t md:rounded-l md:rounded-t-none focus:outline-none focus:ring focus:border-blue-300"
//                 required
//               />
//               <button type="submit" className="bg-[#770099] hover:bg-[#631976] text-white px-4 py-2 rounded-b md:rounded-r md:rounded-b-none focus:outline-none focus:ring focus:border-purple-300">
//                 Get Started
//               </button>
//             </form>
//           </div>
//         </div>
//         <div className="md:w-1/2 mt-6 md:mt-0 text-center">
//           <Slider {...settings}>
//             {slides.map((slide, index) => (
//               <div key={index}>
//                 <img
//                   src={slide.image}
//                   alt={`Slide ${index + 1}`}
//                   className="w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
//                 />
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
//     </section>
//   );
// };

// export default Banner;

//new

// import React, { useState } from 'react';
// import Slider from "react-slick";
// import banner1 from '../../images/Home/Banner/Clipping.jpg';
// import banner2 from '../../images/Home/Banner/Flatlay.jpg';
// import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
// import banner4 from '../../images/Home/Banner/Jewelry.jpg';
// import banner5 from '../../images/Home/Banner/Manipulation.jpg';
// import banner6 from '../../images/Home/Banner/Masking.jpg';
// import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
// import banner8 from '../../images/Home/Banner/Shoe.jpg';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import ModalForm from '../ModalForm/ModalForm';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// const Banner = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_nzpnxuh",
//         "template_74tam52",
//         e.target,
//         "4sUKZTUfMXeJ0lrt7"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     adaptiveHeight: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       }
//     ]
//   };

//   const slides = [
//     { image: banner1 },
//     { image: banner2 },
//     { image: banner3 },
//     { image: banner4 },
//     { image: banner5 },
//     { image: banner6 },
//     { image: banner7 },
//     { image: banner8 },
//   ];

//   return (
//     <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
//       <div className="flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 md:pr-8 text-start md:text-left">
//           <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
//             Professional Photo Retouching <br /> at your Fingertips 
//           </h1>
//           <p className="text-base md:text-lg text-[#646a76] mb-6">
//             Experience the Skill Graphics Difference.
//           </p>
//           <div className="flex justify-start">
//             <form onSubmit={sendEmail} className="flex flex-col md:flex-row w-full md:w-auto">
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Your Email"
//                 className="px-4 py-2 border border-gray-300 rounded-t md:rounded-l md:rounded-t-none focus:outline-none focus:ring focus:border-blue-300"
//                 required
//               />
//               <button type="submit" className="bg-[#770099] hover:bg-[#631976] text-white px-4 py-2 rounded-b md:rounded-r md:rounded-b-none focus:outline-none focus:ring focus:border-purple-300">
//                 Get Started
//               </button>
//             </form>
//           </div>
//         </div>
//         <div className="md:w-1/2 mt-6 md:mt-0 text-center">
//           <Slider {...settings}>
//             {slides.map((slide, index) => (
//               <div key={index}>
//                 <img
//                   src={slide.image}
//                   alt={`Slide ${index + 1}`}
//                   className="w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
//                 />
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
//     </section>
//   );
// };

// export default Banner;


// import React from 'react';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import banner1 from '../../images/Home/Banner/Clipping.jpg';
// import banner2 from '../../images/Home/Banner/Flatlay.jpg';
// import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
// import banner4 from '../../images/Home/Banner/Jewelry.jpg';
// import banner5 from '../../images/Home/Banner/Manipulation.jpg';
// import banner6 from '../../images/Home/Banner/Masking.jpg';
// import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
// import banner8 from '../../images/Home/Banner/Shoe.jpg';

// const Banner = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     adaptiveHeight: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         }
//       }
//     ]
//   };

//   const slides = [
//     { image: banner1 },
//     { image: banner2 },
//     { image: banner3 },
//     { image: banner4 },
//     { image: banner5 },
//     { image: banner6 },
//     { image: banner7 },
//     { image: banner8 },
//   ];

//   return (
//     <div>
//       <Slider {...settings}>
//         {slides.map((slide, index) => (
//           <div key={index}>
//             <img
//               src={slide.image}
//               alt={`Slide ${index + 1}`}
//               className="w-full h-auto rounded-lg"
//             />
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default Banner;

import React, { useState } from 'react';
import Slider from "react-slick";
import banner1 from '../../images/Home/Banner/Clipping.jpg';
import banner2 from '../../images/Home/Banner/Flatlay.jpg';
import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
import banner4 from '../../images/Home/Banner/Jewelry.jpg';
import banner5 from '../../images/Home/Banner/Manipulation.jpg';
import banner6 from '../../images/Home/Banner/Masking.jpg';
import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
import banner8 from '../../images/Home/Banner/Shoe.jpg';
import swal from 'sweetalert';
import emailjs from "@emailjs/browser";
import ModalForm from '../ModalForm/ModalForm';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_nzpnxuh",
        "template_74tam52",
        e.target,
        "4sUKZTUfMXeJ0lrt7"
      )
      .then(
        (result) => {
          swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const slides = [
    { image: banner1 },
    { image: banner2 },
    { image: banner3 },
    { image: banner4 },
    { image: banner5 },
    { image: banner6 },
    { image: banner7 },
    { image: banner8 },
  ];

  return (
    <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-8 text-start md:text-left">
          <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
            Professional Photo Retouching <br /> at your Fingertips 
          </h1>
          <p className="text-base md:text-lg text-[#646a76] mb-6">
            Experience the Skill Graphics Difference.
          </p>
          <div className="flex justify-start">
            <form onSubmit={sendEmail} className="flex flex-col md:flex-row w-full md:w-auto">
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                className="px-4 py-2 border border-gray-300 rounded-t md:rounded-l md:rounded-t-none focus:outline-none focus:ring focus:border-blue-300"
                required
              />
              <button type="submit" className="bg-[#770099] hover:bg-[#631976] text-white px-4 py-2 rounded-b md:rounded-r md:rounded-b-none focus:outline-none focus:ring focus:border-purple-300">
                Get Started
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:w-1/2 mt-6 md:mt-0 text-center">
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <img
                  src={slide.image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
    </section>
  );
};

export default Banner;


// import React, { useState } from 'react';
// import Slider from "react-slick";
// import banner1 from '../../images/Home/Banner/Clipping.jpg';
// import banner2 from '../../images/Home/Banner/Flatlay.jpg';
// import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
// import banner4 from '../../images/Home/Banner/Jewelry.jpg';
// import banner5 from '../../images/Home/Banner/Manipulation.jpg';
// import banner6 from '../../images/Home/Banner/Masking.jpg';
// import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
// import banner8 from '../../images/Home/Banner/Shoe.jpg';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import ModalForm from '../ModalForm/ModalForm';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// const Banner = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_nzpnxuh",
//         "template_74tam52",
//         e.target,
//         "4sUKZTUfMXeJ0lrt7"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const desktopSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     adaptiveHeight: true
//   };

//   const mobileSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     adaptiveHeight: true,
//     responsive: [
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   const slides = [
//     { image: banner1 },
//     { image: banner2 },
//     { image: banner3 },
//     { image: banner4 },
//     { image: banner5 },
//     { image: banner6 },
//     { image: banner7 },
//     { image: banner8 },
//   ];

//   return (
//     <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
//       <div className="flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 md:pr-8 text-start md:text-left">
//           <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
//             Professional Photo Retouching <br /> at your Fingertips 
//           </h1>
//           <p className="text-base md:text-lg text-[#646a76] mb-6">
//             Experience the Skill Graphics Difference.
//           </p>
//           <div className="flex justify-start">
//             <form onSubmit={sendEmail} className="flex flex-col md:flex-row w-full md:w-auto">
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Your Email"
//                 className="px-4 py-2 border border-gray-300 rounded-t md:rounded-l md:rounded-t-none focus:outline-none focus:ring focus:border-blue-300"
//                 required
//               />
//               <button type="submit" className="bg-[#770099] hover:bg-[#631976] text-white px-4 py-2 rounded-b md:rounded-r md:rounded-b-none focus:outline-none focus:ring focus:border-purple-300">
//                 Get Started
//               </button>
//             </form>
//           </div>
//         </div>
//         <div className="md:w-1/2 mt-6 md:mt-0 text-center">
//           {window.innerWidth > 1024 ? (
//             <Slider {...desktopSettings}>
//               {slides.map((slide, index) => (
//                 <div key={index}>
//                   <img
//                     src={slide.image}
//                     alt={`Slide ${index + 1}`}
//                     className="w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
//                   />
//                 </div>
//               ))}
//             </Slider>
//           ) : (
//             <Slider {...mobileSettings}>
//               {slides.map((slide, index) => (
//                 <div key={index}>
//                   <img
//                     src={slide.image}
//                     alt={`Slide ${index + 1}`}
//                     className="w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
//                   />
//                 </div>
//               ))}
//             </Slider>
//           )}
//         </div>
//       </div>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
//     </section>
//   );
// };

// export default Banner;


// import React, { useState } from 'react';
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import banner1 from '../../images/Home/Banner/Clipping.jpg';
// import banner2 from '../../images/Home/Banner/Flatlay.jpg';
// import banner3 from '../../images/Home/Banner/Ghost Mannequin.jpg';
// import banner4 from '../../images/Home/Banner/Jewelry.jpg';
// import banner5 from '../../images/Home/Banner/Manipulation.jpg';
// import banner6 from '../../images/Home/Banner/Masking.jpg';
// import banner7 from '../../images/Home/Banner/Model Retouch & Color Adjustment.jpg';
// import banner8 from '../../images/Home/Banner/Shoe.jpg';
// import swal from 'sweetalert';
// import emailjs from "@emailjs/browser";
// import ModalForm from '../ModalForm/ModalForm';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';


// const Banner = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm(
//         "service_nzpnxuh",
//         "template_74tam52",
//         e.target,
//         "4sUKZTUfMXeJ0lrt7"
//       )
//       .then(
//         (result) => {
//           swal("Thank you for reaching out", "One of us will get back to you shortly", "success");
//           e.target.reset();
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const slides = [
//     { image: banner1 },
//     { image: banner2 },
//     { image: banner3 },
//     { image: banner4 },
//     { image: banner5 },
//     { image: banner6 },
//     { image: banner7 },
//     { image: banner8 },
//   ];

//   const AutoplaySlider = withAutoplay(AwesomeSlider);


//   return (
//     <section id="section1" className="py-16 container mx-auto px-4 md:px-14 bg-[#f8f8f8] font-sans">
//       <div className="flex flex-col md:flex-row items-center">
//         <div className="md:w-1/2 md:pr-8 text-start md:text-left">
//           <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
//             Professional Photo Retouching <br /> at your Fingertips 
//           </h1>
//           <p className="text-base md:text-lg text-[#646a76] mb-6">
//             Experience the Skill Graphics Difference.
//           </p>
//           <div className="flex justify-start">
//             <form onSubmit={sendEmail} className="flex flex-col md:flex-row w-full md:w-auto">
//               <input
//                 type="email"
//                 name="email"
//                 placeholder="Your Email"
//                 className="px-4 py-2 border border-gray-300 rounded-t md:rounded-l md:rounded-t-none focus:outline-none focus:ring focus:border-blue-300"
//                 required
//               />
//               <button type="submit" className="bg-[#770099] hover:bg-[#631976] text-white px-4 py-2 rounded-b md:rounded-r md:rounded-b-none focus:outline-none focus:ring focus:border-purple-300">
//                 Get Started
//               </button>
//             </form>
//           </div>
//         </div>
//         <div className="md:w-1/2 mt-6 md:mt-0 text-center md:text-right">
//           <AutoplaySlider
//      play={true}
//      cancelOnInteraction={false} 
//      interval={3000} animation="openAnimation">
//             {slides.map((slide, index) => (
//               <div key={index} data-src={slide.image} />
//             ))}
//           </AutoplaySlider>
//         </div>
//       </div>
//       <ModalForm isModalOpen={isModalOpen} closeModal={closeModal} />
//     </section>
//   );
// };

// export default Banner;

