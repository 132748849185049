import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeJewelleryRetouching from "../../../src/images/Jewellery Retouching/before/5.jpg"
import afterJewelleryRetouching from "../../../src/images/Jewellery Retouching/after/6.jpg"
import BeforeJewelleryRetouching2 from "../../../src/images/Jewellery Retouching/before/Before_DSC1011.jpg"
import afterJewelleryRetouching2 from "../../../src/images/Jewellery Retouching/after/After_DSC1011.jpg"
import BeforeJewelleryRetouching3 from "../../../src/images/Jewellery Retouching/before/3.jpg"
import afterJewelleryRetouching3 from "../../../src/images/Jewellery Retouching/after/4.jpg"
import BeforeJewelleryRetouching4 from "../../../src/images/Jewellery Retouching/before/Byzantine_silver_gold_band_ring1.png"
import afterJewelleryRetouching4 from "../../../src/images/Jewellery Retouching/after/Byzantine_silver_gold_band_ring1.png"
import BeforeJewelleryRetouching5 from "../../../src/images/Jewellery Retouching/before/DSC_1773gold versions.png"
import afterJewelleryRetouching5 from "../../../src/images/Jewellery Retouching/after/DSC_1773gold versions_A.png"

const serviceData = [

  {
    beforeImage: BeforeJewelleryRetouching4,
    afterImage: afterJewelleryRetouching4,
  },
  {
    beforeImage: BeforeJewelleryRetouching3,
    afterImage: afterJewelleryRetouching3,
  },
  {
    beforeImage: BeforeJewelleryRetouching,
    afterImage: afterJewelleryRetouching,
  },
  {
    beforeImage: BeforeJewelleryRetouching5,
    afterImage: afterJewelleryRetouching5,
  },
  {
    beforeImage: BeforeJewelleryRetouching2,
    afterImage: afterJewelleryRetouching2,
  },
];

const JewelleryRetouching = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Jewellery Retouching</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Prepare your images for versatile editing – optimize for text wrapping, seamless background removal, and suitability for both print and web applications.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JewelleryRetouching;

/*****************************************/

// import React from 'react';
// import {Helmet} from "react-helmet";
// import Services from '../Home/Services';
// import TestimonialOne from '../Home/Testimonials/TestimonialOne';
// import Brandings from '../Home/Brandings';
// import Footer from '../Shared/Footer';
// import TalkOurProduct from '../Home/TalkOurProduct';
// import ServiceQuality from '../Home/ServiceQuality';
// import PackageSection from '../Home/PackageSection/PackageSection';
// import BusinessSummary from '../Home/BusinessSummary/BusinessSummary';
// import HowItWorks from '../Home/HowItWorks/HowItWorks';
// import TestimonialThree from '../Home/Testimonials/TestimonialThree';
// import OtherBusinessServices from '../Masking/OtherBusinessServices';
// import JewelleryRetouchingBanner from './JewelleryRetouchingBanner';
// import JewelleryRetouchingPassionWork from './JewelleryRetouchingPassionWork';
// import JewelleryRetouchingServices from './JewelleryRetouchingServices';
// import JewelleryRetouchingQuestion from './JewelleryRetouchingQuestion';

// const JewelleryRetouching = () => {
//   return (
//     <div className="container mx-auto">
//     <Helmet>
//      <meta charSet="utf-8" />
//      <meta name="description" content="Transform your jewelry images into masterpieces with our professional jewelry retouching services, starting from $1. Elevate your collection effortlessly." />
//      <title>High-End Jewelry Retouching Services Start from $1</title>
//    </Helmet>
//  <JewelleryRetouchingBanner/>
//  <Brandings/>
//  <JewelleryRetouchingPassionWork/>
//  {/* <ClippingPathsHeroSections/> */}
//  <TestimonialOne/>
//  <Services/>
//  <JewelleryRetouchingServices/>
//  <OtherBusinessServices/>
//  <TestimonialThree />
//  <HowItWorks/>
//  <BusinessSummary/>
//  <PackageSection/>
//  <JewelleryRetouchingQuestion/>
//  <ServiceQuality />
// <TalkOurProduct />
//  <Footer />
//  </div>
//   );
// };

// export default JewelleryRetouching;
