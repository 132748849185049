/**********NEW************/
import React from 'react';
import BusinessSummary from './BusinessSummary/BusinessSummary';
import Services from './Services';

const WhyUs = () => {
  return (
    <div>
      <Services/>
      <BusinessSummary/>
    </div>
  );
};

export default WhyUs;