import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeRestoration from "../../../images/Restoration/before/012.png"
import AfterRestoration from "../../../images/Restoration/after/012.png"
import BeforeRestoration1 from "../../../images/Restoration/before/28977.png"
import AfterRestoration1 from "../../../images/Restoration/after/28977.png"
import BeforeRestoration2 from "../../../images/Restoration/before/image.png"
import AfterRestoration2 from "../../../images/Restoration/after/image-2.png"

const serviceData = [
  {
    beforeImage: BeforeRestoration,
    afterImage: AfterRestoration,
  },
  {
    beforeImage: BeforeRestoration1,
    afterImage: AfterRestoration1,
  },
  {
    beforeImage: BeforeRestoration2,
    afterImage: AfterRestoration2,
  },
];

const Restoration = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
     
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-black text-center">Revitalize with Restoration</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
          Immerse your audience in the timeless art of restoration. Our carefully curated images are primed for versatile editing, ensuring a seamless blend of tradition and modernity. Optimize for effortless text wrapping, achieve flawless background removal, and guarantee suitability for both print and web applications. Explore the beauty of restoration with visuals that captivate and stories that endure.
      </p>

      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Restoration;
