// import React from 'react';

// const About = () => {
//   return (
//     <section className="container mx-auto pt-12 pb-16 px-4 lg:px-0 rounded-md shadow-lg">
//       <div className="max-w-3xl mx-auto text-center">
//         <h2 className="text-4xl lg:text-6xl text-[#770099] font-extrabold mb-6">Welcome to Skill Graphics</h2>
//         <p className="text-lg lg:text-xl leading-relaxed mb-8">
//           Skill Graphics is a premier image and video post-production company based in Dhaka, Bangladesh. We specialize in providing high-quality post-production services for e-commerce, photography, fashion, advertising, and digital marketing agencies. Our experienced team is committed to enhancing your brand's visual appeal while reducing production costs.
//         </p>
        
//         <div className="text-left mt-8">
//           <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Mission</h3>
//           <p className="text-lg lg:text-xl leading-relaxed mb-8">
//             Our mission is to deliver exceptional post-production services that exceed client expectations. We aim to provide cost-effective solutions while maintaining the highest standards of quality and efficiency, empowering our clients to achieve their creative goals.
//           </p>
//         </div>
        
//         <div className="text-left mt-8">
//           <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Vision</h3>
//           <p className="text-lg lg:text-xl leading-relaxed mb-8">
//             Our vision is to be a global leader in image post-production services, renowned for our innovation, reliability, and customer-centric approach. We strive to continuously evolve and adapt to the ever-changing digital landscape, fostering creativity and excellence in everything we do.
//           </p>
//         </div>

//         <div className="text-left mt-8">
//           <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Goal</h3>
//           <p className="text-lg lg:text-xl leading-relaxed mb-8">
//             Our goal is to build long-term partnerships with our clients by consistently delivering top-notch image post-production services. We aim to support our clients' success by providing tailored solutions that meet their specific needs, ensuring their brands stand out in a competitive market.
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default About;


import React from 'react';
import { FaEye, FaBullseye, FaHandsHelping } from 'react-icons/fa';

const About = () => {
  return (
    <section className="container mx-auto pt-12 pb-16 px-4 lg:px-0 rounded-md shadow-lg">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl lg:text-6xl text-[#770099] font-extrabold mb-6">Welcome to Skill Graphics</h2>
        <p className="text-lg lg:text-xl leading-relaxed mb-8">
          Skill Graphics is a premier image and video post-production company based in Dhaka, Bangladesh. We specialize in providing high-quality post-production services for e-commerce, photography, fashion, advertising, and digital marketing agencies.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="border rounded-lg shadow-lg p-6 bg-white transform transition duration-500 hover:scale-105 text-center flex flex-col items-center">
            <FaEye className="text-5xl text-[#770099] mb-4" />
            <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Vision</h3>
            <p className="text-lg leading-relaxed mb-4">
              To be a global leader in image post-production services, renowned for innovation and reliability.
            </p>
          </div>

          <div className="border rounded-lg shadow-lg p-6 bg-white transform transition duration-500 hover:scale-105 text-center flex flex-col items-center">
            <FaBullseye className="text-5xl text-[#770099] mb-4" />
            <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Mission</h3>
            <p className="text-lg leading-relaxed mb-4">
              Deliver exceptional post-production services, exceeding client expectations with cost-effective solutions.
            </p>
          </div>

          <div className="border rounded-lg shadow-lg p-6 bg-white transform transition duration-500 hover:scale-105 text-center flex flex-col items-center">
            <FaHandsHelping className="text-5xl text-[#770099] mb-4" />
            <h3 className="text-3xl lg:text-4xl font-bold mb-4">Our Goal</h3>
            <p className="text-lg leading-relaxed mb-4">
              Build long-term partnerships by delivering top-notch image post-production services tailored to client needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
