/************OLD*************/
import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeClipping from "../../../src/images/CP/Before/Before_LATIDO715.jpg"
import afterClipping from "../../../src/images/CP/After/After_LATIDO715.jpg"
import BeforeClipping1 from "../../../src/images/CP/Before/Before_901863490418_2.jpg"
import afterClipping1 from "../../../src/images/CP/After/After_901863490418_2.jpg"
import BeforeClipping2 from "../../../src/images/CP/Before/Before_25SHO005.jpg"
import afterClipping2 from "../../../src/images/CP/After/After_25SHO005.jpg"
import BeforeClipping3 from "../../../src/images/CP/Before/Sagaflor_People6461.png"
import afterClipping3 from "../../../src/images/CP/After/Sagaflor_People6461 (1).png"
import BeforeClipping4 from "../../../src/images/CP/Before/Topf_9020-14_Alaskaweiss7455 (1).png"
import afterClipping4 from "../../../src/images/CP/After/Topf_9020-14_Alaskaweiss7455.png"
// import BeforeClipping5 from "../../../src/images/CP/Before/GMV Mercoledì 1 R80205041 copia.png"
// import afterClipping5 from "../../../src/images/CP/After/GMV Mercoledì 1 R80205041 copia (1).png"

const serviceData = [
  {
    beforeImage: BeforeClipping,
    afterImage: afterClipping,
  },
  {
    beforeImage: BeforeClipping1,
    afterImage: afterClipping1,
  },
  {
    beforeImage: BeforeClipping2,
    afterImage: afterClipping2,
  },
  {
    beforeImage: BeforeClipping3,
    afterImage: afterClipping3,
  },
  {
    beforeImage: BeforeClipping4,
    afterImage: afterClipping4,
  },
  // {
  //   beforeImage: BeforeClipping5,
  //   afterImage: afterClipping5,
  // },
];

const ClippingPaths = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Clipping Path</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Prepare your images for versatile editing – optimize for text wrapping, seamless background removal, and suitability for both print and web applications.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClippingPaths;

/***************NEW********************/

// import React from 'react';
// import {Helmet} from "react-helmet";
// import Services from '../Home/Services';
// import TestimonialOne from '../Home/Testimonials/TestimonialOne';
// import Brandings from '../Home/Brandings';
// import Footer from '../Shared/Footer';
// import TalkOurProduct from '../Home/TalkOurProduct';
// import ServiceQuality from '../Home/ServiceQuality';
// import PackageSection from '../Home/PackageSection/PackageSection';
// import BusinessSummary from '../Home/BusinessSummary/BusinessSummary';
// import HowItWorks from '../Home/HowItWorks/HowItWorks';
// import TestimonialThree from '../Home/Testimonials/TestimonialThree';
// import OtherBusinessServices from '../Masking/OtherBusinessServices';
// import ClippingPathsBanner from './ClippingPathsBanner';
// import ClippingPathsPassionWork from './ClippingPathsPassionWork';
// import ClippingPathsQuestion from './ClippingPathsQuestion';
// import ClippingPathsServices from './ClippingPathsServices';
// import ClippingPathsHeroSections from './ClippingPathsHeroSections/ClippingPathsHeroSections';


// const ClippingPaths = () => {
//   return (
//     <div className="container mx-auto">
//     <Helmet>
//      <meta charSet="utf-8" />
//      <meta name="description" content="Discover high-quality, professional clipping paths and image editing services at Skill Graphics. Contact us today for a quote on your next project!" />
//      <title>Best Clipping Path Service Provider| Image & Photo Editing </title>
//    </Helmet>
//  <ClippingPathsBanner/>
//  <Brandings/>
//  <ClippingPathsPassionWork/>
//  <ClippingPathsHeroSections/>
//  <TestimonialOne/>
//  <Services/>
//  <ClippingPathsServices/>
//  <OtherBusinessServices/>
//  <TestimonialThree />
//  <HowItWorks/>
//  <BusinessSummary/>
//  <PackageSection/>
//  <ClippingPathsQuestion/>
//  <ServiceQuality />
// <TalkOurProduct />
//  <Footer />
//  </div>
//   );
// };

// export default ClippingPaths;