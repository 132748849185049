import React from 'react';
import Digital from '../../images/Animation/Plus Online dynamic_Content 1.mp4';
import { FaArrowRight } from 'react-icons/fa';

const DigitalMarketing = () => {
  return (
    <section className="container mx-auto py-20 px-4 md:px-14 bg-white">
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/4 mb-6 md:mb-0">
          <video
           controls
           autoPlay
           loop
           muted
           src={Digital}
           alt="Scale With Confidence"
           className="max-w-full h-auto rounded-lg hover:scale-105 transform transition-transform duration-300"
           controlsList="nodownload"
          />
        </div>
        <div className="text-justify md:w-2/4 md:ml-6">
          <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight">
            Digital Marketing
          </h1>

          <p className="py-4 text-base md:text-lg text-[#646a76] max-w-md md:max-w-full">
            Our digital marketing experts employ cutting-edge strategies to drive traffic,
            enhance engagement, and convert leads into loyal customers. From social media
            campaigns to search engine optimization (SEO), we tailor our services to meet
            your unique business goals. Stay ahead in the competitive digital landscape with
            Skill Graphics as your strategic partner.
          </p>
            <a
        href="https://plusonlinemarketing.com/"
        target="_blank"
        rel="noopener noreferrer"
        >
           <button className="bg-[#770099] hover:bg-[#950cba] text-white px-6 py-3 rounded flex items-center focus:outline-none focus:ring focus:border-purple-300">
              Explore More
              <FaArrowRight className="ml-2" />
            </button>
        </a>
         
        </div>
      </div>
    </section>
  );
};

export default DigitalMarketing;
