//old design

// import React, { useState } from 'react';
// import image1 from '../../../src/images/Logo/DNKY-svg.png';
// import image2 from '../../../src/images/Logo/GAP-svg.png';
// import image3 from '../../../src/images/Logo/Inditex-svg.png';
// import image4 from '../../../src/images/Logo/S..Oliver-svg.png';
// import image5 from '../../../src/images/Logo/zalando.png';
// import image6 from '../../../src/images/Logo/adidas-logo.png';
// import image7 from '../../../src/images/Logo/mango.png';
// import image8 from '../../../src/images/Logo/pepe jense.png';
// import image9 from '../../../src/images/Logo/adolfo Dominguez-svg.png';
// import './Brandings.css';
// import Marquee from 'react-fast-marquee';

// const Brandings = () => {
//   const cards = [
//     {
//       image: image1,
//     },
//     {
//       image: image2,
//     },
//     {
//       image: image3,
//     },
//     {
//       image: image4,
//     },
//     {
//       image: image5,
//     },
//     {
//       image: image6,
//     },
//     {
//       image: image7,
//     },
//     {
//       image: image8,
//     },
//     {
//       image: image9,
//     },
//     // {
//     //   image: image10,
//     // },
//   ];

//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   return (
//     <div className='py-12 container mx-auto'>
//       <div
//         className={`marquee-container ${isHovered ? 'paused' : ''}`}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <Marquee speed={25} className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-0'>
//           {cards.map((card, index) => (
//             <div key={index}>
//               <img
//                 src={card.image}
//                 alt="brands"
//                 className='h-28 mx-10 object-cover cursor-pointer hover:scale-105 hover:shadow-inner duration-300'
//               />
//             </div>
//           ))}
//         </Marquee>
//       </div>
//     </div>
//   );
// };

// export default Brandings;

// New Design

// import React, { useEffect, useRef, useState } from 'react';
// import { gsap } from 'gsap';
// import { Power3 } from 'gsap/all';
// import image1 from '../../../src/images/Logo/DNKY-svg.png';
// import image2 from '../../../src/images/Logo/GAP-svg.png';
// import image3 from '../../../src/images/Logo/Inditex-svg.png';
// import image4 from '../../../src/images/Logo/S..Oliver-svg.png';
// import image5 from '../../../src/images/Logo/zalando.png';
// import image6 from '../../../src/images/Logo/adidas-logo.png';
// import image7 from '../../../src/images/Logo/mango.png';
// import image8 from '../../../src/images/Logo/pepe jense.png';
// import image9 from '../../../src/images/Logo/adolfo Dominguez-svg.png';
// import image10 from '../../../src/images/Logo/dolce-gabbana-logo.png';
// import './Brandings.css';

// const Brandings = () => {
//   const logosRef = useRef([]);
//   const sectionRef = useRef(null);
//   const [hasAnimated, setHasAnimated] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach(entry => {
//         if (entry.isIntersecting && !hasAnimated) {
//           gsap.fromTo(logosRef.current,
//             { y: -50, opacity: 0 },
//             {
//               y: 0,
//               opacity: 1,
//               duration: 1.5, // Increased duration for smoother animation
//               stagger: 0.2,
//               ease: Power3.easeOut,
//               onComplete: () => {
//                 if (logosRef.current.every(ref => ref)) {
//                   gsap.to(logosRef.current, {
//                     y: '+=10',
//                     repeat: -1,
//                     yoyo: true,
//                     duration: 1,
//                     ease: 'power2.inOut',
//                   });
//                 }
//               },
//             }
//           );
//           setHasAnimated(true);
//         }
//       });
//     }, { threshold: 0.5 });

//     if (sectionRef.current) {
//       observer.observe(sectionRef.current);
//     }

//     return () => {
//       if (sectionRef.current) {
//         observer.unobserve(sectionRef.current);
//       }
//     };
//   }, [hasAnimated]);

//   const cards = [
//     { image: image1 },
//     { image: image2 },
//     { image: image3 },
//     { image: image4 },
//     { image: image5 },
//     { image: image6 },
//     { image: image7 },
//     { image: image8 },
//     { image: image9 },
//     { image: image10 },
//   ];

//   return (
//     <div ref={sectionRef} className='py-12 container mx-auto'>
//       <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
//         {cards.map((card, index) => (
//           <div
//             key={index}
//             className='flex justify-center items-center'
//             style={{ opacity: 0, transform: 'translateY(-50px)' }}
//             ref={(el) => (logosRef.current[index] = el)}
//           >
//             <img
//               src={card.image}
//               alt="brands"
//               className='h-24 mx-10 cursor-pointer hover:scale-105 hover:shadow-inner duration-300'
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Brandings;

// for mobile view

import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { Power3 } from 'gsap/all';
import image1 from '../../../src/images/Logo/DNKY-svg.png';
import image2 from '../../../src/images/Logo/GAP-svg.png';
import image3 from '../../../src/images/Logo/Inditex-svg.png';
import image4 from '../../../src/images/Logo/S..Oliver-svg.png';
import image5 from '../../../src/images/Logo/zalando.png';
import image6 from '../../../src/images/Logo/adidas-logo.png';
import image7 from '../../../src/images/Logo/mango.png';
import image8 from '../../../src/images/Logo/pepe jense.png';
import image9 from '../../../src/images/Logo/adolfo Dominguez-svg.png';
import image10 from '../../../src/images/Logo/dolce-gabbana-logo.png';
import './Brandings.css';

const Brandings = () => {
  const logosRef = useRef([]);
  const sectionRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const handleAnimation = () => {
      if (window.innerWidth > 768) { 
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !hasAnimated) {
              gsap.fromTo(logosRef.current,
                { y: -50, opacity: 0 },
                {
                  y: 0,
                  opacity: 1,
                  duration: 1.5, 
                  stagger: 0.2,
                  ease: Power3.easeOut,
                  onComplete: () => {
                    if (logosRef.current.every(ref => ref)) {
                      gsap.to(logosRef.current, {
                        y: '+=10',
                        repeat: -1,
                        yoyo: true,
                        duration: 1,
                        ease: 'power2.inOut',
                      });
                    }
                  },
                }
              );
              setHasAnimated(true);
              observer.unobserve(sectionRef.current); 
            }
          });
        }, { threshold: 0.5 });

        if (sectionRef.current) {
          observer.observe(sectionRef.current);
        }

        return () => {
          if (sectionRef.current) {
            observer.unobserve(sectionRef.current);
          }
        };
      } else {
        // If screen width is less than or equal to 768px, show cards without animation
        logosRef.current.forEach((el) => {
          if (el) {
            el.style.opacity = 1;
            el.style.transform = 'translateY(0)';
          }
        });
      }
    };

    handleAnimation();
    window.addEventListener('resize', handleAnimation);

    return () => {
      window.removeEventListener('resize', handleAnimation);
    };
  }, [hasAnimated]);

  const cards = [
    { image: image1 },
    { image: image2 },
    { image: image3 },
    { image: image4 },
    { image: image5 },
    { image: image6 },
    { image: image7 },
    { image: image8 },
    { image: image9 },
    { image: image10 },
  ];

  return (
    <div ref={sectionRef} className='py-12 container mx-auto'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
        {cards.map((card, index) => (
          <div
            key={index}
            className='flex justify-center items-center'
            style={{ opacity: 0, transform: 'translateY(-50px)' }}
            ref={(el) => (logosRef.current[index] = el)}
          >
            <img
              src={card.image}
              alt="brands"
              className='h-24 mx-10 cursor-pointer hover:scale-105 hover:shadow-inner duration-300'
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brandings;

