import React, { useRef } from 'react';
import vedioEding from "../../../images/Animation/MVRK VFX_SHOWREEL_2022.mp4"
import { FaArrowRight } from 'react-icons/fa';

const VisualEffects = () => {
    return (
        <section id="section1" className="container h-screen mx-auto py-12 px-4 md:px-14 bg-white">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 text-start md:text-left">
            <h1 className="text-3xl font-bold md:text-5xl text-[#333] leading-tight mb-4">
            Visual Effects
            </h1>
            <p className="text-base md:text-lg text-[#646a76] mb-6">
            Skill Graphics excels in visual effects, turning your raw footage into captivating masterpieces. Our skilled editors bring creativity and precision to every project, ensuring a seamless and engaging visual experience. Experience the unmatched quality and innovation that sets us apart in the dynamic world of visual effects. Let Skill Graphics redefine your visual storytelling with expertise that goes beyond expectations.
            </p>
            <a
          href="http://mvrk.tv/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link MVRK"
        >
            {/* <button className="bg-[#770099] hover:bg-[#950cba] text-white font-bold py-2 px-4 rounded">
            Explore More
            </button> */}
            <button className="bg-[#770099] hover:bg-[#950cba] text-white px-6 py-3 rounded flex items-center focus:outline-none focus:ring focus:border-purple-300">
              Explore More
              <FaArrowRight className="ml-2" />
            </button>
            </a>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0 text-center lg:text-right">
            <video
              controls
              autoPlay
              loop
              muted
              src={vedioEding}
              alt="vedio Eding"
              className="max-w-full rounded-lg hover:scale-105 transform transition-transform duration-300"
              controlsList="nodownload"
            />
          </div>
        </div>
      </section>
    );
};

export default VisualEffects;



// import { Player } from '@lottiefiles/react-lottie-player';

// const VideoEditing = () => {
//   const playerRef = useRef(null);

//   return (
//     <Player
//       ref={playerRef}
//       autoplay={false}
//       loop={true}
//       controls={true}
//       src={vedioEding}
//       style={{ height: '300px', width: '300px' }}
//     ></Player>
//   );
// };

// export default VideoEditing;