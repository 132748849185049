import './App.css';
import Home from './Components/Home/Home';
import Login from './Components/Authentication/Login';
import Register from './Components/Authentication/Register';
import { Routes, Route } from "react-router-dom";
import Header from './Components/Shared/Header';
import BeforeAfterSlider from './Components/Home/BeforeAfterSlider/BeforeAfterSlider';
import Brandings from './Components/Home/Brandings';
import ScrollButton from './Components/ScrollButton/ScrollButton';
import Masking from './Components/Masking/Masking';
import Retouching from './Components/Retouching/Retouching';
import ClippingPaths from './Components/ClippingPaths/ClippingPaths';
import ColorMatch from './Components/ColorMatch/ColorMatch';
import InvisibleMannequin from './Components/InvisibleMannequin/InvisibleMannequin';
import Shadows from './Components/Shadows/Shadows';
import About from './Components/About/About';
import PackageSection from './Components/Home/PackageSection/PackageSection';
import TalkOurProduct from './Components/Home/TalkOurProduct';
import Footer from './Components/Shared/Footer';
import ThreeSixtyDegree from './Components/Home/ThreeSixty/ThreeSixtyDegree';
import ModelRetouching from './Components/Home/ModelRetouching/ModelRetouching';
import Manipulation from './Components/Home/Manipulation/Manipulation';
import Restoration from './Components/Home/Restoration/Restoration';
import Furniture from './Components/Home/Furniture/Furniture';
import Flatlay from './Components/Flatlay/Flatlay';
import OurServices from './Components/Home/OurServices/OurServices';
import PrivacyPolicy from './Components/Shared/FooterItems/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Components/Shared/FooterItems/TermsAndConditions/TermsAndConditions';
import Disclaimer from './Components/Shared/FooterItems/Disclaimer/Disclaimer';
import TermsOfService from './Components/Shared/FooterItems/TermsOfService/TermsOfService';
import CookiePolicy from './Components/Shared/FooterItems/CookiePolicy/CookiePolicy';
import ImageEditingAiExample from './Components/Home/ImageEditingAiExample/ImageEditingAiExample';
import NotFoundPage from './Components/Shared/NotFoundPage';
import JewelleryRetouching from './Components/JewelleryRetouching/JewelleryRetouching';
import WhyUs from './Components/Home/WhyUs';
import EffortlessWorkflow from './Components/Home/EffortlessWorkflow';
import PhotoGallery from './Components/Home/PhotoGallery/PhotoGallery';

function App() {
  return (
    <div className="App">
      <Header/>
       <ScrollButton/>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/branding" element={<Brandings />} />
        <Route path="/image-masking-services" element={<Masking />} />
        <Route path="/photo-retouching" element={<Retouching />} />
        <Route path="/clipping-Paths" element={<ClippingPaths />} />
        <Route path="/colorMatch" element={<ColorMatch />} />
        <Route path="/invisible" element={<InvisibleMannequin />} />
        <Route path="/flatlay" element={<Flatlay />} />
        <Route path="/shadows" element={<Shadows />} />
        <Route path="/modelRetouching" element={<ModelRetouching />} />
        <Route path="/threesixty" element={<ThreeSixtyDegree />} />
        <Route path="/jewelleryRetouching" element={<JewelleryRetouching />} />
        <Route path="/manipulation" element={<Manipulation />} />
        <Route path="/restoration" element={<Restoration />} />
        <Route path="/colorAdjust" element={<Furniture />} />
        <Route path="/services" element={<BeforeAfterSlider />} />
        <Route path="/allservices" element={<OurServices />} />
        <Route path="contact" element={<TalkOurProduct />} />
        <Route path="/about" element={<About />} />
        <Route path="/package" element={<PackageSection />} />
        <Route path="register" element={<Register />} />
        <Route path="/imageEditingAiExample" element={<ImageEditingAiExample />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
        <Route path="/cookiePolicy" element={<CookiePolicy />} />
        <Route path="/photoGallery" element={<PhotoGallery />} />
          <Route path="/workflow" element={<EffortlessWorkflow />} />
          <Route path="/whyus" element={<WhyUs />} />
          <Route path="*" element={<NotFoundPage />} />
    </Routes>
    </div>
  );
}

export default App

// import React, { Suspense, lazy } from "react";
// import { Routes, Route } from "react-router-dom";
// import Header from './Components/Shared/Header';
// import ScrollButton from './Components/ScrollButton/ScrollButton';
// import './App.css';
// import Loading from "./Components/Shared/FooterItems/Loading";
// import EffortlessWorkflow from "./Components/Home/EffortlessWorkflow";
// const Home = lazy(() => import('./Components/Home/Home'));
// const Login = lazy(() => import('./Components/Authentication/Login'));
// const Register = lazy(() => import('./Components/Authentication/Register'));
// const Brandings = lazy(() => import('./Components/Home/Brandings'));
// const Masking = lazy(() => import('./Components/Masking/Masking'));
// const Retouching = lazy(() => import('./Components/Retouching/Retouching'));
// const ClippingPaths = lazy(() => import('./Components/ClippingPaths/ClippingPaths'));
// const ColorMatch = lazy(() => import('./Components/ColorMatch/ColorMatch'));
// const InvisibleMannequin = lazy(() => import('./Components/InvisibleMannequin/InvisibleMannequin'));
// const Flatlay = lazy(() => import('./Components/Flatlay/Flatlay'));
// const Shadows = lazy(() => import('./Components/Shadows/Shadows'));
// const ModelRetouching = lazy(() => import('./Components/Home/ModelRetouching/ModelRetouching'));
// const ThreeSixtyDegree = lazy(() => import('./Components/Home/ThreeSixty/ThreeSixtyDegree'));
// const JewelleryRetouching = lazy(() => import('./Components/JewelleryRetouching/JewelleryRetouching'));
// const Manipulation = lazy(() => import('./Components/Home/Manipulation/Manipulation'));
// const Restoration = lazy(() => import('./Components/Home/Restoration/Restoration'));
// const Furniture = lazy(() => import('./Components/Home/Furniture/Furniture'));
// const BeforeAfterSlider = lazy(() => import('./Components/Home/BeforeAfterSlider/BeforeAfterSlider'));
// const OurServices = lazy(() => import('./Components/Home/OurServices/OurServices'));
// const TalkOurProduct = lazy(() => import('./Components/Home/TalkOurProduct'));
// const About = lazy(() => import( './Components/About/About'));
// const PackageSection = lazy(() => import('./Components/Home/PackageSection/PackageSection'));
// const ImageEditingAiExample = lazy(() => import('./Components/Home/ImageEditingAiExample/ImageEditingAiExample'));
// const NotFoundPage = lazy(() => import('./Components/Shared/NotFoundPage'));
// const PrivacyPolicy = lazy(() => import('./Components/Shared/FooterItems/PrivacyPolicy/PrivacyPolicy'));
// const TermsAndConditions = lazy(() => import('./Components/Shared/FooterItems/TermsAndConditions/TermsAndConditions'));
// const Disclaimer = lazy(() => import('./Components/Shared/FooterItems/Disclaimer/Disclaimer'));
// const TermsOfService = lazy(() => import('./Components/Shared/FooterItems/TermsOfService/TermsOfService'));
// const CookiePolicy = lazy(() => import('./Components/Shared/FooterItems/CookiePolicy/CookiePolicy'));
// const PhotoGallery = lazy(() => import('./Components/Home/PhotoGallery/PhotoGallery'));
// const WhyUs = lazy(() => import('./Components/Home/WhyUs'));

// function App() {
//   return (
//     <div className="App">
//       <Header/>
//        <ScrollButton/>
//        <Suspense fallback={<Loading/>}>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/branding" element={<Brandings />} />
//           <Route path="/image-masking-services" element={<Masking />} />
//           <Route path="/photo-retouching" element={<Retouching />} />
//           <Route path="/clipping-Paths" element={<ClippingPaths />} />
//           <Route path="/colorMatch" element={<ColorMatch />} />
//           <Route path="/invisible" element={<InvisibleMannequin />} />
//           <Route path="/flatlay" element={<Flatlay />} />
//           <Route path="/shadows" element={<Shadows />} />
//           <Route path="/modelRetouching" element={<ModelRetouching />} />
//           <Route path="/threesixty" element={<ThreeSixtyDegree />} />
//           <Route path="/jewelleryRetouching" element={<JewelleryRetouching />} />
//           <Route path="/manipulation" element={<Manipulation />} />
//           <Route path="/restoration" element={<Restoration />} />
//           <Route path="/colorAdjust" element={<Furniture />} />
//           <Route path="/services" element={<BeforeAfterSlider />} />
//           <Route path="/allservices" element={<OurServices />} />
//           <Route path="/contact" element={<TalkOurProduct />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/package" element={<PackageSection />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/imageEditingAiExample" element={<ImageEditingAiExample />} />
//           <Route path="/termsAndConditions" element={<TermsAndConditions />} />
//           <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
//           <Route path="/disclaimer" element={<Disclaimer />} />
//           <Route path="/termsOfService" element={<TermsOfService />} />
//           <Route path="/cookiePolicy" element={<CookiePolicy />} />
          // <Route path="/photoGallery" element={<PhotoGallery />} />
          // <Route path="/workflow" element={<EffortlessWorkflow />} />
          // <Route path="/whyus" element={<WhyUs />} />
          // <Route path="*" element={<NotFoundPage />} />
//         </Routes>
//        </Suspense>
//     </div>
//   );
// }

// export default App;
