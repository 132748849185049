import React from "react";
import threesixty from "../../../images/360/360.gif";

const ThreeSixtyDegree = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-black text-center">360 Image Comparison</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
        Elevate your image editing experience with our 360-degree comparison tool. Prepare your images for versatile editing, optimizing them for text wrapping, seamless background removal, and suitability for both print and web applications.
      </p>
      <div className="container mx-auto py-2 px-4 sm:px-8">
      <div className="flex justify-center">
      {/* <video
        controls
        autoPlay
        loop
        muted
        className="max-w-full md:max-w-3xl rounded-lg shadow-lg border-4 border-[#581C87] hover:border-[#581C87] transition-all duration-300 transform"
        /> */}
        <img  
        className="max-w-full rounded-lg shadow-lg transition-all duration-300 transform" src={threesixty} alt=""/>
      </div>
    </div>
    </div>
  );
};

export default ThreeSixtyDegree;
