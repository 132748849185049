

// import React from 'react';

// const CookiePolicy = () => {
//     return (
//         <div className="bg-gray-100">
//             <div className="max-w-xl mx-auto bg-white p-6 rounded-md shadow-md">
//                 <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
//                 <p className="text-gray-700 mb-4">
//                     This website uses cookies to ensure you get the best experience on our website.
//                 </p>
//                 <p className="text-gray-700 mb-4">
//                     By continuing to use our site, you agree to our{' '}
//                     <a href="/privacy-policy" className="text-blue-500 hover:underline">
//                         Privacy Policy
//                     </a>{' '}
//                     and the use of cookies.
//                 </p>
//                 <div className="flex items-center justify-end">
//                     <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
//                         Accept
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default CookiePolicy;
import React from 'react';
import { useCookies } from 'react-cookie';

const CookiePolicy = () => {
    const [cookies, setCookie] = useCookies(['accepted']);

    const handleAccept = () => {
        console.log('Accept button clicked');
        // Set a cookie named "accepted" with value "true" that expires in 365 days
        setCookie('accepted', 'true', { path: '/', maxAge: 365 * 24 * 60 * 60 });
    };

    // If the "accepted" cookie exists, do not render the component
    if (cookies.accepted === 'true') {
        return null;
    }

    return (
        <div className="bg-gray-100">
            <div className="max-w-xl mx-auto bg-white p-6 rounded-md shadow-md">
                <h2 className="text-2xl font-bold mb-4">Cookie Policy</h2>
                <p className="text-gray-700 mb-4">
                    This website uses cookies to ensure you get the best experience on our website.
                </p>
                <p className="text-gray-700 mb-4">
                    By continuing to use our site, you agree to our{' '}
                    <a href="/privacyPolicy" className="text-blue-500 hover:underline">
                        Privacy Policy
                    </a>{' '}
                    and the use of cookies.
                </p>
                <div className="flex items-center justify-end">
                    <button onClick={handleAccept} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                        Accept
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicy;
