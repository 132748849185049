import React from "react";
import ReactCompareImage from "react-compare-image";
import BeforeFurniture1 from "../../../images/Furniture/before/Before_64A8885.jpg"
import afterFurniture1 from "../../../images/Furniture/after/After_64A8885.jpg"
import BeforeFurniture2 from "../../../images/Furniture/before/Before_IMG_8242.jpg"
import afterFurniture2 from "../../../images/Furniture/after/After_IMG_8242.jpg"
import BeforeFurniture3 from "../../../images/Furniture/before/Before_PUNTA-TRAGARA-003388-0721.jpg"
import afterFurniture3 from "../../../images/Furniture/after/After_PUNTA-TRAGARA-003388-0721.jpg"

const serviceData = [
  {
    beforeImage: BeforeFurniture1,
    afterImage: afterFurniture1,
  },
  {
    beforeImage: BeforeFurniture2,
    afterImage: afterFurniture2,
  },
  {
    beforeImage: BeforeFurniture3,
    afterImage: afterFurniture3,
  },

];

const Furniture = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
     
      <h1 className="text-3xl md:text-5xl font-bold mb-4 text-black text-center">Adjustment Your Color Imagery</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
          Immerse your audience in the captivating world of our exquisite furniture collection. We meticulously prepare each image to provide you with the flexibility you need for versatile editing. Our commitment extends to optimizing for seamless text wrapping, background removal, and ensuring suitability for both print and web applications.
      </p>

      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#808080"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                leftImageLabel="Before"
                rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Furniture;


// import React from "react";
// import ReactCompareImage from "react-compare-image";
// import BeforeFurniture from "../../../images/Furniture/before/Furniture1 (1).png";
// import afterFurniture from "../../../images/Furniture/after/Furniture2 (1).png";

// const serviceData = [
//   {
//     beforeImage: BeforeFurniture,
//     afterImage: afterFurniture,
//   },
// ];

// const Furniture = () => {
//   return (
//     <div className="bg-[#f2f2f2] container mx-auto py-14">
//       <h1 className="text-3xl md:text-5xl font-bold mb-4 text-black text-center">Adjustment Your Color Imagery</h1>
//       <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
//         Immerse your audience in the captivating world of our exquisite furniture collection. We meticulously prepare each image to provide you with the flexibility you need for versatile editing. Our commitment extends to optimizing for seamless text wrapping, background removal, and ensuring suitability for both print and web applications.
//       </p>

//       <div className="container mx-auto py-8 px-4 sm:px-8">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
//           {serviceData.map((data, index) => (
//             <div key={index} className="bg-white rounded-lg shadow-inner hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
//               <ReactCompareImage
//                 hover
//                 handleSize={40}
//                 sliderLineColor="linear-gradient(to right, #005374, #770099)" 
//                 leftImage={data.beforeImage}
//                 rightImage={data.afterImage}
//                 leftImageCss={{
//                   width: "100%",
//                   height: "auto",
//                 }}
//                 rightImageCss={{
//                   width: "100%",
//                   height: "auto",
//                 }}
//                 leftImageLabel="Before"
//                 rightImageLabel="After"
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Furniture;
