import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import emailjs from "@emailjs/browser";
import swal from 'sweetalert';

const ModalChoosePackageForm = ({ isModalChoosePlanOpen, closeModal }) => {
  const [selectedPackage, setSelectedPackage] = useState('STANDARD');

  const handlePackageChange = (e) => {
    setSelectedPackage(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const form = e.target; 
  
    const formData = {
      ...Object.fromEntries(new FormData(form)),
      selectedPackage: selectedPackage
    };
  
    emailjs
      .sendForm(
        "service_zw0hrv4",
        "template_foz5mcz",
        form,
        "9x7Cg5x8l-qqgSg6M"
      )
      .then(
        (result) => {
          swal("Thank you for your customization", "One of us will get back to you shortly", "success");
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  

  return (
    <div
      className={`fixed inset-0 z-10 flex items-center justify-center overflow-y-auto ${
        isModalChoosePlanOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } transition-opacity ease-in-out duration-300`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed bg-black opacity-0"></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:max-w-md">
        <div className="bg-[#ac02db] p-4 text-white text-center flex justify-between items-center">
          <h2 className="text-2xl font-bold mb-4">Choose your package</h2>
          <button
            className="text-white focus:outline-none"
            onClick={closeModal}
            aria-label="Close modal"
          >
            <FaTimes size={28} className="text-2xl bg-slate-500 rounded-full p-1 " />
          </button>
        </div>
        <div className="p-4 text-black">
          <form onSubmit={sendEmail}>
            <div className="mb-2 text-start">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-purple-500 focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-2 text-start">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-purple-500 focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-2 text-start">
              <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                Number of images:
              </label>
              <input
                type="number"
                id="number"
                name="number"
                className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-purple-500 focus:border-blue-500"
                required
              />
            </div>

            <div className="mb-2 text-start">
              <label htmlFor="package" className="block text-sm font-medium text-gray-700">
                Package:
              </label>
              <select
                id="package"
                name="package"
                value={selectedPackage}
                onChange={handlePackageChange}
                className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-purple-500 focus:border-purple-500"
              >
                <option value="STANDARD">STANDARD</option>
                <option value="EXPRESS">EXPRESS</option>
                <option value="URGENT">URGENT</option>
              </select>
            </div>
            <div className="mt-2">
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-[#770099] rounded-md hover:bg-[#632e74] focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalChoosePackageForm;
