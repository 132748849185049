/****old*****/

import React from "react";
import ReactCompareImage from "react-compare-image";
import beforeMasking from "../../../src/images/Masking/Before/BUCK_WerdeMaler_Freizeit_149.png"
import afterMasking from "../../../src/images/Masking/After/BUCK_WerdeMaler_Freizeit_149 (1).png"
import beforeMasking1 from "../../../src/images/Masking/Before/aluramodel-sandlau_112.png"
import afterMasking1 from "../../../src/images/Masking/After/aluramodel-sandlau_112_A.png"
import beforeMasking2 from "../../../src/images/Masking/Before/DSC_0027_B.png"
import afterMasking2 from "../../../src/images/Masking/After/DSC_0027 (1)_A.png"

const serviceData = [

  {
    beforeImage: beforeMasking,
    afterImage: afterMasking,
  },
  {
    beforeImage: beforeMasking1,
    afterImage: afterMasking1,
  },
  {
    beforeImage: beforeMasking2,
    afterImage: afterMasking2,
  },
];

const Masking = () => {
  return (
    <div className="bg-[#f2f2f2] container mx-auto py-14">
      <h1 className="text-3xl  md:text-5xl font-bold mb-4 text-black text-center">Image Masking</h1>
      <p className="text-gray-700 text-base sm:text-lg md:text-xl max-w-full mb-6 text-center mx-auto px-4 sm:px-8">
      Perfect your image with precision using advanced masking techniques. Seamlessly blend, reveal, or highlight elements for a flawless and captivating visual impact. Elevate your images with expert masking for a polished and professional finish.
      </p>
      <div className="container mx-auto py-8 px-4 sm:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-5">
          {serviceData.map((data, index) => (
            <div key={index} className="bg-white rounded-lg shadow-inner cursor-pointer hover:shadow-2xl transform transition-transform hover:scale-105 duration-300 p-4">
              <ReactCompareImage
                hover
                handleSize={40}
                // sliderLineColor="#005374"
                // sliderLineColor="#f8f8f8"
                leftImage={data.beforeImage}
                rightImage={data.afterImage}
                leftImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                rightImageCss={{
                  width: "100%",
                  height: "auto",
                }}
                // leftImageLabel="Before"
                // rightImageLabel="After"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Masking;


/*****************NEW*************************/

// import React from "react";
// import MaskingBanner from "./MaskingBanner";
// import MaskingPassionWork from "./MaskingPassionWork";
// import Brandings from "../Home/Brandings";
// import TestimonialOne from "../Home/Testimonials/TestimonialOne";
// import Services from "../Home/Services";
// import MaskingQuestion from "./MaskingQuestion";
// import Footer from "../Shared/Footer";
// import ServiceQuality from "../Home/ServiceQuality";
// import TalkOurProduct from "../Home/TalkOurProduct";
// import MaskingServices from "./MaskingServices";
// import OtherBusinessServices from "./OtherBusinessServices";
// import HowItWorks from "../Home/HowItWorks/HowItWorks";
// import TestimonialThree from "../Home/Testimonials/TestimonialThree";
// import BusinessSummary from "../Home/BusinessSummary/BusinessSummary";
// import PackageSection from "../Home/PackageSection/PackageSection";
// import {Helmet} from "react-helmet";
// import MaskingHeroSections from "./MaskingHeroSections/MaskingHeroSections";

// const Masking = () => {
//   return (
//     <div className="container mx-auto">
//     <Helmet>
//         <meta charSet="utf-8" />
//         <meta name="description" content="Get a quote now for premium, cost-effective image masking and Photoshop masking services by Skill Graphics. Enhance your visuals with precision and affordability. Contact us today!" />
//         <title>Expert Image Masking Services | Photoshop Masking - Skill Graphics</title>
//       </Helmet>
//     <MaskingBanner/>
//     <Brandings/>
//     <HowItWorks/>
//     <MaskingPassionWork/>
//     <MaskingHeroSections/>
//     <TestimonialOne/>
//     <Services/>
//     <MaskingServices/>
//     <OtherBusinessServices/>
//     <TestimonialThree />
//     <BusinessSummary/>
//     <PackageSection/>
//     <MaskingQuestion/>
//     <ServiceQuality />
//    <TalkOurProduct />
//     <Footer />
//     </div>
//   );
// };

// export default Masking;



